import React, {useState, FC} from 'react';
import styles from './Input.module.scss';
import Eye from '../../../assets/images/icons/eye.svg'
import addIcon from '../../../assets/images/icons/add_icon.svg'

interface IProps {
  value: string;
  onChange: (value: string) => void;
  update?: () => void;
  error?: string | boolean;

  placeholder?: string;
  label?: string,

  type?: 'text' | 'password' | 'number' | 'addSub';

  disable?: boolean,
  className?: string,
  classLabelName?: string,

  width?: string | number,
  height?: string | number,
  rightIcon?: JSX.Element,
  leftIcon?: JSX.Element,
  autoComplete?: string,
  pl?: boolean,
  cursor?: string
}

const Input: FC<IProps> = ({
                             error,
                             placeholder,
                             type,
                             onChange,
                             value,
                             disable,
                             className,
                             classLabelName,
                             width,
                             height,
                             rightIcon,
                              leftIcon,
                             label,
                             autoComplete,
                             update,
                             pl,
                             cursor
                           }: IProps) => {
  const [showPassword, setShowPassword] = useState(type);

  const style: any = {};
  if (width) style.width = width;
  if (height) style.height = height;
  if (rightIcon) style.paddingRight = '40px';
  if (leftIcon) style.paddingLeft = '50px';
  if (pl) style.paddingLeft = '50px';
  if (cursor) style.cursor = cursor;
  const handleClick = () => {
    setShowPassword(showPassword === 'text' ? 'password' : 'text')
  };

  return (
      <>
        {label && <div className={`${styles.input_label} ${classLabelName ? classLabelName : ''}`}>{label}</div>}
        <div className={`${styles.input_wrapper} ${className ? className : ''}`}>

          <input
              className={`
                ${styles.input}
                ${error ? styles.input_error : ''}
                ${disable ? styles.input_disable : ''}
              `}
              name={error ? 'input_error' : ''}
              placeholder={placeholder}
              value={value}
              style={style}

              autoComplete={autoComplete || ''}
              disabled={disable}
              type={showPassword}
              onChange={(e) => onChange(e.target.value)}/>

          {type === 'password' ?
              <span className={`${styles.password__show}`} onClick={handleClick}>
                <img
                  width={'24px'}
                  height={'24px'}
                  src={Eye}
                />
              </span> : null
          } 
           {type === 'addSub' ?
          <span className={`${styles.addSub}`} onClick={update}>
            <img
              width={'14px'}
              height={'14px'}
              src={addIcon}
            />
          </span> : null
      }
          {rightIcon && <div className={styles.right_icon}>{rightIcon}</div>}
          {leftIcon && <div className={styles.left_icon}>{leftIcon}</div>}
        </div>
        <div className={styles.error_text}>
          <span>{error}</span>
        </div>
      </>
  )
};

Input.defaultProps = {
  type: "text"
};

export default Input;
