import React, { FC, useEffect, useState } from 'react';
import {
    SurveyTemplateModel, QuestionCategory,
    QuestionnaireModel, Question,
    SurveySaveModel, SurveyChartModel, SurveyRelatedModel, SurveyHistoryModel, KeyValueModel, TemplatePreviewSurveyChartModel,
} from '../../../models';
import { generateId, isEmptyObject } from '../../../helpers';
import AvatarPlaceholder from "../../../assets/images/icons/avatar-placeholder.svg";
import DatePicker from "react-datepicker";
import Textarea from "../../controls/Textarea/Textarea";
import Button from "../../controls/Button/Button";
import Modal from "../../common/Modal/Modal";
import Input from "../../controls/Input/Input";
import SurveyCategory from "./parts/SurveyCategory/SurveyCategory";
import ReactSpeedometer from "react-d3-speedometer"
import moment from "moment";
import { getSurvey, sendSurvey, shareSurvey, updateSurvey} from "../../../store/survey/surveySlice";
import {useDispatch, useSelector} from "react-redux";
import { email, validate } from '../../../helpers/validatior';
import { RootState } from '../../../store/reducers';
import {MAIN_URI} from "../../../constants";
import { useUnsavedChangesWarning } from '../../common';
import { clearChartPreview, sendPreviewSurvey } from '../../../store/templates/templatesSlice';

interface IState {
    modalType: 'update-success' | 'complete-success' | 'error' | 'unable-to-save' | 'sendEmail-success' | null,
  }

  const initialState: IState = {
    modalType: null,
  };

type IProps = {
    template: SurveyTemplateModel,
    questionnaire: QuestionnaireModel,
    token?: string | undefined,
    surveyChart: SurveyChartModel | null,
    surveyChartPreview: TemplatePreviewSurveyChartModel | null,
    surveyRelated: SurveyRelatedModel[] | null,
    surveyHistory: SurveyHistoryModel[] | null,
};

type AccordionOpen = {
    history: boolean,
    share: boolean,
};

// const validatorScheme = {
//     required: ['answers'],
// };

const emailValidatorScheme = {
    required: ['email'],
    custom: [email(['email'])],
};

const generator = generateId();

const TemplatesContainer: FC<IProps> = ({
                                            template,
                                            questionnaire,
                                            token,
                                            surveyChart,
                                            surveyChartPreview,
                                            surveyRelated,
                                            surveyHistory}) => {
    const userData = useSelector((state: RootState) => state.user.userData);
    const dispatch = useDispatch();

    const initialState = {
        modalType: null,
    };

    const initialAccordionOpen = {
        history: false,
        share: false,
    }

    const initialFormData = {
        token: token,
        answers: [],
        admission_date: new Date(),
        discharge_date: new Date(),
        notes: questionnaire.notes || '',
    }
    const [Prompt, setDirty, setClear] = useUnsavedChangesWarning();
    const [changedSurvey, setChangedSurvey] = useState(false);
    const [accordionOpen, setOpen] = useState<AccordionOpen>(initialAccordionOpen);
    const [surveyCopied, setSurveyCopied] = useState(false);
    const [email, setEmail] = useState('');
    const [openEmailPopup, setOpenEmailPopup] = useState(false);
    const [emailErrors, setEmailErrors] = useState<KeyValueModel<string | boolean>>({});

    const [formData, setFormData] = useState<SurveySaveModel>(initialFormData);
    const [categoryList, setCategoryList] = useState<QuestionCategory[] | null>(questionnaire.answers || template.fields);
    const [surveyInfo, setSurveyInfo] = useState<QuestionnaireModel>(questionnaire);
    const [surveyStatus, setSurveyStatus] = useState<string>(questionnaire.status);
    // const [errors, setErrors] = useState<KeyValueModel<string | boolean>>({});
    const [errorSurveyQuestion, setErrorSurveyQuestion] = useState<boolean>(false);

    const [completeFail, setCompleteFail] = useState<string>('');
    const [showSurveyChartPreview, setShowSurveyChartPreview] = useState(false);
    let [state, setState] = useState<IState>(initialState);

    const showScoreByType =
      template.type_color.alias === 'with_dial_and_score' ||
      template.type_color.alias === 'with_score';
    const showDialByType =
      template.type_color.alias === 'with_dial_and_score' ||
      template.type_color.alias === 'with_dial';

    const handleCloseModal = () => {
        setState({...state, modalType: null});
    };

    const handleShareSurvey = () => {
      if (token) {
        const { errors } = validate(emailValidatorScheme, {email});
        setEmailErrors(errors);

        if (isEmptyObject(errors)) {
          const sendData = { email, token };
          dispatch(
            shareSurvey({
              data: sendData,
              callback: () => {
                setState({ ...state, modalType: 'sendEmail-success' });
                setEmail('');
                setOpenEmailPopup(false);
              },
            }),
          );
        }
      }
    };

    const printSurvey = () => {
        let body = document.getElementsByTagName('body')[0].innerHTML;
        let el = document.getElementById('print')?.innerHTML;
        if (el) {
            document.getElementsByTagName('body')[0].innerHTML = el;
        }
        window.print();
        window.location.reload();
    };

    const handleChangeSurvey = () => {
        setChangedSurvey(true);
    }
    const collectFields = (id : number, categoryQuestions : Question[]) => {
        const currentCategoryIndex = categoryList?.findIndex(category => category.id === id)

        const updateCategory = (list : QuestionCategory[] | null) => {
            const newCategory = JSON.parse(JSON.stringify(list));
            if(currentCategoryIndex !== undefined) {
                newCategory[currentCategoryIndex].items = categoryQuestions;
            }
            return newCategory;
        }

        setCategoryList(updateCategory(categoryList));
        setFormData({...formData, answers: updateCategory(categoryList)});
        if (changedSurvey) {
            setDirty();
        }
        if (surveyChartPreview && showSurveyChartPreview) {
            clearChartPreview();
            setShowSurveyChartPreview(false);
        }
    }

    const disabled = (formData: SurveySaveModel) => {
        let disabled = false;
        if (formData.answers.length) {
            formData.answers.map((category) => {
                for (let i = 0; i < category.items.length; i++) {
                    if (category.items[i].answers) {
                        if (!category.items[i].answers?.length && category.items[i].type !== 'input') {
                            return (disabled = true);
                        } else {
                            // if (category.items[i].type !== 'input') {
                            category.items[i].options?.map((item) =>
                            {
                                category.items[i].answers?.includes(item.id)
                                    ? item.subQuestions.map((el) => {
                                        // if (el.type === 'input' && !el.textArea) {
                                        //     return (disabled = true);
                                        // }
                                        if (el.type !== 'input') {
                                            if (!el.answers?.length) {
                                                return (disabled = true);
                                            }
                                        }
                                    })
                                    : item
                            }
                            );
                        // }
                        }
                    }
                }
            });
        } else {
            disabled = true;
        }

      return disabled;
    };

    if (!disabled(formData) && !showSurveyChartPreview && surveyStatus !== 'Completed') {
        setShowSurveyChartPreview(true);
        dispatch(sendPreviewSurvey({answers: formData.answers}));
    }

    const saveSurveyData = (e:Event , action : 'save' | 'update' , data : SurveySaveModel) => {
        e.preventDefault();

        // const {errors} = validate(validatorScheme, formData);
        // setErrors(errors);
        
        // if (isEmptyObject(errors) ) {

        const sendData = {
            ...data,
            admission_date: moment(data.admission_date).format('YYYY-MM-DD'),
            discharge_date: moment(data.discharge_date).format('YYYY-MM-DD'),
        }

            if (action === 'save') {
                if (disabled(formData)) {
                    setErrorSurveyQuestion(true)
                } else {
                    setErrorSurveyQuestion(false)
                }
                if (disabled(formData) === false) {
                dispatch(sendSurvey({
                    data: sendData,
                    callback: (message) => { 
                        if (message.message.indexOf('of non-object') !== -1){
                            setState({...state, modalType: 'unable-to-save'});
                        } else if (message.message.indexOf('Undefined index') !== -1 || message.message.indexOf('Division by zero') !== -1){
                            setState({...state, modalType: 'error'});
                            setCompleteFail(message.message);

                        } else {
                            setState({...state, modalType: 'complete-success'});
                            setSurveyStatus('Completed');
                            setClear();
                        }
                    }
                }));
            }
            } else if (action === 'update') {
                dispatch(updateSurvey({
                    data: sendData,
                    callback: () => {
                        setState({...state, modalType: 'update-success'});
                        setErrorSurveyQuestion(false);
                        setClear();
                    }
                }));
                if (token) {
                dispatch(getSurvey({token}));
                }
            }
        // }
    }

    useEffect(() => {
        const inputErrorElements = document.getElementsByClassName('errorSurveyQuestion');
        if (inputErrorElements.length) {
          let posTop = window.pageYOffset;
          console.log('coordTOP', posTop)

          const coord = inputErrorElements[0].getBoundingClientRect();
          window.scrollTo(0, posTop - 200 + coord.y);
          console.log('coord', coord)

        }
      }, [errorSurveyQuestion]);

    const scrollToCategory = (index: number) => {
        const categoryTitleRef = document.getElementsByClassName('categoryTitleRef');
        if (categoryTitleRef.length) {
            const coord = categoryTitleRef[index].getBoundingClientRect();
            window.scrollTo(0, window.scrollY - 10 + coord.y);
        }
    };

    const getRisk = (score : number) => {
        if (score < 15) {
            return "Low";
        }
        else if (score > 30) {
            return "High";
        }
        else {
            return "Medium";
        }
    }

    const getIcon = (alias : string) => {
        if (alias === 'Sent') {
            return "--created";
        }
        else if (alias === 'in_progress' || alias === 'In Progress') {
            return "--updated";
        }
        else if (alias === 'Completed') {
            return "--completed";
        }
    }

    return (
        <>
            <div className={'b-mainContainer__wrapperInner'}>
                <div className="b-mainContainer__contentWrapper">

                    <div className="b-survey">
                        <div className="b-survey__heading">
                            <div 
                            // style={{backgroundColor: template.type_color.color}}
                             className="b-survey__headingAva">{template.initial}1</div>
                            <div className="b-survey__headingHolder">
                                <div className="b-survey__headingTitle">{template.title}</div>
                                <ul>
                                    <li>{template.type_color.title}</li>
                                    <li>{`${surveyInfo.creator.first_name} ${surveyInfo.creator.last_name}`}</li>
                                    <li>{moment(template.date_of_creation).format('MM-DD-YYYY')}</li>
                                </ul>
                            </div>
                        </div>

                        <div className="b-infoBar">
                            <ul className="b-infoBar__list">
                                <li><img src={AvatarPlaceholder} className={'b-infoBar__ava'}/> Patient: {`${surveyInfo.patient.first_name} ${surveyInfo.patient.last_name}`}</li>
                                <li>ID: {surveyInfo.patient.id}</li>
                                <li>Date of Birth: {moment(surveyInfo.patient.dob).format('MM-DD-YYYY')}</li>
                            </ul>
                        </div>
                        {/* b-surveyForm start */}
                        <form action="#" className="b-surveyForm">

                            <div className="b-surveyForm__dateRow">

                                <div className={'b-surveyForm__dateCol'}>
                                    <div className={'b-form__datePikerLabel'}>
                                        Current admission date (if applicable)
                                    </div>
                                    <DatePicker minDate={new Date()}
                                                dateFormat={'MM.dd.yyyy'}
                                                selected={formData.admission_date}
                                                onChange={date => setFormData({...formData, admission_date: date})} 
                                                disabled={surveyStatus === 'Completed'} />
                                </div>

                                <div className={'b-surveyForm__dateCol'}>
                                    <div className={'b-form__datePikerLabel'}>
                                        Discharge date (if known)
                                    </div>
                                    <DatePicker minDate={new Date()}
                                                dateFormat={'MM.dd.yyyy'}
                                                selected={formData.discharge_date}
                                                onChange={date => setFormData({...formData, discharge_date: date})}
                                                disabled={surveyStatus === 'Completed'} />
                                </div>

                            </div>
                            {/* b-surveyForm block start */}
                            <div id={'print'} className="b-surveyForm__categoryBlock">
                                {categoryList && categoryList.length > 0
                                    ? categoryList.map((category) => (
                                        <SurveyCategory
                                            key={`categoryItem-${category.id}`}
                                            title={category.title}
                                            categoryId={category.id}
                                            categoryItems={category.items}
                                            collectFields={collectFields}
                                            errorSurveyQuestion={errorSurveyQuestion}
                                            handleChangeSurvey={handleChangeSurvey}
                                            surveyStatus={surveyStatus}
                                        />
                                    ))
                                    : null}
                            </div>
                            {/* b-surveyForm block end */}

                            {/* b-surveyForm block start */}
                            {showScoreByType || showDialByType && !disabled(formData) ? 
                            <div className="b-surveyForm__block">
                                <h2 className="b-surveyForm__tilte">Assessment</h2>
                                <div className="b-surveyForm__chart">
                                    <div className="b-surveyForm__chart__sideBlock -chart">
                                        <div className="b-surveyForm__chart__chartHolder">
                                            <div className='b-surveyForm__chart__chartFrame'>
                                                <ReactSpeedometer
                                                    width={400}
                                                    height={250}
                                                    minValue={0}
                                                    maxValue={100}
                                                    fluidWidth={true}
                                                    needleHeightRatio={0.42}
                                                    ringWidth={25}
                                                    value={showScoreByType ? surveyChartPreview?.score || surveyChart?.score || questionnaire.score || 0 : 0}
                                                    maxSegmentLabels={3}
                                                    segments={3}
                                                    customSegmentStops={[0,17,32,100]}
                                                    currentValueText={' '}
                                                    // customSegmentLabels={[
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    // ]}
                                                    segmentColors={[
                                                        "#6FDE47",
                                                        "#E5D012",
                                                        "#DB4E6F",
                                                    ]}
                                                    needleColor="#515151"
                                                />
                                                <div className={`score-container`}>
                                                    <div className={`score`}>
                                                        <span>{showScoreByType ? surveyChartPreview?.score && Math.round(surveyChartPreview?.score) || surveyChart?.score && Math.round(surveyChart?.score) || questionnaire.score && Math.round(questionnaire.score) || 0 : 0}</span>
                                                    </div>
                                                </div>
                                                <div className={`score-level-container`}>
                                                    <div className={'score-level'}>
                                                        <span>{showScoreByType ? surveyChartPreview?.score_level && `${surveyChartPreview?.score_level} Risk` || surveyChart?.score_level && `${surveyChart?.score_level} Risk` || questionnaire.score_level && `${questionnaire.score_level} Risk` : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="b-surveyForm__chart__sideBlock -chartDetails">
                                        <h2 className="b-surveyForm__tilte">Contributing Factors</h2>
                                        <div className={"b-surveyForm__chart__factors"}>
                                            {showScoreByType && surveyChartPreview?.category_score_level ?
                                                surveyChartPreview.category_score_level.map((category, index) => {
                                                    return (
                                                        <div onClick={() => scrollToCategory(index)} key={`type-${index}-${category.title}`} className={`factor ${category.score_level.toLowerCase()}`}>
                                                            <span className={"factor-text"}>{category.title}</span>
                                                        </div>
                                                    )
                                                })
                                                :
                                                surveyChart?.category_score_level ?
                                                    surveyChart.category_score_level.map((category, index) => {
                                                        return (
                                                            <div onClick={() => scrollToCategory(index)} key={`type-${index}-${category.title}`} className={`factor ${category.score_level.toLowerCase()}`}>
                                                                <span className={"factor-text"}>{category.title}</span>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    questionnaire.category_score_level ?
                                                        questionnaire.category_score_level.map((category, index) => {
                                                            return (
                                                                <div onClick={() => scrollToCategory(index)} key={`type-${index}-${category.title}`} className={`factor ${category.score_level.toLowerCase()}`}>
                                                                    <span className={"factor-text"}>{category.title}</span>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <ul className="riskList">
                                    <li
                                    className="riskList__item --high"
                                    >
                                    High risk
                                    </li>
                                    <li
                                    className="riskList__item --medium"
                                    >
                                    Medium risk
                                    </li>
                                    <li
                                    className="riskList__item --low"
                                    >
                                    Low risk
                                    </li>
                                </ul>
                            </div>
                            : null}
                            {/* b-surveyForm block end */}

                            {/* b-surveyForm block start */}
                            {surveyRelated?.length ? 
                                <div className="b-surveyForm__block">
                                    <h2 className="b-surveyForm__tilte">Related Questionnaires</h2>

                                    <div className="b-relatedQuestionnaires">
                                        {surveyRelated.map((related) => {
                                            const dateTime = related.created_at.split('|')
                                            const [date, time] = dateTime;

                                            return(
                                                <div key={`related${related.id}`} className="b-relatedQuestionnaires__item">
                                                    <span className={`b-relatedQuestionnaires__number ${getRisk(related.score).toLowerCase()}`}>{Math.round(related.score)}</span>
                                                    <div className="b-relatedQuestionnaires__holder">
                                                        <div className="b-relatedQuestionnaires__title">{`Risk: ${related.score_level ? related.score_level : getRisk(related.score)} Risk`}</div>
                                                        <ul>
                                                            <li>{date}</li>
                                                            <li>{time}</li>
                                                            <li>{`${related.creator.first_name} ${related.creator.last_name}`}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            : null }
                            {/* b-surveyForm block end */}

                            <div className="b-surveyForm__buttons">

                                <div className="b-surveyForm__buttonsLeft">
                                    <Button
                                        title={'save'}
                                        className={'-uppercase'}
                                        onPress={e => saveSurveyData(e, 'update' , formData)}
                                        disabled={!changedSurvey || surveyStatus === 'Completed'}
                                    />
                                    {changedSurvey || surveyStatus === 'In Progress' ? 
                                    <Button
                                        title='Complete'
                                        className={'darkest -mr-10 -uppercase'}
                                        onPress={e => saveSurveyData(e, 'save' ,formData)}
                                        disabled={surveyStatus === 'Completed'}
                                    />
                                    : null}
                                </div>

                            </div>
                        </form>
                        {/* b-surveyForm end */}

                        {/* accordion start */}
                        {showDialByType ? 
                        <>
                        <div className="b-surveyForm__notes">
                            <Textarea
                                id={'notes'}
                                value={formData.notes}
                                label={'Notes'}
                                placeholder={''}
                                disable={surveyStatus === 'Completed'}
                                onChange={text => setFormData({...formData, notes: text})}/>
                        </div>
                        <div className="b-accordion">
                            <div className={`b-accordion__item ${accordionOpen.history ? '--opened' : ''}`}>
                                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, history: !accordionOpen.history })}>
                                    <span className="b-accordion__title">Questionnaire History</span>
                                    <span className="b-accordion__arrow"></span>
                                </div>
                                <div className="b-accordion__content">
                                    <div className="b-accordion__contentHolder">
                                        <div className="b-accordion__contentText" style={{width : '100%'}}>
                                            <div className={'b-historyTable'}>
                                                <table>
                                                    <tbody>
                                                    { surveyHistory && surveyHistory.map( (historyItem, index) => {
                                                        return(
                                                            <tr key={`history-${index}`}>
                                                                <td><span className={`b-historyTable__icon ${getIcon(historyItem.alias)}`}></span></td>
                                                                <td><b>{`${historyItem.creator.first_name} ${historyItem.creator.last_name}`}</b></td>
                                                                <td>{historyItem.status_type}</td>
                                                                <td><b>{historyItem.date_of_week}</b></td>
                                                                <td>{historyItem.date_of_creation}</td>
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {userData?.option.permissions.fill_questionnaire ?
                            <div className={`b-accordion__item ${accordionOpen.share ? '--opened' : ''}`}>
                                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, share: !accordionOpen.share })}>
                                    <span className="b-accordion__title"> Share This Questionnaire</span>
                                    <span className="b-accordion__arrow"></span>
                                </div>
                                <div className="b-accordion__content">
                                    <div className="b-accordion__contentHolder">
                                        <div className="b-accordion__contentText">
                                            <p>Sharing the information contained in this survey is only intended for appropriate discharge planning purposes. You agree that this function is part of your official job duties and responsibilities, and the intended recipient is involved in provision of care and services to this patient. You also agree that you will NOT use this service to violate any applicable state and federal laws, contracts, third-party rights, or internal GENERIC Health System rules, policies and/or procedures. You acknowledge and agree that the officers, directors and employees of GENERIC Health System are not responsible/liable for your conduct in using this service inappropriately.</p>
                                            <div className="b-shareLink">
                                                <span className="b-shareLink__label">Copy this link to your clipboard</span>
                                                <div className="b-shareLink__inputRow">
                                                    <div style={{width: '100%', cursor: 'pointer'}} onClick={(e) => {
                                                        navigator.clipboard.writeText(e.target.value);
                                                        setSurveyCopied(true);
                                                        setTimeout(() => setSurveyCopied(false), 1000);
                                                    }} >
                                                        <Input
                                                            disable={true}
                                                            className="b-shareLink__input"
                                                            cursor={'pointer'}
                                                            value={`${MAIN_URI}/fill-questionnaire/${token}`}
                                                            onChange={() => null}
                                                            />
                                                        {surveyCopied ? <p className="b-tooltipCopy">Survey copied</p> : null}
                                                    </div>
                                                    <button disabled={false} onClick={() => printSurvey()} type="button" className="b-shareLink__btn b-shareLink__btn--print"></button>
                
                                                    <div className="p-datatable-filters__popupContainerShare">
                                                        <button onClick={() => setOpenEmailPopup(!openEmailPopup)} type="button" className="b-shareLink__btn b-shareLink__btn--send"></button>
                                                        <div className={`p-datatable-filters__popupShare ${openEmailPopup ? 'active' : ''}`}>
                                                            <div className="p-datatable-filters__popupFrame">
                                                                <div className="p-datatable-filters__popupContentShare">
                                                                    <div className="p-datatable-filters__containerShare">

                                                                    <div className={'p-datatable-filters__colShare'}>
                                                                        <Input
                                                                            value={email}
                                                                            label={'Email *'}
                                                                            width={'220px'}
                                                                            error={emailErrors.email}
                                                                            onChange={(value) => setEmail(value)} />
                                                                    </div>
                                                                    <Button
                                                                        title={'send'}
                                                                        className={'-uppercase -ml-10'}
                                                                        onPress={() => handleShareSurvey()}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                        </div>
                        </>
                        : null}
                        {/* accordion end */}
                    </div>

                </div>
            </div>
            {state.modalType === 'update-success' && <Modal
                title='Success'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Survey<i><b>&nbsp;“{template.title}” </b></i>&nbsp;saved
                </div>
                </div>
            </Modal>}
            {state.modalType === 'complete-success' && <Modal
                title='Success'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Survey<i><b>&nbsp;“{template.title}” </b></i>&nbsp;completed
                </div>
                </div>
            </Modal>}
            {state.modalType === 'sendEmail-success' && <Modal
                title='Success'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Survey<i><b>&nbsp;“{template.title}” </b></i>&nbsp;sent
                </div>
                </div>
            </Modal>}
            {state.modalType === 'error' && <Modal
                title='Fail'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                   Answer all questions in the category: <b>{completeFail.split(':')[1]}</b>
                </div>
                </div>
            </Modal>}
            {state.modalType === 'unable-to-save' && <Modal
                title='Error'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Unable to save questionnaire because it has been deleted
                </div>
                </div>
            </Modal>}
            {Prompt}
        </>
    );
};

export default TemplatesContainer;
