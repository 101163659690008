import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import styles from './ReportsView.module.scss'
import HeaderSearch from "../../common/HeaderSearch/HeaderSearch";
import DatePicker from "react-datepicker";
import Button from "../../controls/Button/Button";
import {getReports, ReportsData} from "../../../store/reports/reportSlice";
import moment from "moment";


import {
   Reports
} from '../../../store/reports/reportSlice';
import Modal from '../../common/Modal/Modal';
import { RootState } from '../../../store/reducers';
import { Routes } from '../../../routes/routes';

interface IState {
  modalType: 'permissions-fail' | null,
}

const initialState: IState = {
  modalType: null,
};

const ReportsView = () => {
  const userData = useSelector((state: RootState) => state.user.userData);
  const history = useHistory();

  const initialData = {
    date_from: new Date(),
    date_to: new Date(),
  };
  
  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState<ReportsData>(initialData);
  let [state, setState] = useState<IState>(initialState);
  const [permissionsError, setpermissionsError] = useState<string>(`You don't have permissions to manage Reports`);

  const handleGenerateReport = (e: Event): void => {
    e.preventDefault();
    const sendData = {
      ...dateValue,
      date_from: moment(dateValue.date_from).format('YYYY-MM-DD'),
      date_to: moment(dateValue.date_to).format('YYYY-MM-DD'),
    };

    dispatch(getReports({data: sendData, callback: (message) => {
      if (message) {
        setState({...state, modalType: 'permissions-fail'});
        // setpermissionsError('Sorry, but you have not permissions for generate repots');
      }
    }}));
  };
  
  const handleClearDate = (e: Event): void => {
    e.preventDefault();
    setDateValue({ ...initialData });
  };

  return (
    userData?.option.permissions.view_reports ? 
    <>
      <div className={'b-mainContainer__wrapper'}>
        <HeaderSearch
            pageName='Reports'
            isSearhVisible={false}
        />

        <div className={'b-mainContainer__wrapperInner'}>
          <div className={`b-mainContainer__contentWrapper ${styles.containerWrapperInner}`}>
            <form className={styles.controlWrapper}>
              <div className={'b-form__formGroup'}>
                <div className={'b-form__datePikerLabel'}>
                  From Date *
                </div>
                <DatePicker maxDate={dateValue.date_to || new Date()}
                            dateFormat={'MM.dd.yyyy'}
                            selected={dateValue.date_from}
                            onChange={date => setDateValue({...dateValue, date_from: date})}/>
              </div>
              <div className={'b-form__formGroup'}>
                <div className={'b-form__datePikerLabel'}>
                  To Date *
                </div>
                <DatePicker minDate={dateValue.date_from || new Date()}
                            dateFormat={'MM.dd.yyyy'}
                            selected={dateValue.date_to}
                            onChange={date => setDateValue({...dateValue, date_to: date})}/>
              </div>
              <Button 
                  title={'generate'} 
                  className={`-uppercase ${styles.btn}`}
                  onPress={handleGenerateReport}/>
              <Button 
                  title={'clear'} 
                  className={`-uppercase ${styles.btn}`}
                  type={'transparency'}
                  onPress={handleClearDate}/>
            </form>
          </div>
        </div>
      </div>
     </>
    //  : <>{`You don't have permissions to manage Reports`}</>
    : <Modal
        title='Error'
        width={520}
        onClose={() => {
          // setState({...state, modalType: null}),
          // setpermissionsError(''),
          history.push(Routes.DASHBOARD);
        }}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            {permissionsError}
          </div>
        </div>
      </Modal>
  );
};

export default ReportsView;
