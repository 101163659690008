import {call, all, take, StrictEffect, put} from 'redux-saga/effects';
import {NotificationManager} from "react-notifications";
import {
  login,
  getRestorePasswordToken,
  resetPassword,
  LoginPayload,
  loginSuccess,
  logout,
  logoutSuccess,
  RestorePasswordTokenPayload,
  ResetPasswordPayload,
  getUserDetails,
  getUserDetailsSuccess,
  updateUserDetails, updateUserPassword, PolicyPayload, acceptPolicySuccess, acceptPolicy,
} from './userSlice';
import Api from '../../services/api';
import {UserDataUpdatePayload, UserPasswordUpdatePayload} from "../../models/user/user.model";

function* loginSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {email, password, callback} }: {payload: LoginPayload} = yield take(login.toString());

    try {
      const response = yield call(Api.post, `auth/login`, {email, password});
      // const response = yield call(Api.get, `main/get-pages`);
      if (response) {
        yield put(loginSuccess(response.data));
        localStorage.setItem('access_token', response.data.tokenData.access_token);
        localStorage.setItem('is_policy_accepted', response.data.userData.option.is_policy_accepted);
      }
    } catch (e: any) {
      console.log('error message222', e.response);
      if (e.response.status === 400) {
        callback(e.response.data.errors);
      }
      if (e.response.status === 403) {
        if (callback){
          callback(e.response);
        }
      }
    }
  }
}

function* logoutSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(logout.toString());

    try {
      const response = yield call(Api.get, `auth/logout`);
      if (response) {
        // localStorage.removeItem('access_token');
        yield put(logoutSuccess());
      }
    } catch (e) {
      console.log('error message', e);
      NotificationManager.error('Error', 'Oops... something went wrong', 4000);
    }
  }
}

function* getRestorePasswordTokenSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {email,callback} }: {payload: RestorePasswordTokenPayload} = yield take(getRestorePasswordToken.toString());

    try {
      const response = yield call(Api.post, `auth/get-token-to-reset-password`, {email});
      if (response) {
        callback({message:'success'});
      }
    } catch (e: any) {
      console.error('restorePasswordToken_error', e.response);
      if (e.response.status === 400) {
        callback(e.response.data.errors);
      }
    }
  }
}

function* resetPasswordSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {password,token,callback} }: {payload: ResetPasswordPayload} = yield take(resetPassword.toString());

    try {
      const response = yield call(Api.post, `auth/restore-password`, {password,token});
      if (response) {
        callback({message:response.message});
      }
    } catch (e: any) {
      console.error('resetPassword_error', e.response);
      if (e.response.status === 400) {
        callback(e.response.data.errors);
      }
    }
  }
}

function* getUserDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getUserDetails.toString());

    try {
      const response = yield call(Api.get, `user/get-user-details`);
      if (response) {
        yield put(getUserDetailsSuccess(response.data.resource));
      }
    } catch (e) {
      console.log('error message', e);
    }
  }
}

function* updateUserDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { data, callback } }: { payload: UserDataUpdatePayload } = yield take(updateUserDetails.toString());
    try {
      const response = yield call(Api.put, `auth/update-user-profile`, undefined, data);
      if (response) {
        NotificationManager.success('Success', 'Profile data has been changed', 4000);
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('error message222', e.response);
      if (e.response.status === 400) {
        callback && callback(e.response.data.errors);
      }
    }
  }
}

function* updateUserPasswordSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { data, callback } }: { payload: UserPasswordUpdatePayload } = yield take(updateUserPassword.toString());
    try {
      const response = yield call(Api.put, `auth/update-user-profile`, undefined, data);
      if (response) {
        NotificationManager.success('Success', 'Password has been changed', 4000);
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* acceptPolicySaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {is_policy_accepted, callback} }: {payload: PolicyPayload} = yield take(acceptPolicy.toString());

    try {
      const response = yield call(Api.post, `user/set-user-options`, {is_policy_accepted});
      if (response) {
        // localStorage.removeItem('is_policy_accepted');
        localStorage.setItem('is_policy_accepted', response.data.userData.option.is_policy_accepted);
        yield put(acceptPolicySuccess());
        callback();

      }
    } catch (e: any) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([
    loginSaga(),
    logoutSaga(),
    getRestorePasswordTokenSaga(),
    resetPasswordSaga(),
    getUserDetailsSaga(),
    updateUserDetailsSaga(),
    updateUserPasswordSaga(),
    acceptPolicySaga(),
  ]);
}
