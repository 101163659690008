import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './QuestionForm.module.scss';
import stylesPreview from '../../../TemplatesList/TemplatesListView.module.scss'

import closeIcon from '../../../../../assets/images/icons/modal_close_ic.svg';
import Image from "../../../../controls/Image/Image";
import arrow from '../../../../../assets/images/icons/arrow_right.svg'


import { Question, QuestionCategory, QuestionFormErrorsModel, QuestionOptions, QuestionParent } from '../../../../../models/templates/templates.model';
import ReactTooltip from 'react-tooltip';
import Button from '../../../../controls/Button/Button';
import { Input } from '../../../../controls';
import { useDrop } from 'react-dnd';
import SurveyQuestion from '../../../Survey/parts/SurveyQuestion/SurveyQuestion';

type IProps = {
  style: CSSProperties;
  categoryList: QuestionCategory[];
  data: Question;
  openPopup: (data: Question, prevData?: Question) => void;
  closePopup: () => void;
  saveForm: (form: Question) => void;
  errors: QuestionFormErrorsModel | undefined;
};

const QuestionForm: FC<IProps> = ({ data, categoryList, openPopup, closePopup, style, saveForm, errors }) => {

  const [form, setForm] = useState<Question>(data);

  useEffect(() => {
    if (data.type === 'switcher') {
      const newData: Question = JSON.parse(JSON.stringify(data));
      newData.answers?.length == 0 ? newData.answers = [2] : newData.answers,
      setForm(newData)
    } else {
      setForm(data)
    }
  }, [data]);

  let nameOfCategory='';
  let nameOfQuestion='';
  let nameOfOption='';
  if (form.parent) {
    let categoryListCopy = categoryList.filter(
      (item) => item.id === form.parent?.category_id
    );
    nameOfCategory = categoryListCopy[0].title;

    let arrayItems: Question[] = categoryListCopy[0].items;

    arrayItems.map((elem) => (elem.id === form.parent?.mainQuestion_id ?
        ((nameOfQuestion = elem.title), elem.options?.map((item)=> item.id === form.parent?.option_id ? (nameOfOption = item.title): item)) : elem));
    
        categoryListCopy = categoryList.map((el) =>
      el.id === form.category_id ? { ...el, items: [...arrayItems] } : el,
    );
  }

  const updateOptions = (type: 'add' | 'delete' | 'answer', optionId?: number): void => {
    let items: QuestionOptions[];
    if (form.options) {
      items = [...form.options];

      switch (type) {
        case 'add': {
          items.push({
            id: items[items.length - 1] ? items[items.length - 1].id + 1 : 0,
            title: '',
            weight: '0',
            subQuestions: [],
            subAnswer: false
          });
          break;
        }
        case 'delete': {
          items = items.filter((item) => item.id !== optionId);
          break;
        }
        case 'answer': {
          items = items.map((item) => item.id === optionId ? {...item, subAnswer: !item.subAnswer} : {...item, subAnswer: false});
          break;
        }
      }

      setForm((form) => ({
        ...form,
        options: [...items],
      }));
    }
  };

  const subQuestionManipulate = (
    type: 'copy' | 'delete',
    questionId: number,
    parent?: QuestionParent,
  ): void => {
    let items: QuestionOptions[] = [...form.options];
    
    switch (type) {
      case 'copy': {
        break;
      }
      case 'delete': {
        items = items.map((el) =>
          el.id === parent?.option_id
            ? { ...el, subQuestions: [...el.subQuestions.filter((el) => el.id !== questionId)] }
            : el,
        );
        break;
      }
    }
  
    setForm((form) => ({
      ...form,
      options: [...items],
    }));
  };

  const handleChangeFormTitle = (value: string, fieldName?: string) => {
    setForm((form) => ({ ...form, [fieldName || 'title']: value }));
  };

  const handleChange = (fieldName: string, value: string, optionId: number) => {
    let items, changeItems: QuestionOptions[];

    if (form.options) {
      items = [...form.options];

      changeItems = items.map((item) =>
        item.id == optionId ? { ...item, [fieldName]: value } : { ...item }
      );
      setForm((form) => ({
        ...form,
        options: [...changeItems],
      }));
    }
  };
  
const currentOption = form.options?.filter((item) => item.subAnswer === true)

const [, dropSubQuestion] = useDrop({
    accept: 'questionType',
    drop: () => ({optionId: currentOption && currentOption[0].id, question: form}),
  });

  const collectAnswers = (type: 'sub' | 'main', id: number, answers?: number[], parent?: QuestionParent, text?: string) => {
    // const currentQuestionIndex = categoryItems.findIndex(question => question.id === id)

    // const changeAnswer = (categoryItems : Question[]) => {
    //     const newCategory = JSON.parse(JSON.stringify(categoryItems));
    //     newCategory[currentQuestionIndex].answers = answers;
    //     return newCategory;
    // }

    // setCategory(changeAnswer(categoryItems))
}

  const renderTab = (type: string) => {
    switch (type) {
      case 'input':
        return (
          <>
            <div className={styles.body}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={form.title}
                  label={'Title *'}
                  error={errors?.questionForm.title}
                  onChange={(value) => handleChangeFormTitle(value)}
                />
              </div>
              <div className={styles.body__box}>
              <div className="b-surveyForm__itemLabel" style={{fontSize: '14px'}}>
                <span className="b-surveyForm__iconSurvey"></span>
                <span className="b-surveyForm__itemQuestionpDescription">Description</span>
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={form.description}
                  onChange={(value) => handleChangeFormTitle(value, 'description')}
                />
              </div>
              </div>
            </div>
          </>
        );
      case 'textarea':
        return (
          <>
            <div className={styles.body}>
              <div className={styles.body__box}>
                <div className={styles.body__title}>Title</div>
                <input
                  value={form.title}
                  onChange={(e) => handleChangeFormTitle(e.target.value)}
                  className={styles.body__inputTitle}
                  type="text"
                />
              </div>
            </div>
          </>
        );
      case 'radio':
        return (
          <>
            <div className={styles.body}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={form.title}
                  label={'Title *'}
                  error={errors?.questionForm.title}
                  onChange={(value) => handleChangeFormTitle(value)}
                />
              </div>
              <div className={styles.body__box}>
              <div className="b-surveyForm__itemLabel" style={{fontSize: '14px'}}>
                <span className="b-surveyForm__iconSurvey"></span>
                <span className="b-surveyForm__itemQuestionpDescription">Description</span>
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={form.description}
                  onChange={(value) => handleChangeFormTitle(value, 'description')}
                />
              </div>
              </div>
              {form.options?.map((option: QuestionOptions, index: number) => (
                // <div key={`opt-${option.id}`} className={'b-form__row -two_column'}>
                <div key={`opt-${option.id}`} className={styles.body__box}>
                <div className={styles.body__options}
                //  style={{marginRight: '-20px'}}
                 >
                  <div className={styles.body__inputTitle}>
                      <div className={'b-form__formGroup'}>
                        <Input
                          value={option.title}
                          label={`${index + 1} choice *`}
                          {...form.parent ? null :
                            {type: 'addSub', 
                            pl: true}
                          }
                          update={() => updateOptions('answer', option.id)}
                          error={errors?.questionFormOptions[index]?.title}
                          onChange={(value) => handleChange('title', value, option.id)}
                        />
                      </div>
                  </div>
                  <div className={styles.body__inputWeight}>
                      <div className={'b-form__formGroup'} data-tip data-for={`weight-${option.id}`}>
                        {option.subQuestions.length ? <ReactTooltip id={`weight-${option.id}`} className='b-tooltip'>
                          You are able to set a weight if an answer does not have a decision tree below
                        </ReactTooltip> : null}
                        <Input
                          value={option.weight}
                          label={`weight *`}
                          disable={!!option.subQuestions.length}
                          // type={form.options?.length > 2 ? 'number' : undefined}
                          error={errors?.questionFormOptions[index]?.weight}
                          onChange={(value) => handleChange('weight', value, option.id)}
                        />
                      </div>
                  </div>
                  {form.options && form.options.length < 3 ? null : (
                      <button
                      className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                      style={{marginTop: '33px', marginLeft: '10px'}}
                      onClick={() => updateOptions('delete', option.id)}>
                      </button>
                    )}
                </div>
                { option.subQuestions.length ? 
                   <span className={`${styles.arrowRight}`}>
                   <img src={arrow} />
                 </span>
              : null}
                   { option.subQuestions.length ? option.subQuestions.map((item, idx) => 
                   (
                    <div 
                      className="templates__listItemSubQuestionPreview"
                      key={`questionSubItem${item.id}`}
                      // id={name}
                      // data-handler-id={handlerId}
                      // ref={refQ}
                      // style={{ marginLeft: '30px'}}
                      >
                        <div className='previewEdit'> 
                        {/* <button
                          className={`${styles.buttonPreview} ${styles.buttonPreview__copy}`}
                          // onClick={() => copyTemplate(template.id)}
                          >
                        </button> */}
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__edit}`}
                          onClick={() => openPopup(item, form)}
                          >
                        </button>
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                          onClick={() => subQuestionManipulate('delete', item.id, item.parent)}
                          >
                        </button>
                        </div>
                      <SurveyQuestion
                          subQuestionNumber={idx+1}
                          id={`question-${data.id}${data.id}`}
                          data={item}
                          key={`questionSubItem-${data.id}`}
                          collectAnswers={collectAnswers}
                          preview={true}
                      /> 
                  </div>

                      )
                      )
                   : null}

                   {/* { option.weight instanceof Object && <div className="templates__listItemDashedOption">1111111111</div>} */}
                   { option.subAnswer && <div className="templates__listItemDashedOption" ref={dropSubQuestion}></div>}

                   {/* <div className="templates__listItemDashed" ref={drop}></div> */}

                </div>
              ))}
              <Button
              title='Add choice'
              type={'transparency'}
              className={'darkest -p-0'}
              width={'100px'}
              onPress={() => updateOptions('add')} />
            </div>
          </>
        );
        case 'radio_vertical':
        return (
          <>
          <div className={styles.body}>            
            <div className={'b-form__formGroup'}>
              <Input
                value={form.title}
                label={'Title *'}
                error={errors?.questionForm.title}
                onChange={(value) => handleChangeFormTitle(value)}
              />
            </div>
            <div className={styles.body__box}>
            <div className="b-surveyForm__itemLabel" style={{fontSize: '14px'}}>
              <span className="b-surveyForm__iconSurvey"></span>
              <span className="b-surveyForm__itemQuestionpDescription">Description</span>
            </div>
            <div className={'b-form__formGroup'}>
              <Input
                value={form.description}
                onChange={(value) => handleChangeFormTitle(value, 'description')}
              />
            </div>
            </div>
            {form.options?.map((option: QuestionOptions, index: number) => (
              // <div key={`opt-${option.id}`} className={'b-form__row -two_column'}>
              <div key={`opt-${option.id}`} className={styles.body__box}>

              <div className={styles.body__options}
              //  style={{marginRight: '-20px'}}
               >
                <div className={styles.body__inputTitle}>
                    <div className={'b-form__formGroup'}>
                      <Input
                        value={option.title}
                        label={`${index + 1} choice *`}
                        {...form.parent ? null :
                          {type: 'addSub', 
                          pl: true}}
                        update={() => updateOptions('answer', option.id)}
                        error={errors?.questionFormOptions[index]?.title}
                        onChange={(value) => handleChange('title', value, option.id)}
                      />
                    </div>
                </div>
                <div className={styles.body__inputWeight}>
                    <div className={'b-form__formGroup'} data-tip data-for={`weight-${option.id}`}>
                      {option.subQuestions.length ? <ReactTooltip id={`weight-${option.id}`} className='b-tooltip'>
                        You are able to set a weight if an answer does not have a decision tree below
                      </ReactTooltip> : null}
                      <Input
                       value={option.weight}
                        label={`weight *`}
                        disable={!!option.subQuestions.length}
                        // type={form.options?.length > 2 ? 'number' : undefined}
                        error={errors?.questionFormOptions[index]?.weight}
                        onChange={(value) => handleChange('weight', value, option.id)}
                      />
                    </div>
                </div>
                {form.options && form.options.length < 3 ? null : (
                  <button
                    className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                    style={{marginTop: '33px', marginLeft: '10px'}}
                    onClick={() => updateOptions('delete', option.id)}>
                  </button>
                  )}
              </div>
              { option.subQuestions.length ? 
                   <span className={`${styles.arrowRight}`}>
                   <img src={arrow} />
                 </span>
              : null}
              { option.subQuestions.length ? option.subQuestions.map((item, idx) => 
                   (
                    <div 
                      className="templates__listItemSubQuestionPreview"
                      key={`questionSubItem${item.id}`}
                      // id={name}
                      // data-handler-id={handlerId}
                      // ref={refQ}
                      // style={{ marginLeft: '30px'}}
                      >
                        <div className='previewEdit'> 
                        {/* <button
                          className={`${styles.buttonPreview} ${styles.buttonPreview__copy}`}
                          // onClick={() => copyTemplate(template.id)}
                          >
                        </button> */}
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__edit}`}
                          onClick={() => openPopup(item, form)}
                          >
                        </button>
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                          onClick={() => subQuestionManipulate('delete', item.id, item.parent)}
                          >
                        </button>
                        </div>
                      <SurveyQuestion
                          subQuestionNumber={idx+1}
                          id={`question-${data.id}${data.id}`}
                          data={item}
                          key={`questionSubItem-${data.id}`}
                          collectAnswers={collectAnswers}
                          preview={true}
                      /> 
                  </div>

                      )
                      )
                   : null}

                   {/* { option.weight instanceof Object && <div className="templates__listItemDashedOption">1111111111</div>} */}
                   { option.subAnswer && <div className="templates__listItemDashedOption" ref={dropSubQuestion}></div>}

                   {/* <div className="templates__listItemDashed" ref={drop}></div> */}

              </div>
            ))}
            <Button
            title='Add choice'
            type={'transparency'}
            className={'darkest -p-0'}
            width={'100px'}
            onPress={() => updateOptions('add')} />
          </div>
        </>
        );
        case 'switcher':
        return (
          <>
            <div className={styles.body}>
            <div className={'b-form__formGroup'}>
              <Input
                value={form.title}
                label={'Title *'}
                error={errors?.questionForm.title}
                onChange={(value) => handleChangeFormTitle(value)}
              />
            </div>
            <div className={styles.body__box}>
            <div className="b-surveyForm__itemLabel" style={{fontSize: '14px'}}>
              <span className="b-surveyForm__iconSurvey"></span>
              <span className="b-surveyForm__itemQuestionpDescription">Description</span>
            </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={form.description}
                  onChange={(value) => handleChangeFormTitle(value, 'description')}
                />
              </div>
            </div>
              {form.options?.map((option: QuestionOptions, index: number) => (
                <div key={`opt-${option.id}`} className={styles.body__box}>

                  <div className={styles.body__options} style={{marginRight: '-20px'}}>
                    <div className={styles.body__inputTitle}>
                        <div className={'b-form__formGroup'}>
                          <Input
                            value={option.title}
                            label={`${index + 1} choice *`}
                            {...form.parent ? null :
                              {type: 'addSub', 
                              pl: true}
                            }
                            update={() => updateOptions('answer', option.id)}
                            disable={true}
                            error={errors?.questionFormOptions[index]?.title}
                            onChange={(value) => handleChange('title', value, option.id)}
                          />
                        </div>
                    </div>
                    <div className={styles.body__inputWeight}>
                        <div className={'b-form__formGroup'} data-tip data-for={`weight-${option.id}`}>
                          {option.subQuestions.length ? <ReactTooltip id={`weight-${option.id}`} className='b-tooltip'>
                            You are able to set a weight if an answer does not have a decision tree below
                          </ReactTooltip> : null}
                          <Input
                            value={option.weight}
                            label={`weight *`}
                            disable={!!option.subQuestions.length}
                            // type={form.options?.length > 2 ? 'number' : undefined}
                            error={errors?.questionFormOptions[index]?.weight}
                            onChange={(value) => handleChange('weight', value, option.id)}
                          />
                        </div>
                    </div>
                    {/* <button
                      className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__edit}`}
                      style={{marginTop: '33px', marginLeft: '10px'}}
                      onClick={() => updateOptions('answer', option.id)}
                    >
                    </button>  */}
                  </div>
                  { option.subQuestions.length ? option.subQuestions.map((item, idx) => 
                   (
                    <div 
                      className="templates__listItemSubQuestionPreview"
                      key={`questionSubItem${item.id}`}
                      // id={name}
                      // data-handler-id={handlerId}
                      // ref={refQ}
                      // style={{ marginLeft: '30px'}}
                      >
                        <div className='previewEdit'> 
                        {/* <button
                          className={`${styles.buttonPreview} ${styles.buttonPreview__copy}`}
                          // onClick={() => copyTemplate(template.id)}
                          >
                        </button> */}
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__edit}`}
                          onClick={() => openPopup(item, form)}
                          >
                        </button>
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                          onClick={() => subQuestionManipulate('delete', item.id, item.parent)}
                          >
                        </button>
                        </div>
                      <SurveyQuestion
                          subQuestionNumber={idx+1}
                          id={`question-${data.id}${data.id}`}
                          data={item}
                          key={`questionSubItem-${data.id}`}
                          collectAnswers={collectAnswers}
                          preview={true}
                      /> 
                  </div>

                      )
                      )
                   : null}

                   {/* { option.weight instanceof Object && <div className="templates__listItemDashedOption">1111111111</div>} */}
                   { option.subAnswer && <div className="templates__listItemDashedOption" ref={dropSubQuestion}></div>}

                   {/* <div className="templates__listItemDashed" ref={drop}></div> */}

                </div>
              ))}
            </div>
          </>
        );
      case 'checkbox':
        return (
          <>
          <div className={styles.body}>            
            <div className={'b-form__formGroup'}>
              <Input
                value={form.title}
                label={'Title *'}
                error={errors?.questionForm.title}
                onChange={(value) => handleChangeFormTitle(value)}
              />
            </div>
            <div className={styles.body__box}>
            <div className="b-surveyForm__itemLabel" style={{fontSize: '14px'}}>
              <span className="b-surveyForm__iconSurvey"></span>
              <span className="b-surveyForm__itemQuestionDescription">Description</span>
            </div>
            <div className={'b-form__formGroup'}>
              <Input
                value={form.description}
                onChange={(value) => handleChangeFormTitle(value, 'description')}
              />
            </div>
            </div>
            {form.options?.map((option: QuestionOptions, index: number) => (
              // <div key={`opt-${option.id}`} className={'b-form__row -two_column'}>
              <div key={`opt-${option.id}`} className={styles.body__box}>

              <div className={styles.body__options} style={{marginRight: '-20px'}}>
                <div className={styles.body__inputTitle}>
                    <div className={'b-form__formGroup'}>
                      <Input
                        value={option.title}
                        label={`${index + 1} choice *`}
                        {...form.parent ? null :
                          {type: 'addSub', 
                          pl: true}}
                        update={() => updateOptions('answer', option.id)}
                        error={errors?.questionFormOptions[index]?.title}
                        onChange={(value) => handleChange('title', value, option.id)}
                      />
                    </div>
                </div>
                <div className={styles.body__inputWeight}>
                    <div className={'b-form__formGroup'} data-tip data-for={`weight-${option.id}`}>
                      {option.subQuestions.length ? <ReactTooltip id={`weight-${option.id}`} className='b-tooltip'>
                        You are able to set a weight if an answer does not have a decision tree below
                      </ReactTooltip> : null}
                      <Input
                        value={option.weight}
                        label={`weight *`}
                        disable={!!option.subQuestions.length}
                        // type={form.options?.length > 2 ? 'number' : undefined}
                        error={errors?.questionFormOptions[index]?.weight}
                        onChange={(value) => handleChange('weight', value, option.id)}
                      />
                    </div>
                </div>
                {form.options && form.options.length < 3 ? null : (
                  <button
                    className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                    style={{marginTop: '33px', marginLeft: '10px'}}
                    onClick={() => updateOptions('delete', option.id)}>
                  </button>
                  )}
              </div>
              { option.subQuestions.length ? 
                   <span className={`${styles.arrowRight}`}>
                   <img src={arrow} />
                 </span>
              : null}
              { option.subQuestions.length ? option.subQuestions.map((item, idx) => 
                   (
                    <div 
                      className="templates__listItemSubQuestionPreview"
                      key={`questionSubItem${item.id}`}
                      // id={name}
                      // data-handler-id={handlerId}
                      // ref={refQ}
                      // style={{ marginLeft: '30px'}}
                      >
                        <div className='previewEdit'> 
                        {/* <button
                          className={`${styles.buttonPreview} ${styles.buttonPreview__copy}`}
                          // onClick={() => copyTemplate(template.id)}
                          >
                        </button> */}
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__edit}`}
                          onClick={() => openPopup(item, form)}
                          >
                        </button>
                        <button
                          className={`${stylesPreview.buttonPreview} ${stylesPreview.buttonPreview__remove}`}
                          onClick={() => subQuestionManipulate('delete', item.id, item.parent)}
                          >
                        </button>
                        </div>
                      <SurveyQuestion
                          subQuestionNumber={idx+1}
                          id={`question-${data.id}${data.id}`}
                          data={item}
                          key={`questionSubItem-${data.id}`}
                          collectAnswers={collectAnswers}
                          preview={true}
                      /> 
                  </div>

                      )
                      )
                   : null}

                   {/* { option.weight instanceof Object && <div className="templates__listItemDashedOption">1111111111</div>} */}
                   { option.subAnswer && <div className="templates__listItemDashedOption" ref={dropSubQuestion}></div>}

                   {/* <div className="templates__listItemDashed" ref={drop}></div> */}

              </div>
            ))}

            <Button
            title='Add choice'
            type={'transparency'}
            className={'darkest -p-0'}
            width={'100px'}
            onPress={() => updateOptions('add')} />
          </div>
        </>
        );
      case 'date':
        return (
          <>
            <div className={styles.body}>
              <div className={styles.body__box}>
                <div className={styles.body__title}>Title</div>
                <input
                  value={form.title}
                  onChange={(e) => handleChangeFormTitle(e.target.value)}
                  className={styles.body__inputTitle}
                  type="text"
                />
              </div>
              {form.options?.map((option: QuestionOptions, index: number) => (
                <div key={`opt-${option.id}`} className={styles.body__box}>
                  <div className={styles.body__options}>
                    <div className={styles.body__title}>{index + 1} choice</div>
                    <div className={styles.body__title}>weight</div>
                  </div>
                  <div className={styles.body__options}>
                    <input
                      value={option.title}
                      onChange={(e) =>
                        handleChange('title', e.target.value, option.id)
                      }
                      className={styles.body__inputTitle}
                      type="text"
                    />
                    <input
                      value={option.weight || ''}
                      onChange={(e) =>
                        handleChange('weight', e.target.value, option.id)
                      }
                      className={styles.body__inputWeight}
                      type="number"
                      min={0}
                      max={10}
                    />
                    {form.options?.length === 1 ? null : (
                      <button
                        onClick={() => updateOptions('delete', option.id)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                onClick={() => updateOptions('add')}
                className={styles.body_addOption}
              >
                + <span>Add Choice</span>
              </button>
            </div>
          </>
        );
      case 'time':
        return (
          <>
            <div className={styles.body}>
              <div className={styles.body__box}>
                <div className={styles.body__title}>Title</div>
                <input
                  value={form.title}
                  onChange={(e) => handleChangeFormTitle(e.target.value)}
                  className={styles.body__inputTitle}
                  type="text"
                />
              </div>
              {form.options?.map((option: QuestionOptions, index: number) => (
                <div key={`opt-${option.id}`} className={styles.body__box}>
                  <div className={styles.body__options}>
                    <div className={styles.body__title}>{index + 1} choice</div>
                    <div className={styles.body__title}>weight</div>
                  </div>
                  <div className={styles.body__options}>
                    <input
                      value={option.title}
                      onChange={(e) =>
                        handleChange('title', e.target.value, option.id)
                      }
                      className={styles.body__inputTitle}
                      type="text"
                    />
                    <input
                      value={option.weight || ''}
                      onChange={(e) =>
                        handleChange('weight', e.target.value, option.id)
                      }
                      className={styles.body__inputWeight}
                      type="number"
                      min={0}
                      max={10}
                    />
                    {form.options?.length === 1 ? null : (
                      <button
                        onClick={() => updateOptions('delete', option.id)}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>
              ))}
              <button
                onClick={() => updateOptions('add')}
                className={styles.body_addOption}
              >
                + <span>Add Choice</span>
              </button>
            </div>
          </>
        );
      default:
        return <div>default-default</div>;
    }
  };

  return (
    <>
      <div className={styles.popupBox} style={style}>
        <div className={
          `${styles.headerBox} ${form.parent ? styles.subQuestion : ''}`
          }>
          
          <div>
            {form.parent ? (<>
              <div className={styles.sub__title}>{nameOfCategory}</div>
              {nameOfQuestion && nameOfOption ?
              <div className={styles.infoBox}>
                {/* {nameOfQuestion && nameOfOption ?
                  <> */}
                    <div className={styles.sub__nameOfQuestion}>{nameOfQuestion}</div>
                    <div className={styles.sub__nameOfOption}>Answer - {nameOfOption}</div>
                  {/* </>
                  :
                  <div className={styles.sub__nameOfQuestion}>Sub Question</div>
                } */}
              </div>
              : null}
            </>)
            : null}
            <h2 className={ `${styles.headerBoxTitle} ${form.parent ? styles.sub__nameOfSubQuestion : ''}`}>
              {form.parent ? form.title || form.nameOfType : form.nameOfType}</h2>
          </div>
          <div className={`${styles.backBtn} ${form.parent ? styles.sub : ''}`} onClick={closePopup}>
            <Image className={styles.icon} width={'18px'} height={'18px'} source={closeIcon} />
          </div>
        </div>
        {renderTab(form.type)}
        <div className={styles.buttonBox}>
          <Button
            title={'save'}
            className={'-uppercase'}
            width={'100px'}
            onPress={() => saveForm(form)}
          />
        </div>
      </div>
    </>
  );
};

export default QuestionForm;
