import React, { FC } from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';

import { Question, QuestionType } from '../../../../../models/templates/templates.model';

type IProps = {
  data: QuestionType;
  moveItem: (data: QuestionType, categoryId: number) => void;
  moveSubQuestion: (data: QuestionType, categoryId: number, question: Question) => void;
};

const QuestionTypeItem: FC<IProps> = ({ data, moveItem, moveSubQuestion }) => {
  const { title } = data.field;

  const [{ isDragging }, dragRef] = useDrag({
    type: 'questionType',
    item: data,
    end: (item, monitor) => {
      const dropResult: { categoryId?: number, optionId?: number, question?: Question } | null = monitor.getDropResult();
      if (item && dropResult && dropResult.categoryId) {
        moveItem(item, dropResult.categoryId);
      }
      if (item && dropResult && dropResult.optionId && dropResult.question) {
        moveSubQuestion(item, dropResult.optionId, dropResult.question);
      }
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={dragRef} className="templates__listItem">
      {title}
    </div>
  );
};

export default QuestionTypeItem;
