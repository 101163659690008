import * as React from 'react';

import './CheckBox.scss';

interface IProps {
    id?: string;
    size?: string;
    color?: string;
    error?: string;
    checked: boolean;
    disabled?: boolean;
    children?: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    noText?: boolean
}

const Checkbox = (props: IProps) => {
    const { checked, children, onChange, error, size, disabled = false, noText, id} = props;
    let inputRef: React.MutableRefObject<any>;
    inputRef = React.useRef(null);
    const onPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            inputRef.current.click();
        }
    };

    return (
        <div className={noText ? '' :'check-box-container'} id={id} tabIndex={0} onKeyPress={onPress}>
            <span>
                <input
                    className={`${size ? size : ""} ${error? error: ''}`}
                    ref={inputRef}
                    checked={checked}
                    disabled={disabled}
                    type="checkbox"
                    onChange={onChange}
                />
                {noText ? null : children}
            </span>
        </div>
    );
};

export default Checkbox;
