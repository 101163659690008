import React, { FC, useState, useEffect} from 'react';

import { useHistory, useLocation, useParams } from "react-router-dom";
import {Routes} from "../../../../routes/routes";
import Modal from "../../../common/Modal/Modal";
import { HeaderSearch } from '../../../common'
import Button from "../../../controls/Button/Button";
import TitleInner from "../../../common/TitleInner/TitleInner";
import Input from "../../../controls/Input/Input";
import {email, password, validate} from "../../../../helpers/validatior";
import {isEmptyObject} from "../../../../helpers";
import { ISelectOptions, KeyValueModel, UserById, UserFormErrorsModel } from '../../../../models';
import { addUser, getUserById, updateUser } from '../../../../store/manageUsers/manageUsersSlice';
import { useDispatch } from 'react-redux';
import { Select } from '../../../controls';  

interface IState {
  modalType: 'create-success' | 'update-success' | 'create-error' | null,
}

interface InitialUser {
  id?: number;
  email: string;
  password?: string;
}

const initialState: IState = {
  modalType: null,
};

const selectRole = [
  {label: 'Admin', value: 'admin'},
  {label: 'Clinician', value: 'clinician'},
];

const userUpdateValidatorScheme = {
  required: ['email'],
  custom: [email(['email'])],
};

const userCreateValidatorScheme = {
  required: ['email', 'password'],
  custom: [email(['email']), password(['password'])],
};

const userProfileValidatorScheme = {
  required: ['first_name', 'last_name'],
};

const contactValidatorScheme = {
  required: ['phone', 'mobile'],
};

const roleValidatorScheme = {
  required: ['role'],
};

const ManageUsersAdd = () => {
  const history = useHistory();
  const { pathname } = useLocation<KeyValueModel<string>>();
  const { id: idForUpdate } = useParams<KeyValueModel<string>>();
  const dispatch = useDispatch();

  const initialUser = {
    email: '',
    password: '',
  };

  const initialUserProfile = {
    first_name: '',
    last_name: '',
  };

  const initialContact = {
    phone: '',
    mobile: '',
  };

  const initialRole = {
    role: null
  };

  const initialOption = {
    permissions: {
        sign_in: 1,
        view_patient_list: 1,
        create_edit_patient: 1,
        view_questionnaire_list: 1,
        create_edit_questionnaire: 1,
        fill_questionnaire: 1,
        view_reports: 1,
        view_manage_user_list: 1,
        create_edit_manage_user: 1
      }
  };

  const [user, setUser] = useState<InitialUser>(initialUser);
  const [userProfile, setUserProfile] = useState(initialUserProfile);
  const [contact, setContact] = useState(initialContact);
  const [role, setRole] = useState<ISelectOptions | null | any>(initialRole);
  const [option, setOption] = useState(initialOption);
  const [errors, setErrors] = useState<UserFormErrorsModel>();
  const [userToUpdate, setUserToUpdate] = useState<UserById | null>();
  const [permissionsError, setPermissionsError] = useState<string>('');
  
  let [state, setState] = useState<IState>(initialState);

  const handleCloseModal = () => {
    setState({...state, modalType: null});
    history.push(Routes.MANAGE_USERS_LIST);
  };

  useEffect(() => {
    if (userToUpdate) {
      const roleId = selectRole.findIndex(role => role.value === userToUpdate.role[0].name);
      
      const userData = {
        id: userToUpdate.id,
        email: userToUpdate.email,
        password: '',
      };

      const userProfileData = {
        first_name: userToUpdate.first_name,
        last_name: userToUpdate.last_name,
      };

      const contactData = {
        phone: userToUpdate.contact.length ? userToUpdate.contact[0].phone : '',
        mobile: userToUpdate.contact.length ? userToUpdate.contact[0].mobile : '',
      };

      const roleData = {
        role: selectRole[roleId]
      };

      const optionData = {
        permissions:
        userToUpdate.permissions && Object.keys(userToUpdate.permissions).length !== 0
            ? userToUpdate.permissions
            : initialOption.permissions,
      };

      setUser(userData);
      setUserProfile(userProfileData);
      setContact(contactData);
      setRole(roleData);
      setOption(optionData);
    }
  }, [userToUpdate]);

  useEffect(() => {
    if (pathname.indexOf('/manage-users-edit') !== -1) {
      dispatch(
        getUserById({
          id: +idForUpdate,
          callback: (response) => {
            setUserToUpdate(response);
          },
        }),
      );
    }
  }, [pathname]);

  const checkSwitcher = (fieldName: string, checked: boolean) => {
    const optionCopy = JSON.parse(JSON.stringify(option));

    let changedOptionsList = {...optionCopy.permissions, [fieldName]: checked ? 1 : 0}

    setOption({ permissions: changedOptionsList });
  };

  const handleAddUser = (e: Event) => {
    e.preventDefault();
    const {errors: userErrors } = validate(!userToUpdate || user.password ? userCreateValidatorScheme : userUpdateValidatorScheme, user);
    const { errors: userProfileErrors } = validate(userProfileValidatorScheme, userProfile);
    const { errors: contactErrors } = validate(contactValidatorScheme, contact);
    const { errors: roleErrors } = validate(roleValidatorScheme, role);

    setErrors({
      user: userErrors,
      user_profile: userProfileErrors,
      contact: contactErrors,
      role: roleErrors,
    });

    if (
      isEmptyObject(userErrors) &&
      isEmptyObject(userProfileErrors) &&
      isEmptyObject(contactErrors) &&
      isEmptyObject(roleErrors)
    ) {
      let userData: InitialUser = {
        email: user.email
      };

      if (user.id) {
        userData = {
          ...userData,
          id: user.id
        };
      }

      if (user.password) {
        userData = {
          ...userData,
          password: user.password
        };
      }

      const userProfileData = {
        ...userProfile,
      };

      const contactData = {
        ...contact,
      };

      const roleData = {
        role: role.role.value,
      };

      const optionData = {
        ...JSON.parse(JSON.stringify(option)),
      };

      const sendData = {
        user: userData,
        user_profile: userProfileData,
        contact: contactData,
        role: roleData,
        option: optionData,
      };

      if (pathname.indexOf('/manage-users-edit') !== -1) {
        dispatch(
          updateUser({
            data: sendData,
            callback: () => {
              // history.push(Routes.MANAGE_USERS_LIST);
              setState({...state, modalType: 'update-success'});
            },
          }),
        );
      } else {
        dispatch(
          addUser({
            data: sendData,
            callback: (message) => {
              // history.push(Routes.MANAGE_USERS_LIST);
              if (message.message === 'success') {
                setState({...state, modalType: 'create-success'});
              } else {
                for (let key in message) {
                  if (key !== 'user.email') {
                    setPermissionsError(message[key][0]);
                    setState({...state, modalType: 'create-error'});
                  } else {
                    setErrors({
                      user: { email: 'Email has already been taken' },
                      user_profile: {},
                      contact: {},
                      role: {},
                    });
                  }
                }
              }
            },
          }),
        );
      }
    }
  };

  return (
    <div className={'b-mainContainer__wrapper'}>
      <HeaderSearch pageName="Manage Users" />
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={'b-mainContainer__contentWrapper'}>
          <TitleInner title={pathname.indexOf('/manage-users-edit') !== -1 ? 'Update User' : 'Add New User' } />
          <form className={'b-form'}>
            <div className={'b-form__subTitle'}>General Information</div>

            <div className={'b-form__row'}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={userProfile.first_name}
                  label={'First Name *'}
                  error={errors?.user_profile.first_name}
                  onChange={(value) => setUserProfile({ ...userProfile, first_name: value })}
                />
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={userProfile.last_name}
                  label={'Last Name *'}
                  error={errors?.user_profile.last_name}
                  onChange={(value) => setUserProfile({ ...userProfile, last_name: value })}
                />
              </div>
            </div>

            <div className={'b-form__row'}>
              <div className={'b-form__formGroup'}>
                <Select
                  value={role.role}
                  options={selectRole}
                  label={'Role *'}
                  className={'mb-0'}
                  error={errors?.role.role}
                  onChange={(value) => setRole({ ...role, role: value })}
                />
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={user.password || ''}
                  type={'password'}
                  label={pathname.indexOf('/manage-users-edit') !== -1 ? 'Change Password' : 'Password *'}
                  autoComplete={'new-password'}
                  error={errors?.user.password}
                  onChange={(value) => setUser({ ...user, password: value })}
                />
              </div>
            </div>

            <div className={'b-form__subTitle'}>Contact Information</div>
            <div className={'b-form__row'}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={contact.phone}
                  label={'Telephone Number * '}
                  error={errors?.contact.phone}
                  onChange={(value) => setContact({ ...contact, phone: value })}
                />
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={contact.mobile}
                  label={'Cell Phone Number *'}
                  error={errors?.contact.mobile}
                  onChange={(value) => setContact({ ...contact, mobile: value })}
                />
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                  value={user.email}
                  label={'Email *'}
                  disable={pathname.indexOf('/manage-users-edit') !== -1}
                  error={errors?.user.email}
                  onChange={(value) => setUser({ ...user, email: value })}
                />
              </div>
            </div>

            <div className={'b-form__row -three_column'}>
              <div className="b-form__col">
                <div className="b-form__block">
                  <div className={'b-form__subTitle'}>Account</div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.sign_in
                        }
                        onChange={(e) => checkSwitcher('sign_in', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Active user who may sign in</span>
                  </div>
                </div>
                <div className="b-form__block">
                  <div className={'b-form__subTitle'}>Patient Records</div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.view_patient_list
                        }
                        onChange={(e) =>
                          checkSwitcher('view_patient_list', e.target.checked)
                        }
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">View patient demographics</span>
                  </div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.create_edit_patient
                        }
                        onChange={(e) =>
                          checkSwitcher('create_edit_patient', e.target.checked)
                        }
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Create and edit patient demographics</span>
                  </div>
                </div>
              </div>

              <div className="b-form__col">
                <div className="b-form__block">
                  <div className={'b-form__subTitle'}>Documents</div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.view_questionnaire_list
                        }
                        onChange={(e) => checkSwitcher('view_questionnaire_list', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">View surveys</span>
                  </div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.create_edit_questionnaire
                        }
                        onChange={(e) => checkSwitcher('create_edit_questionnaire', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Create and edit surveys</span>
                  </div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.fill_questionnaire
                        }
                        onChange={(e) => checkSwitcher('fill_questionnaire', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Share survey links</span>
                  </div>
                </div>
              </div>

              <div className="b-form__col">
                <div className="b-form__block">
                  <div className={'b-form__subTitle'}>Reports</div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.view_reports
                        }
                        onChange={(e) => checkSwitcher('view_reports', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Generate reports</span>
                  </div>
                </div>
                <div className="b-form__block">
                  <div className={'b-form__subTitle'}>System</div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.view_manage_user_list
                        }
                        onChange={(e) => checkSwitcher('view_manage_user_list', e.target.checked)}
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">View user information</span>
                  </div>
                  <div className={'toggle'}>
                    <label className={'toggle-group'}>
                      <input
                        type={'checkbox'}
                        checked={
                          !!option.permissions.create_edit_manage_user
                        }
                        onChange={(e) =>
                          checkSwitcher('create_edit_manage_user', e.target.checked)
                        }
                      />
                      <span className={'toggle-group-on'}>Yes</span>
                      <span className={'toggle-group-off'}>No</span>
                      <span className={'toggle-group-handle'}></span>
                    </label>
                    <span className="toggle-label">Create and edit system users</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={'b-form__btnWrapper'}>
              <Button
                title={'save'}
                className={'-uppercase -ml-10'}
                onPress={(e) => handleAddUser(e)}
              />
            </div>
          </form>
        </div>
      </div>
      {state.modalType === 'create-success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
          {role.role.label}<i><b>&nbsp;“{userProfile.first_name}&nbsp;{userProfile.last_name}” </b></i>&nbsp;was created
          </div>
        </div>
      </Modal>}
      {state.modalType === 'update-success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
          {role.role.label}<i><b>&nbsp;“{userProfile.first_name}&nbsp;{userProfile.last_name}” </b></i>&nbsp;was updated
          </div>
        </div>
      </Modal>}
      {state.modalType === 'create-error' && <Modal
          title='Error'
          width={520}
          onClose={() => {
            setState({...state, modalType: null}),
            setPermissionsError('')
          }}>
          <div className={'b-modal'}>
            <div className={'b-modal__text'}>
              {permissionsError}
            </div>
          </div>
        </Modal>}
    </div>
  );
};

export default ManageUsersAdd;
