import React, { FC, useEffect, useRef, useState } from 'react';

import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { Question, QuestionOptions, QuestionParent } from '../../../../../models/templates/templates.model';
import QuestionItem from '../QuestionItem/QuestionItem';
import styles from '../../../TemplatesList/TemplatesListView.module.scss'

interface DragItem {
  index: number;
}

type IProps = {
  closePopup: () => void;
  categoryId: number;
  title: string;
  categoryItems: Question[];
  index: number;
  moveCategoryHandler: (dragIndex: number, hoverIndex: number) => void;
  deleteCategoryItem: (categoryId: number) => void;
  openPopup: (data: Question) => void;
  questionManipulate: (type: 'copy' | 'delete', questionId: number, categoryId: number) => void;
  moveItemHandler: (
    categoryData: Question[],
    dragIndex: number,
    hoverIndex: number,
    categoryId: number,
  ) => void;
  moveItemInAnotherCategory: (item: Question, toCategoryId: number, fromCategoryId: number) => void;
  collectFields: (id : number, categoryQuestions : Question[]) => void;
};

const CategoryItem: FC<IProps> = ({
  closePopup,
  categoryId,
  title,
  categoryItems,
  index,
  moveCategoryHandler,
  deleteCategoryItem,
  openPopup,
  questionManipulate,
  moveItemInAnotherCategory,
  moveItemHandler,
  collectFields
}) => {

  const [category, setCategory] = useState<Question[]>(categoryItems);

  const collectAnswers = (type: 'sub' | 'main', id: number, answers: number[], parent?: QuestionParent, text?: string) => {

    if (type === 'main') {
      const currentQuestionIndex = categoryItems.findIndex(question => question.id === id)
      if (answers && answers?.length > 0) {

        const changeAnswer = (categoryItems: Question[]) => {
          let newCategory: Question[] = JSON.parse(JSON.stringify(categoryItems));

          newCategory = newCategory.map((item, index) => index === currentQuestionIndex ? { ...item, answers: answers } : { ...item })
          // newCategory[currentQuestionIndex].answers = answers;
          newCategory[currentQuestionIndex].options.map((item) => item.subQuestions.length ? { ...item, subQuestions: [...item.subQuestions.map((el) => el.answers = [])] } : item);
          return newCategory;
        }

        setCategory(changeAnswer(categoryItems))
      } else {
        const changeAnswer = (category: Question[]) => {
          let newCategory: Question[] = JSON.parse(JSON.stringify(category));

          newCategory = newCategory.map((item, index) => index === currentQuestionIndex ? { ...item, textArea: text } : { ...item })
          // newCategory[currentQuestionIndex].options.map((item) => item.subQuestions.length ?  {...item, subQuestions: [...item.subQuestions.map((el) => el.answers= [])] } : item);
          return newCategory;
        }

        setCategory(changeAnswer(category))
      }

    } else {
      const currentQuestionIndex = categoryItems.findIndex(question => question.id === parent?.mainQuestion_id)

      const changeAnswer = (categoryItems: Question[]) => {
        let newCategory: Question[] = JSON.parse(JSON.stringify(categoryItems));

        let newOptions: QuestionOptions[];
        newOptions = newCategory[currentQuestionIndex].options.map((item) => item.id === parent?.option_id ?
          { ...item, subQuestions: item.subQuestions.map((el) => el.id === id ? { ...el, answers: answers } : el) }
          : item)

        newCategory = newCategory.map((elem, index) => currentQuestionIndex === index ? { ...elem, options: newOptions } : elem);
        return newCategory;
      }

      setCategory(changeAnswer(categoryItems))
    }
  }

  const addSwitcherStatus = () => {
    const newCategory: Question[] = JSON.parse(JSON.stringify(category));
    newCategory.map((item) =>
      item.type === 'switcher' && item.answers?.length == 0 ? (item.answers = [2]) : item.answers,
    );
    setCategory(newCategory);
  };

  useEffect(() => {
    addSwitcherStatus();
  }, []);

  useEffect(() => {
    collectFields(categoryId, category);
}, [category]);

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, dropRef] = useDrop({
    accept: 'categoryType',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      } else {
        closePopup();
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset: XYCoord | null = monitor.getClientOffset();
      if (clientOffset) {
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        moveCategoryHandler(dragIndex, hoverIndex);
        item.index = hoverIndex;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: () => {
      return { categoryId, index };
    },
    type: 'categoryType',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  drag(dropRef(ref));

  const [, drop] = useDrop({
    accept: 'questionType',
    drop: () => ({ categoryId: categoryId }),
  });

  const [, dropQ] = useDrop({
    accept: 'questionDrag',
    drop: () => ({ categoryId: categoryId }),
  });

  return (
    <>
      <div ref={ref} data-handler-id={handlerId} style={{ opacity: `${opacity}` }}>
        <div className="templates__listQuestions" ref={dropQ}>
          <div className="templates__categoryBoxTitle">
            <h2>{title !== 'No Category' ? title : ''}</h2>
            <button
              className={`${styles.buttonPreview} ${styles.buttonPreview__remove}`}
              onClick={() => deleteCategoryItem(categoryId)}>
            </button>
          </div>
          {categoryItems.length > 0
            ? categoryItems
              .map((item, idx) => (
                <QuestionItem
                  closePopup={closePopup}
                  categoryItems={categoryItems}
                  name={`question${item.id}`}
                  data={item}
                  key={`questionItem${item.id}`}
                  index={idx}
                  openPopup={openPopup}
                  questionManipulate={questionManipulate}
                  moveItemInAnotherCategory={moveItemInAnotherCategory}
                  moveItemHandler={moveItemHandler}
                  collectAnswers={collectAnswers}
                />
              ))
            : null}
          <div className="templates__listItemDashed" ref={drop}></div>
        </div>
      </div>
    </>
  );
};

export default CategoryItem;
