import { all } from 'redux-saga/effects';
import { saga as userSaga } from './user/userSaga';
import { saga as templatesSaga } from './templates/templatesSaga';
import { saga as getReportSaga } from './reports/reportSaga';
import { saga as surveySaga } from './survey/surveySaga';
import { saga as patientsSaga } from './patients/patientsSaga';
import { saga as manageUsersSaga } from './manageUsers/manageUsersSaga';
import { saga as hccCodesSaga } from './hccCodes/hccCodesSaga';


export default function* rootSaga() {
  yield all([
    userSaga(),
    templatesSaga(),
    getReportSaga(),  
    surveySaga(),
    patientsSaga(),
    manageUsersSaga(),
    hccCodesSaga(),
  ]);
}
