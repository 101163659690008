import React, { FC } from 'react';
import styles from './TitleInner.module.scss'

interface IProps {
  title?: string,
}

const TitleInner: FC<IProps> = ({title}) => {

  return (
      <div className={styles.title}>
        {title}
      </div>
  );
};

export default TitleInner;
