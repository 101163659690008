import React, { FC, useState, useEffect } from 'react';
import styles from './Questionnaires.module.scss'

import {Link, useHistory} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { HeaderSearch, TitleInner } from '../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from "../../controls/CheckBox/CheckBox";
import Button from "../../controls/Button/Button";
import Select from "../../controls/Select/Select";
import Modal from "../../common/Modal/Modal"
import {Routes} from "../../../routes/routes";
import {
  ISelectOptions,
  PatientsListGetModel,
  PatientsListOrderingModel,
  PatientsTableModel,
  PatientSearchFields,
  PatientsListSearchingModel,
  UsersSearchingModel,
  PatientProfilesSearchingModel,
  PaginationModel,
  PatientTableFields,
} from "../../../models";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store/reducers";
import Pagination from "../../common/Pagination/Pagination";
import {getPatientsList, deletePatients} from "../../../store/patients/patientsSlice";
import {getColorType, getTemplates} from '../../../store/templates/templatesSlice';
import {sendSurveyOnEmail, getSurveyForFill, clearSurveyChart} from '../../../store/survey/surveySlice';
import {Loader} from "../../controls";
import Input from "../../controls/Input/Input";
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmptyObject} from "../../../helpers";
import QuestionnairesSearch from './QuestionnairesSearch/QuestionnairesSearch';

interface IState {
  modalType: 'null' | 'fill' | 'send' | 'success' | 'search' | null,
}

const selectionTimeToPass = [
  {label: '1 Day', value: '1440'},
  {label: '2 Day', value: '2880'},
  {label: 'Week', value: '10080'},
];

const initialPatientListConfig = {
  ordering: {
    users: {
      id: "desc",
      email: "asc"
    },
    patient_profiles: {
      first_name: "asc",
      last_name: "asc",
      dob: "asc",
      gender: "asc",
      record_number: "asc"
    }
  },
}

const initialState: IState = {
  modalType: null,
};

const Questionnaires = () => {

  const history = useHistory();

  const navTo = (route: string) => {
    history.push(route);
  };

  const dispatch = useDispatch();

  const patients: PaginationModel<PatientTableFields[]> | null = useSelector((state: RootState) => state.patients.patientsList);
  const types = useSelector((state: RootState) => state.templates.colorType);
  const templates = useSelector((state: RootState) => state.templates.templates);

  const [typesForSelect, setTypesForSelect] = useState<ISelectOptions[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailCopied, setEmailCopied] = useState('');
  const [patientListConfig, setPatientListConfig] = useState<PatientsListGetModel>(initialPatientListConfig);
  const [patientsTable, setPatientsTable] = useState<PatientsTableModel[]>();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [selectedTypeTemplates, setSelectedTypeTemplates] = useState<ISelectOptions[]>();
  const [selectedType, setSelectedType] = useState<ISelectOptions>();
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectOptions>();
  const [selectedTimeToPass, setSelectedTimeToPass] = useState<ISelectOptions>();
  const [patientForFillId, setPatientForFillId] = useState<number>();
  const [searching, setSearching] = useState<PatientsListSearchingModel | null>(null);
  const [ordering, setOrdering] = useState<PatientsListOrderingModel | null>(null);
  // const [showPatientListBlock, setShowPatientListBlock] = useState(false);

  let [state, setState] = useState<IState>(initialState);

  const handleModalOpen = (type: 'null' | 'fill' | 'send' | 'success' | 'search' | null ) => () => {
    setState({...state, modalType: type})
  };

  const handleCloseModal = () => {
    setState({...state, modalType: null})
  };

  const handleOpenSendQuestionnaire = () => {
    setState({...state, modalType: 'send'})
    dispatch(getColorType());
  }
  const handleOpenFillQuestionnaire = (id : number) => {
    setState({...state, modalType: 'fill'})
    dispatch(getColorType());
    setPatientForFillId(id);
  }

  // const handleOpenFillQuestionnaire = (id : number) => {
  //   setState({...state, modalType: 'fill'})
  //   dispatch(getColorType());
  //   setPatientForFillId(id);
  // }

  const handleSearchPatient = (searchFields : PatientSearchFields) => {
    let searching : PatientsListSearchingModel = {};
    let patient_profiles : PatientProfilesSearchingModel;

    if (patients) {
      setLoading(true);
    }

    patient_profiles = {
      first_name: searchFields.first_name,
      last_name: searchFields.last_name,
      dob:  moment(searchFields.dob).isValid() ?  moment(searchFields.dob).format('YYYY-MM-DD') : '',
      gender: `${searchFields.gender ? searchFields.gender?.value : ''}`,
      record_number: searchFields.record_number,
      ss_number: searchFields.ss_number,
      member_id: searchFields.member_id,
      pin: searchFields.pin,
     }

    patient_profiles = Object.entries(patient_profiles).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});

    if (!isEmptyObject(patient_profiles)) {
      searching.patient_profiles = patient_profiles;
    }

    setCheckedIds([]);
    // setShowPatientListBlock(true);
    setSearching(searching);
    dispatch(getPatientsList({data: {searching : searching} , page: 1, callback: () => {setLoading(false)}}))
  }

  const handleSendSurvey = (type : 'send' | 'fill') => {
    if (selectedTemplate) {
      if (type === 'send' && selectedTimeToPass) {
        dispatch(sendSurveyOnEmail(
          { data: {
             ids: checkedIds,
             time_to_leave: +selectedTimeToPass.value,
             template_id: +selectedTemplate?.value
           },
           callback: () => setState({...state, modalType: 'success'})
         }
       ))
        setCheckedIds([]);
      }

      if (type === 'fill' && patientForFillId) {
        dispatch(getSurveyForFill({
          data: {
            template_id: +selectedTemplate?.value,
            user_id: patientForFillId,
          },
          callback: (token : string) => {
            dispatch(clearSurveyChart());
            navTo(`/fill-questionnaire/${token}`)
          }
        }))
      }

      handleCloseModal();
    }
  }

  const sortColumn = (sortField : string) => {
    const config = JSON.parse(JSON.stringify(patientListConfig));
    let ordering : PatientsListOrderingModel;

    if (sortField === 'id' || sortField === 'email') {
      ordering = {
        users: {
          [sortField]: config.ordering.users[sortField] === 'asc' ? 'desc' : 'asc'
        }
      }
      config.ordering.users[sortField] = config.ordering.users[sortField] === 'asc' ? 'desc' : 'asc'
    } else {
      ordering = {
        patient_profiles: {
          [sortField]: config.ordering.patient_profiles[sortField] === 'asc' ? 'desc' : 'asc'
        }
      }
      config.ordering.patient_profiles[sortField] = config.ordering.patient_profiles[sortField] === 'asc' ? 'desc' : 'asc';
    }
    setPatientListConfig(config);
    if (patients) {
      setLoading(true);
    }

    const sendData : PatientsListGetModel = {};
    sendData.ordering = ordering;
    setOrdering(ordering);
    if ( searching !== null) {
      sendData.searching = searching;
    }

    dispatch(getPatientsList({data: sendData , page: currentPage, callback: () => {setLoading(false)}}))
  }

  const formatPatientsTable = (patients : PaginationModel<PatientTableFields[]>) => {
    const formattedPatients = patients.data.map((patient) => {
      let token: string = '';
      let titleArray: string[] = [];
      let title: string = '';
      let formattedSurveyHistory: { label: string; value: string }[] = [];
      patient.survey_history.map((item) => {
        titleArray = item.survey_history.split(' , ');
        token = titleArray.shift() || 'Noname';
        title = titleArray.join('');
        formattedSurveyHistory.push({ label: title, value: token });
      });

      return {
        id: patient.id,
        patient: `${patient.first_name} ${patient.last_name}`,
        email: patient.email,
        dob: patient.dob,
        gender: patient.gender,
        record_number: patient.record_number,
        survey_history: formattedSurveyHistory,
      };
    });
    setPatientsTable(formattedPatients);
  }

  const handleTypeChange = (value : ISelectOptions) => {
    setSelectedType(value);
    setSelectedTemplate({});

    dispatch(getTemplates({ data: {
        filtering: {
          type_colors: {
            id: [
              +value.value
            ]
          }
        },
        as_array: 1
      }
    }));

  }

  useEffect(() => {
    dispatch(getColorType());
  }, [])

  useEffect(() => {
    if (templates) {
      const formattedTemplates = Object.entries(templates).map( template => {
            return {
              label: template[1],
              value: template[0],
            }
        }
      )
      setSelectedTypeTemplates(formattedTemplates)
    }
  }, [templates])

  const checkItem = (patient: PatientsTableModel) => {
    setCheckedIds((prev) => {
      if (prev.includes(patient.id)) {
        prev = prev.filter((item) => item !== patient.id);
        return prev;
      } else {
        return [...prev, patient.id];
      }
    });
  };

  const generateNamesOfUser = () => {
    if (checkedIds.length > 1) {
      let arrayOfNames: (string | undefined)[] = [];
      checkedIds.map((user) =>
        arrayOfNames.push(
          patientsTable?.filter((checkedId) => user === checkedId.id)[0].patient
        )
      );
      return arrayOfNames.join(', ');
    } else {
      return patientsTable?.filter((item) => item.id === checkedIds[0])[0]
        .patient;
    }
  }

  const allSelected = () => {
    let checkedCounter = 0;
    if (patientsTable) {
      patientsTable.map((item) => (checkedIds.includes(item.id) ? checkedCounter++ : item));
      if (checkedIds.length > 0 && patientsTable.length === checkedCounter) {
        return true;
      }
    }
    return false;
  };

  const checkAll = () => {
    if (patientsTable) {
      let checkedCounter = 0;
      patientsTable.map((item) => (checkedIds.includes(item.id) ? checkedCounter++ : item));
      if (checkedIds.length > 0 && patientsTable?.length === checkedCounter) {
        let array: number[] = [];
        patientsTable.map((item) => array.push(item.id));
        const filteredCheckedIds = checkedIds.filter((item) => !array.includes(item));

        setCheckedIds(filteredCheckedIds);
      } else {
        let array: number[] = [...checkedIds];
        patientsTable.map((item) => (array.includes(item.id) ? item : array.push(item.id)));

        setCheckedIds(array);
      }
    }
  };

  useEffect(() => {
    // if (patients) {
    //   setLoading(true);
    // }

    const sendData = JSON.parse(JSON.stringify(patientListConfig));
    if ( ordering !== null) {
      sendData.ordering = ordering;
    }
    if (searching !== null) {
      sendData.searching = searching;
    }
      dispatch(getPatientsList({
          data: sendData, page: currentPage, callback: () => {
            setLoading(false);
          }
        }));
    // } 
    // else {
    //   setLoading(false);
    // }

  }, [currentPage]);

  useEffect(() => {
    if (patients?.data[0]?.id) {
      formatPatientsTable(patients)
    } else {
      if (searching && patients?.data.length === 0) {
        setState({...state, modalType: 'null'})
    }
    }
  }, [patients])

  useEffect(() => {
    if(types && types[0]) {
      const selectTypes = types.map(type => {
        return {
          label: type.title,
          value: type.id,
        }
      })
      setTypesForSelect(selectTypes);
    }
  },[types])

  const headerEdit = (
    <span className='p-column-title -flex'>
      {/* <span className='p-column-title-inner'>Edit</span> */}
      <Checkbox
        checked={allSelected()}
        onChange={checkAll}/>
    </span>
  );

  const templateHistorySelection = (rowData: any) => {
    return (
      <>
        {rowData.survey_history.length ? (
          <Select
            value={rowData.survey_history[0]}
            options={rowData.survey_history}
            label={''}
            menuPosition={'fixed'}
            className={'mb-0'}
            onChange={(value) => navTo(`/fill-questionnaire/${value.value}`)}
          />
        ) : (
          <span style={{textAlign: 'center'}}>No Surveys</span>
        )}
      </>
    );
  };
  const templateEdit = (patient : PatientsTableModel) => {
    return (
      <div className={styles.patientControl}>
        <a 
            className={styles.patientDocLink} 
            href="#" 
            aria-label=""
            onClick={() => handleOpenFillQuestionnaire(patient.id)}
            data-tip data-for='patientDoc'>
          <ReactTooltip id='patientDoc' className='b-tooltip'>
            Fill Questionnaire
          </ReactTooltip>  
        </a>
        {/* <Link
            to={`/patient-edit/${patient.id}`}
            className={styles.patientEditLink} 
            href="#" 
            aria-label="Edit"
            data-tip data-for='patientEdit'>
          <ReactTooltip id='patientEdit' className='b-tooltip'>
            Edit
          </ReactTooltip>
        </Link> */}
        <Checkbox 
          checked={checkedIds.includes(patient.id)}
          onChange={() => checkItem(patient)}/>
      </div>
    )
  };

  const footer = (
    <div className="patientControlList" style={{justifyContent: 'flex-end'}}>
      <Button
        title='send Survey'
        dataFor={'sendToolTip'}
        className={'darkest -uppercase'}
        onPress={!checkedIds.length ? (() => null) : handleOpenSendQuestionnaire}/>
        {!checkedIds.length ?
          <ReactTooltip id='sendToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
            Please select at least one patient to send survey
          </ReactTooltip>
        : null}
    </div>
  );

  const columnHeader = (sortField : string , columnName : string) => {
    return (
        <>
          <div className={styles.customTableHeader} onClick={() => sortColumn(sortField)}>
            <span>{columnName}</span>
            <span className={'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
          </div>
        </>
    )
  }

  const typeInfo = (
    <div className="p-datatable-filters__popupContainerInfo">
      <button type="button" className="p-datatable-filters__popupOpenerInfo"></button>
      <div className="p-datatable-filters__popupInfo">
        <div className="p-datatable-filters__popupFrame">
          <div className="p-datatable-filters__popupHeading">TYPE INFO</div>
          <div className="p-datatable-filters__popupContent">
            <ul className="p-datatable-filters__popupList">
              <li>
                <b>Hybrid survey</b> - Has a score and a dial. This is for clinicians only.
              </li>
              <li>
                <b>Task survey</b> that creates a task list or action plan based on how the questions are
                answered, Example Social determinants of Health. Only for clinicians to fill out.
              </li>
              <li>
                <b>Standard survey</b> - Just informational to get responses from patient or survey
                taker, sent to patient to fill out. Example patient pain. Patients and clinicians
                may fill out.
              </li>
              <li>
                <b>Scored survey</b> (with score) - Patients or clinicians
                may fill out
              </li>
              <li>
                <b>Weighted survey</b> (with dial) example, the readmission surveys. Only clinicians
                fill out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={'b-mainContainer__wrapper'}>
      <HeaderSearch
        pageName='Surveys'

        isSearhVisible={false}
      />
      {/* {loading && <Loader fitParent={true} />} */}
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={`b-mainContainer__contentWrapper ${styles.tableWrapper}`}>
          <TitleInner title={'Run New Survey'} />
          <QuestionnairesSearch
            searchPatient={handleSearchPatient}
          />

          {patients?.data.length
          //  && showPatientListBlock
            ?
            <>
              <div className={'b-form__subTitle'}>
                Patients List
              </div>

              <DataTable
                value={patientsTable}
                className='-paginator'
                footer={footer}
              >
                <Column field='id' header={columnHeader('id', 'ID')} />
                <Column field='patient' header={columnHeader('first_name', 'Patient')} />
                <Column field='dob' header={columnHeader('dob', 'DoB')} />
                <Column field='gender' header={columnHeader('gender', 'Gender')} />
                <Column field='record_number' header={columnHeader('record_number', 'Record Number')} />
                <Column field='patient_survey' header='Patient Survey' body={templateHistorySelection} />
                <Column field='edit' header={headerEdit} body={templateEdit} />
              </DataTable>
              {patients?.total > 10 ?
              <Pagination
                displayTitle={'patients'}
                data={patients}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              /> 
              : null}
            </>
            : null}
        </div>
      </div>
  
      {state.modalType === 'send' && <Modal
          title='Send Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect?.filter(item => item.label === 'Standard survey' || item.label === 'Scored survey')}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <Select 
              label={'Time to pass *'}
              value={selectedTimeToPass}
              options={selectionTimeToPass}
              width='100%'
              onChange={value => setSelectedTimeToPass(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Send'} disabled={!selectedTemplate?.value || !selectedTimeToPass} onPress={() => handleSendSurvey('send')} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Survey<i><b>&nbsp;“{selectedTemplate?.label}” </b></i>&nbsp;has been sent
          </div>
        </div>
      </Modal>}
      {state.modalType === 'fill' && <Modal
          title='Fill Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Fill'} disabled={!selectedTemplate?.value} onPress={() => handleSendSurvey('fill')} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'null' && <Modal
        title='Patient Not Found'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Unable to find a matching patient. <br />
            Would you like to add a new patient?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'add patient'} onPress={() => navTo(Routes.PATIENTS_ADD)} type={'transparency'} className={'-uppercase'} width='160px' />
            <Button title={'search again'} onPress={handleCloseModal} className={'-uppercase -ml-10'} width='160px' />
          </div>
        </div>
      </Modal>}
    </div>
  );
};

export default Questionnaires;
