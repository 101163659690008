import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    SurveyModel,
    SurveyTokenModel,
    SurveyTemplateModel,
    SurveySaveModel,
    SurveyChartModel,
    SurveyRelatedModel,
    SurveyUserTemplateModel,
    SurveyHistoryModel,
    SurveyRunModel,
    GetSurveyByUserModel,
    FillSurveyPayload,
    QuestionnairesPayload,
    StatusQuestionnaire,
    ScoreLevelQuestionnaire,
    PaginationModel,
    Questionnaire,
    ChartPayload,
    ChartData,
    SurveyRunPayload,
    SurveySaveModelPayload,
    ShareSurveyPayload,
} from '../../models';

export interface InitialState {
    survey: SurveyModel | null;
    surveyChart: SurveyChartModel | null;
    related: SurveyRelatedModel[] | null,
    history: SurveyHistoryModel[] | null,
    questionnaires: PaginationModel<Questionnaire[]> | null,
    statusQuestionnaire: StatusQuestionnaire | null,
    scoreLevelQuestionnaire: ScoreLevelQuestionnaire | null,
    chartData: ChartData | null
}

const initialState: InitialState = {
    survey: null,
    surveyChart: null,
    related: null,
    history: null,
    questionnaires: null,
    statusQuestionnaire: null,
    scoreLevelQuestionnaire: null,
    chartData: null
};

const survey = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        getSurvey: (state, {payload}: PayloadAction<SurveyTokenModel>) => { },
        getSurveySuccess: (state, { payload }: PayloadAction<SurveyModel>) => {
            state.survey = payload;
        },
        getSurveyForFill: (state, {payload}: PayloadAction<FillSurveyPayload>) => { },
        getSurveyForFillSuccess: (state, { payload }: PayloadAction<SurveyModel>) => {
            state.survey = payload;
        },
        sendSurvey: (state, {payload}: PayloadAction<SurveySaveModelPayload>) => { },
        sendSurveySuccess: (state, { payload }: PayloadAction<SurveyChartModel>) => {
            state.surveyChart = payload;
        },
        clearSurvey: (state) => {
            state.survey = null;
        },
        clearSurveyChart: (state) => {
            state.surveyChart = null;
        },
        updateSurvey: (state, {payload}: PayloadAction<SurveySaveModelPayload>) => { },
        updateSurveySuccess: (state, { payload }: PayloadAction<SurveyChartModel>) => {
            state.surveyChart = payload;
        },
        getSurveyRelated: (state, {payload}: PayloadAction<SurveyUserTemplateModel>) => { },
        getSurveyRelatedSuccess: (state, { payload }: PayloadAction<SurveyRelatedModel[]>) => {
            state.related = payload;
        },
        getSurveyHistory: (state, {payload}: PayloadAction<SurveyTokenModel>) => { },
        getSurveyHistorySuccess: (state, { payload }: PayloadAction<SurveyHistoryModel[]>) => {
            state.history = payload;
        },
        getQuestionnaires: (state, {payload}: PayloadAction<QuestionnairesPayload>) => { },
        getQuestionnairesSuccess: (state, { payload }: PayloadAction<PaginationModel<Questionnaire[]>>) => {
            state.questionnaires = payload;
        },
        getStatusQuestionnaire: (state) => { },
        getStatusQuestionnaireSuccess: (state, { payload }: PayloadAction<StatusQuestionnaire>) => {
          state.statusQuestionnaire = payload;
        },
        getScoreLevelQuestionnaire: (state) => { },
        getScoreLevelQuestionnaireSuccess: (state, { payload }: PayloadAction<ScoreLevelQuestionnaire>) => {
          state.scoreLevelQuestionnaire = payload;
        },
        sendSurveyOnEmail: (state, {payload}: PayloadAction<SurveyRunPayload>) => { },
        // sendSurveyOnEmailSuccess: (state) => { },
        getChartData: (state, {payload}: PayloadAction<ChartPayload>) => { },
        getChartDataSuccess: (state, { payload }: PayloadAction<ChartData>) => {
            state.chartData = payload;
        },
        shareSurvey: (state, {payload}: PayloadAction<ShareSurveyPayload>) => { },
    },
});

const {
    getSurvey,
    getSurveySuccess,
    sendSurvey,
    sendSurveySuccess,
    updateSurvey,
    updateSurveySuccess,
    getSurveyRelated,
    getSurveyRelatedSuccess,
    getSurveyHistory,
    getSurveyHistorySuccess,
    getQuestionnaires,
    getQuestionnairesSuccess,
    getStatusQuestionnaire,
    getStatusQuestionnaireSuccess,
    getScoreLevelQuestionnaire,
    getScoreLevelQuestionnaireSuccess,
    sendSurveyOnEmail,
    // sendSurveyOnEmailSuccess,
    getSurveyForFill,
    getSurveyForFillSuccess,
    clearSurveyChart,
    clearSurvey,
    getChartData,
    getChartDataSuccess,
    shareSurvey,
} = survey.actions;

export {
    getSurvey,
    getSurveySuccess,
    sendSurvey,
    sendSurveySuccess,
    updateSurvey,
    updateSurveySuccess,
    getSurveyRelated,
    getSurveyRelatedSuccess,
    getSurveyHistory,
    getSurveyHistorySuccess,
    getQuestionnaires,
    getQuestionnairesSuccess,
    getStatusQuestionnaire,
    getStatusQuestionnaireSuccess,
    getScoreLevelQuestionnaire,
    getScoreLevelQuestionnaireSuccess,
    sendSurveyOnEmail,
    // sendSurveyOnEmailSuccess,
    getSurveyForFill,
    getSurveyForFillSuccess,
    clearSurveyChart,
    clearSurvey,
    getChartData,
    getChartDataSuccess,
    shareSurvey,
};

export default survey.reducer;
