import { configureStore} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import {rootReducer} from './reducers';
import rootSaga from './saga';
import {globals} from './globals';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

globals.store = store;

sagaMiddleware.run(rootSaga);

export default store;
