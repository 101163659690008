import React, {FC, useState} from 'react';
import {Button, Input, Loader, Select} from '../../../controls';
import {validate, email} from '../../../../helpers/validatior';
import Modal from "../../../common/Modal/Modal"

import {useDispatch} from "react-redux";
import {KeyValueModel} from "../../../../models";
import {isEmptyObject} from "../../../../helpers";
import {getRestorePasswordToken} from "../../../../store/user/userSlice";
import UserIc from '../../../../assets/images/icons/user.svg'
import AvixenaLogo from '../../../../assets/images/icons/avixenalogo.svg'
import Eye from "../../../../assets/images/icons/eye.svg";

interface IState {
    modalType: 'success' | 'error' | null,
}

interface IProps {

}

const initialState: IState = {
    modalType: null,
};

const validatorScheme = {
        required: ['email'],
        custom: [email(['email'])],
}


const ForgotPasswordContainer: FC<IProps> = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState<string>('');

    const [errors, setErrors] = useState<KeyValueModel<string | boolean>>({});

    const [loading, setLoading] = useState<boolean>(false);
    let [state, setState] = useState<IState>(initialState);

    const handleModalOpen = (type: 'success' | 'error' | null) => () => {
        setState({ ...state, modalType: type });
    };

    const handleCloseModal = () => {
        setState({ ...state, modalType: null });
    };

    const handleChange = (value: string) => {
        setEmail(value);
        setErrors({});
    };

    const handleSubmit = () => {
        const {errors} = validate(validatorScheme, {email:email});
        setErrors(errors);
        if (isEmptyObject(errors)) {
            setLoading(true);
            dispatch(getRestorePasswordToken({
                ...{email:email},
                callback: (message) => {
                    if (message.message !== 'success') {
                        setErrors({...errors, email: 'The selected email is invalid.'});
                    } else {
                        setState({...state, modalType: 'success'});
                    }
                    setLoading(false);
                }
            }));
        }
    };

    return (
        <div className='auth__page'>
            {loading && <Loader/>}
            <img src={AvixenaLogo} style={{marginBottom:'30px'}}/>
            <div className='auth__form'>
                <div className='auth__textWrapper -forgotpass'>
                    <div className='auth__title'>Reset your password</div>
                    <div className='auth__text -forgotpass'>
                        Welcome to Avixena health solution. Once you log in you will be able to create and track your patient{`'`}s surveys and their progress.
                    </div>
                    <div className='auth__titleLine'/>
                </div>
                <div className='auth__input -forgotpass'>
                    <Input
                        height={50}
                        label={'Email:'}
                        classLabelName={'-authStyle'}
                        onChange={handleChange}
                        value={email}
                        error={errors.email}
                        leftIcon={<img src={UserIc} alt=""/>}
                    />
                </div>
                <Button
                    height={50}
                    onPress={handleSubmit}
                    title={'RESET PASSWORD'}
                />
            </div>
            {state.modalType === 'success' && <Modal
                title='Success'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    The link for recovering your password was sent to your email.
                </div>
                </div>
            </Modal>}
        </div>
    )
};

export default ForgotPasswordContainer;
