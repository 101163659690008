import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { HeaderSearch } from '../../common';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CreateTemplateContainer from './CreateTemplateContainer';

const CreateTemplatePage = () => {
  const dispatch = useDispatch();

  return (
    <div className="b-mainContainer__wrapper">
      <HeaderSearch
          pageName='Survey'
          isSearhVisible={false}
      />
      <DndProvider backend={HTML5Backend}>
        <CreateTemplateContainer />
      </DndProvider>
    </div>
  );
};

export default CreateTemplatePage;
