import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KeyValueModel } from '../../models/key-value.model';

export interface ReportsData {
  date_from: Date | any,
  date_to: Date | any,
}

export interface Reports {
  data: ReportsData,
  callback?: (error: KeyValueModel<string>) => void,
}

export interface InitialState {
}

const initialState: InitialState = {
};

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getReports: (state, { payload }: PayloadAction<Reports>) => {},
    
  },
});

const { getReports } =
    report.actions;

export { getReports };

export default report.reducer;
