import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
    getSurvey,
    getSurveySuccess,
    sendSurvey,
    sendSurveySuccess,
    updateSurvey,
    updateSurveySuccess,
    getSurveyRelated,
    getSurveyRelatedSuccess,
    getSurveyHistory,
    getSurveyHistorySuccess,
    getQuestionnaires,
    getQuestionnairesSuccess,
    sendSurveyOnEmail,
    // sendSurveyOnEmailSuccess,
    getSurveyForFill,
    getSurveyForFillSuccess,
    getStatusQuestionnaire,
    getStatusQuestionnaireSuccess,
    getScoreLevelQuestionnaire,
    getScoreLevelQuestionnaireSuccess,
    getChartDataSuccess,
    getChartData,
    shareSurvey,
} from './surveySlice';
import Api from '../../services/api';
import {ChartPayload, FillSurveyPayload, QuestionnairesPayload, ShareSurveyPayload, SurveyRunPayload, SurveySaveModelPayload, SurveyTokenModel, SurveyUserTemplateModel} from "../../models";
 
function* getSurveySaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: data}: {payload: SurveyTokenModel} = yield take(getSurvey.toString());

        try {
            const response = yield call(Api.post, `questionnaire/check`, data);
            if (response) {
                yield put(getSurveySuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getSurveyForFillSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: { data, callback } }: {payload: FillSurveyPayload} = yield take(getSurveyForFill.toString());

        try {
            const response = yield call(Api.post, `questionnaire/fill`, data);
            if (response) {
                yield put(getSurveyForFillSuccess(response.data.resource));
                if (callback){
                    callback(response.data.resource.questionnaire.token);
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* sendSurveySaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback} }: {payload: SurveySaveModelPayload} = yield take(sendSurvey.toString());

        try {
            const response = yield call(Api.post, `questionnaire/save`, data);
            if (response) {
                yield put(sendSurveySuccess(response.data.resource));
                callback({message:'success'});
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
            if (e.response.status === 500) {
                callback(e.response.data);
              }
        }
    }
}

function* updateSurveySaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback} }: {payload: SurveySaveModelPayload} = yield take(updateSurvey.toString());

        try {
            const response = yield call(Api.put, `questionnaire/update`, undefined , data);
            if (response) {
                yield put(updateSurveySuccess(response.data.resource));
                callback({message:'success'});
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getSurveyRelatedSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const {payload: data}: {payload: SurveyUserTemplateModel} = yield take(getSurveyRelated.toString());

        try {
            const response = yield call(Api.post, `questionnaire/related`, data);
            if (response) {
                yield put(getSurveyRelatedSuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getSurveyHistorySaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: data}: {payload: SurveyTokenModel} = yield take(getSurveyHistory.toString());

        try {
            const response = yield call(Api.post, `questionnaire/history`, data);
            if (response) {
                yield put(getSurveyHistorySuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getQuestionnairesSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, page, callback} }: {payload: QuestionnairesPayload} = yield take(getQuestionnaires.toString());

        try {
            const response = yield call(Api.post, `questionnaire/list?page=${page}`, data);
            if (response) {
                yield put(getQuestionnairesSuccess(response.data.resource));
                if (callback){
                    callback();
                  }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getStatusQuestionnaireSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        yield take(getStatusQuestionnaire.toString());

        try {
            const response = yield call(Api.post, `questionnaire/status`);
            if (response) {
                yield put(getStatusQuestionnaireSuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getScoreLevelQuestionnaireSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        yield take(getScoreLevelQuestionnaire.toString());

        try {
            const response = yield call(Api.post, `questionnaire/score-level`);
            if (response) {
                yield put(getScoreLevelQuestionnaireSuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* sendSurveyOnEmailSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback}}: {payload: SurveyRunPayload} = yield take(sendSurveyOnEmail.toString());

        try {
            const response = yield call(Api.post, `questionnaire/run`, data);
            if (response) {
                // yield put(sendSurveyOnEmailSuccess());
                callback();
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getChartDataSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback} }: {payload: ChartPayload} = yield take(getChartData.toString());

        try {
            const response = yield call(Api.post, `questionnaire/chart`, data);
            if (response) {
                yield put(getChartDataSuccess(response.data.resource));
                    callback();
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* shareSurveySaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback} }: {payload: ShareSurveyPayload} = yield take(shareSurvey.toString());

        try {
            const response = yield call(Api.post, `questionnaire/share`, data);
            if (response) {
                callback();
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

export function* saga() {
    yield all([
        getSurveySaga(),
        sendSurveySaga(),
        updateSurveySaga(),
        getSurveyRelatedSaga(),
        getSurveyHistorySaga(),
        getQuestionnairesSaga(),
        getStatusQuestionnaireSaga(),
        getScoreLevelQuestionnaireSaga(),
        sendSurveyOnEmailSaga(),
        getSurveyForFillSaga(),
        getChartDataSaga(),
        shareSurveySaga()
    ]);
}
