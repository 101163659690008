import React, {FC, useState} from 'react';
import styles from './HeaderSearch.module.scss'
import searchIcon from '../../../assets/images/icons/search.svg'

import Input from "../../controls/Input/Input";
import Modal from "../Modal/Modal";
import DatePicker from "react-datepicker";
import Select from "../../controls/Select/Select";
import Button from "../../controls/Button/Button";
import {PatientSearchFields} from "../../../models";

const selectGender = [
  {label: 'Male', value: 'male'},
  {label: 'Female', value: 'female'},
];

interface IProps {
  pageName: string;
  searchPatient?: (searchFields : PatientSearchFields) => void,
  openModal?: (type: 'fill' | 'send' | 'delete' | 'search' | null ) => void,
  closeModal?: () => void,
  isSearchOpen?: boolean,
  isSearhVisible?: boolean,
}

interface IFields {
  search: string;
}

const initialPatientSearching = {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    dob: null,
    gender: null,
    record_number: "",
    ss_number: "",
    member_id: "",
    pin: "",
}

const HeaderSearch: FC<IProps> = ({
                                      pageName,
                                      searchPatient,
                                      openModal,
                                      closeModal,
                                      isSearchOpen,
                                      isSearhVisible}) => {

  const [searchFilters, setSearchFilters] = useState<PatientSearchFields>(initialPatientSearching);

  const handleSearchPatients = () => {
    searchPatient && searchPatient(searchFilters)
  }
  
  return (
      <>
        <div className={styles.headerSearch}>
          <div className={styles.headerSearch__pageName}>{pageName}</div>
          { isSearhVisible && openModal ?
              <div className={styles.headerSearch__searchWrapper} onClick={openModal('search')}>
                  <div className={styles.headerSearch__searchButton}>Search Patients</div>
                  <img className={styles.headerSearch__searchIcon} src={searchIcon} alt="icon"/>
              </div>
          : null }
        </div>
        {isSearchOpen && <Modal
            title='Search Patients'
            width={520}
            classNameBack={styles.searchModalBack}
            className={styles.searchModal}
            onClose={closeModal}>
          <div className={'b-modal'}>
            <div className={'b-form__row -two_column'}>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.id}
                    label={'ID *'}
                    onChange={value => setSearchFilters({...searchFilters, id: value })}/>
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.email}
                    label={'Email *'}
                    onChange={value => setSearchFilters({...searchFilters, email: value })}/>
              </div>
            </div>
            <div className={'b-form__row -two_column'}>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.last_name}
                    label={'Last Name * '}
                    onChange={value => setSearchFilters({...searchFilters, last_name: value })}/>
              </div>
              <div className={'b-form__formGroup'}>
                <div className={'b-form__datePikerLabel'}>
                  Date of Birth *
                </div>
                <DatePicker
                    dateFormat={'MM.dd.yyyy'}
                    selected={searchFilters.dob}
                    onChange={value => setSearchFilters({...searchFilters, dob: value })}/>
              </div>
            </div>
            <div className={'b-form__row -two_column'}>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.first_name}
                    label={'First Name *'}
                    onChange={value => setSearchFilters({...searchFilters, first_name: value })}/>
              </div>
              <div className={'b-form__formGroup'}>
                <Select
                    value={searchFilters.gender}
                    options={selectGender}
                    label={'Gender *'}
                    className={'mb-0'}
                    onChange={value => setSearchFilters({...searchFilters, gender: value })}/>
              </div>
            </div>
            <div className={'b-form__row -two_column'}>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.record_number}
                    label={'Record Number'}
                    onChange={value => setSearchFilters({...searchFilters, record_number: value })}/>
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.ss_number}
                    label={'SS Number'}
                    onChange={value => setSearchFilters({...searchFilters, ss_number: value })}/>
              </div>
            </div>
            <div className={'b-form__row -two_column'}>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.member_id}
                    label={'Member ID'}
                    onChange={value => setSearchFilters({...searchFilters, member_id: value })}/>
              </div>
              <div className={'b-form__formGroup'}>
                <Input
                    value={searchFilters.pin}
                    label={'PIN'}
                    onChange={value => setSearchFilters({...searchFilters, pin: value })}/>
              </div>
            </div>
            <div className={'b-modal__btnWrapper -mt-20'}>
            <Button
              title='Clear'
              type={'transparency'}
              className={'darkest -uppercase'}
              onPress={() => {
                searchPatient && searchPatient(initialPatientSearching),
                setSearchFilters(initialPatientSearching)
              }}
              />
              <Button 
              title={'Search'}
              className={'-ml-20 -uppercase'}
               onPress={() => handleSearchPatients()}
               />
            </div>
          </div>
        </Modal> }
      </>
  );
};

export default HeaderSearch;