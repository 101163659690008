import React, { FC, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { Question, QuestionParent } from '../../../../../models/templates/templates.model';
import Checkbox from "../../../../controls/CheckBox/CheckBox";
import Textarea from "../../../../controls/Textarea/Textarea";

type IProps = {
    subQuestionNumber?: number;
    data: Question;
    id: string;
    surveyStatus?: string;
    // key: string;
    collectAnswers: (type: 'sub' | 'main', id: number, answers?: number[], parent?: QuestionParent, text?: string) => void;
    preview?: boolean;
    errorSurveyQuestion?: boolean;
};

const SurveyQuestion: FC<IProps> = ({ subQuestionNumber, data, id, surveyStatus, collectAnswers, preview, errorSurveyQuestion }) => {
    const [form, setForm] = useState<Question>(data);

    useEffect(() => {
        setForm(data);
    }, [data]);

    const textAnswer = (text: string) => {
        if (form.parent) {
            collectAnswers('sub', form.id, undefined, form.parent, text)
            setForm({ ...form, textArea: text })
        } else {
            collectAnswers('main', form.id, undefined, undefined, text)
            setForm({ ...form, textArea: text }) 
        }
    }

    const getAnswer = (answers: number[]) => {
        if (form.parent) {
            collectAnswers('sub', form.id, answers, form.parent)
            setForm({ ...form, answers: answers })
        } else {
            collectAnswers('main', form.id, answers)
            setForm({ ...form, answers: answers })
        }
    }


    const checkSwitcher = (checked: boolean) => {
        if (form.options) {
            if (checked) {
                getAnswer([form.options[0].id])
            } else {
                getAnswer([form.options[1].id])
            }
        }
    }

    const collectCheckBoxes = (id: number) => {
        const index = form.answers?.findIndex(optionId => optionId === id)
        const isChecked = index === -1 ? false : true;
        const newAnswers = JSON.parse(JSON.stringify(form.answers)) || [];

        if (isChecked) {
            newAnswers.splice(index, 1);
        } else {
            newAnswers.push(id);
        }

        getAnswer(newAnswers);
    }

    const renderTab = (type: string) => {
        switch (type) {
            case 'input':
                return (
                    <div className="b-surveyForm__input">
                        <Textarea
                            value={form.textArea || ''}
                            placeholder={surveyStatus === 'Completed' ? '' : 'Arbitrary answer'}
                            onChange={text => textAnswer(text)}
                            disable={surveyStatus === 'Completed'}
                            id={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `main-${form.id}`}
                            />
                    </div>
                );
            case 'radio':
                return (
                    <>
                        <div className="b-customRadioList">
                            {form.options?.map(option => {
                                const isChecked = !!form.answers?.find(id => id === option.id)
                                return (
                                    <>
                                        <input
                                            checked={isChecked}
                                            type="radio"
                                            disabled={surveyStatus === 'Completed'}
                                            name={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}`}
                                            id={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}-${option.id}`}
                                        />
                                        <label
                                            onClick={() => surveyStatus === 'Completed' ? null : getAnswer([option.id])}
                                            htmlFor={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}-${option.id}`}
                                        >
                                            {option.subQuestions.length ?
                                                <>
                                                    <ReactTooltip id={`tree${form.id}`} className='b-tooltip'>
                                                        The answer contains a decision tree
                                                    </ReactTooltip>
                                                    <span data-tip data-for={`tree${form.id}`} className="b-customRadioList__toolTipTree"></span>
                                                </>
                                                : null}
                                            {option.title}
                                        </label>
                                    </>
                                )
                            })
                            }
                        </div>
                        {form.answers?.length ?
                            form.options?.map(option => {
                                if (form.answers?.length && form.answers[0] === option.id) {
                                    return (
                                        <div key={`radio-option${option.id}`} className={'b-surveyForm__itemSubQuestionBlock'}>
                                            {option.subQuestions.length > 0
                                                ? option.subQuestions
                                                    .map((item, idx) => (
                                                        <SurveyQuestion
                                                            preview={preview ? preview : undefined}
                                                            subQuestionNumber={preview ? idx + 1 : undefined}
                                                            id={`subQuestion-${item.title}-${option.id}${item.id}`}
                                                            data={item}
                                                            key={`radio-subQuestionItem-${item.id}`}
                                                            collectAnswers={collectAnswers}
                                                            errorSurveyQuestion={errorSurveyQuestion}
                                                            surveyStatus={surveyStatus}
                                                        />
                                                    ))
                                                : null}
                                        </div>
                                    )
                                }
                            })
                            : null}
                    </>
                );
            case 'radio_vertical':
                return (
                    <>
                        <div className="b-customRadioList --vertical">
                            {form.options?.map(option => {
                                const isChecked = !!form.answers?.find(id => id === option.id)
                                return (
                                    <>
                                        <input checked={isChecked}
                                            type="radio"
                                            disabled={surveyStatus === 'Completed'}
                                            name={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}`}
                                            id={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}-${option.id}`}
                                        />
                                        <label
                                            onClick={() => surveyStatus === 'Completed' ? null : getAnswer([option.id])}
                                            htmlFor={form.parent ? `sub${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}-${option.id}`}
                                        >
                                            {option.subQuestions.length ?
                                                <>
                                                    <ReactTooltip id={`tree${form.id}`} className='b-tooltip'>
                                                        The answer contains a decision tree
                                                    </ReactTooltip>
                                                    <span data-tip data-for={`tree${form.id}`} className="b-customRadioList__toolTipTree"></span>
                                                </>
                                                : null}
                                            {option.title}
                                        </label>
                                    </>
                                )
                            })
                            }
                        </div>
                        {form.answers?.length ?
                            form.options?.map(option => {
                                if (form.answers?.length && form.answers[0] === option.id) {
                                    return (
                                        <div key={`radioVertical-option${option.id}`} className={'b-surveyForm__itemSubQuestionBlock'}>
                                            {option.subQuestions.length > 0
                                                ? option.subQuestions
                                                    .map((item, idx) => (
                                                        <SurveyQuestion
                                                            preview={preview ? preview : undefined}
                                                            subQuestionNumber={preview ? idx + 1 : undefined}
                                                            id={`subQuestion-${item.title}-${option.id}${item.id}`}
                                                            data={item}
                                                            key={`radioVertical-subQuestionItem-${item.id}`}
                                                            collectAnswers={collectAnswers}
                                                            errorSurveyQuestion={errorSurveyQuestion}
                                                            surveyStatus={surveyStatus}
                                                        />
                                                    ))
                                                : null}
                                        </div>
                                    )
                                }
                            })
                            : null}
                    </>
                );
            case 'switcher':
                return (
                    <>
                        <div className={"toggle"}>
                            {form.options[0].subQuestions.length || form.options[1].subQuestions.length ?
                                <>
                                    <ReactTooltip id={`tree${form.id}`} className='b-tooltip'>
                                        The answer contains a decision tree
                                    </ReactTooltip>
                                    <span data-tip data-for={`tree${form.id}`} className="toggle__toolTipTree"></span>
                                </>
                                : null}
                            <label className={'toggle-group'}>
                                <input
                                    checked={form.answers?.length && form.answers < [2] || undefined}
                                    onChange={e => checkSwitcher(e.target.checked)}
                                    type={'checkbox'}
                                    disabled={surveyStatus === 'Completed'}
                                />
                                <span className={'toggle-group-on'}>Yes</span>
                                <span className={'toggle-group-off'}>No</span>
                                <span className={'toggle-group-handle'}></span>
                            </label>
                        </div>
                        {form.answers?.length ?
                            form.options?.map(option => {
                                if (form.answers?.length && form.answers[0] === option.id) {
                                    return (
                                        <div key={`switcher-${option.id}`} className={'b-surveyForm__itemSubQuestionBlock'}>
                                            {option.subQuestions.length > 0
                                                ? option.subQuestions
                                                    .map((item, idx) => (
                                                        <SurveyQuestion
                                                            preview={preview ? preview : undefined}
                                                            subQuestionNumber={preview ? idx + 1 : undefined}
                                                            id={`switcher-subQuestion-${item.title}-${option.id}${item.id}`}
                                                            data={item}
                                                            key={`switcher-subQuestionItem-${item.id}`}
                                                            collectAnswers={collectAnswers}
                                                            errorSurveyQuestion={errorSurveyQuestion}
                                                            surveyStatus={surveyStatus}
                                                        />
                                                    ))
                                                : null}
                                        </div>
                                    )
                                }
                            })
                            : null}
                    </>
                );
            case 'checkbox':
                return (
                    <>
                        {form.options?.map(option => {
                            const isChecked = !!form.answers?.find(id => id === option.id)
                            return (
                                <>
                                    <div key={`checkbox${option.id}`} className={"b-customCheckBoxList"}>
                                        {option.subQuestions.length ?
                                            <>
                                                <ReactTooltip id={`tree${form.id}`} className='b-tooltip'>
                                                    The answer contains a decision tree
                                                </ReactTooltip>
                                                <span data-tip data-for={`tree${form.id}`} className="b-customCheckBoxList__toolTipTree"></span>
                                            </>
                                            : null}
                                        <Checkbox
                                            id={`${form.id}-${option.id}`}
                                            checked={isChecked}
                                            onChange={() => collectCheckBoxes(option.id)}
                                            disabled={surveyStatus === 'Completed'} />
                                        <label htmlFor={`${form.id}-${option.id}`}>{option.title}</label>
                                    </div>
                                </>
                            )
                        })
                        }
                        {form.answers?.length ?
                            form.options?.map(option => {
                                // if (form.answers?.length && form.answers[0] === option.id) {
                                if (form.answers?.length && form.answers.includes(option.id)) {

                                    return (
                                        <div key={`checkbox-option${option.id}`} className={'b-surveyForm__itemSubQuestionBlock'}>
                                            {option.subQuestions.length > 0
                                                ? option.subQuestions
                                                    .map((item, idx) => (
                                                        <SurveyQuestion
                                                            preview={preview ? preview : undefined}
                                                            subQuestionNumber={preview ? idx + 1 : undefined}
                                                            id={`checkbox-subQuestion-${item.title}-${option.id}${item.id}`}
                                                            data={item}
                                                            key={`checkbox-subQuestionItem-${item.id}`}
                                                            collectAnswers={collectAnswers}
                                                            errorSurveyQuestion={errorSurveyQuestion}
                                                            surveyStatus={surveyStatus}
                                                        />
                                                    ))
                                                : null}
                                        </div>
                                    )
                                }
                            })
                            : null}
                    </>
                );

            default:
                return null;

        }
    };

    return (
        <>
            <div className="b-surveyForm__item" id={id}
                style={preview ? { marginBottom: 10 } : undefined}>
                <div className="b-surveyForm__itemLabel" style={{ width: '90%' }}>
                    <span className={`b-surveyForm__item${preview ? `${form.parent ? 'SubQuestionPreview' : 'QuestionPreview'}` : `${form.parent ? 'QuestionSubQuestion' : 'Question'}`}`}>
                        {subQuestionNumber ? `${subQuestionNumber}. ` : ''}&nbsp;{form.title}
                    </span>
                    {form.description ?
                        <>
                            <ReactTooltip id={form.parent ? `subToolTip${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}`} className='b-tooltip'>
                                {form.description}
                            </ReactTooltip>
                            <span data-tip data-for={form.parent ? `subToolTip${form.id}${form.parent.category_id}${form.parent.mainQuestion_id}${form.parent.option_id}` : `${form.id}`} className="b-surveyForm__icon"></span>
                        </>

                        : null}
                </div>
                {renderTab(form.type)}
                <div
                    className={`b-surveyForm__errorText ${form.answers?.length === 0 && errorSurveyQuestion ? 'errorSurveyQuestion' : ''}`}
                >
                    <span>
                        {form.type !== 'input' && form.answers?.length === 0 && errorSurveyQuestion ? 'Please select an option' : ''}
                    </span>
                </div>
            </div>
        </>
    );
};

export default SurveyQuestion;
