import React, {FC, useEffect, useState} from 'react';
import searchIcon from '../../../../assets/images/icons/search.svg'
import styles from './QuestionnairesSearch.module.scss'
import Input from "../../../controls/Input/Input";

import DatePicker from "react-datepicker";
import Select from "../../../controls/Select/Select";
import Button from "../../../controls/Button/Button";
import {PatientSearchFields} from "../../../../models";
import { isEmptyObject } from '../../../../helpers';

const selectGender = [
  {label: 'Male', value: 'male'},
  {label: 'Female', value: 'female'},
];

interface IProps {
  searchPatient?: (searchFields : PatientSearchFields) => void,

}

const initialPatientSearching = {
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    dob: null,
    gender: null,
    record_number: "",
    ss_number: "",
    member_id: "",
    pin: "",
}

const QuestionnairesSearch: FC<IProps> = ({ searchPatient }) => {

  const [searchFilters, setSearchFilters] = useState<PatientSearchFields>(initialPatientSearching);
  const [disabledSearchButton, setDisabledSearchButton] = useState(true);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchPatients = () => {
    searchPatient && searchPatient(searchFilters);
  }
 
  useEffect(() => {
    let disabled: boolean = true;

    for (let key in searchFilters) {
      if (!!searchFilters[key] === true) {
        disabled = false;
      }
    }
    setDisabledSearchButton(disabled);
  }, [searchFilters]);

  return (
    <>
      {/* <div className={'b-form__subTitle'}>
        Search Patient
      </div> */}
      <div className={styles.searchBlock} onClick={() => setSearchOpen(!searchOpen)}>
        <span className={styles.searchBlockTitle}> Search Patient</span>
        <span className={`${styles.arrow} ${searchOpen ? `${styles.arrowOpen}` : ''}`}></span>
      </div>
      {searchOpen ? <>
      <div className={'b-form__row'}>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.first_name}
            label={'First Name *'}
            onChange={value => setSearchFilters({ ...searchFilters, first_name: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.last_name}
            label={'Last Name *'}
            onChange={value => setSearchFilters({ ...searchFilters, last_name: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <div className={'b-form__datePikerLabel'}>
            Date of Birth *
          </div>
          <DatePicker
            dateFormat={'MM.dd.yyyy'}
            selected={searchFilters.dob}
            onChange={value => setSearchFilters({ ...searchFilters, dob: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <Select
            value={searchFilters.gender}
            options={selectGender}
            label={'Gender *'}
            className={'mb-0'}
            onChange={value => setSearchFilters({ ...searchFilters, gender: value })} />
        </div>
      </div>
      <div className={'b-form__row'}>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.record_number}
            label={'Record Number'}
            onChange={value => setSearchFilters({ ...searchFilters, record_number: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.ss_number}
            label={'SS Number'}
            onChange={value => setSearchFilters({ ...searchFilters, ss_number: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.member_id}
            label={'Member ID'}
            onChange={value => setSearchFilters({ ...searchFilters, member_id: value })} />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={searchFilters.pin}
            label={'PIN'}
            onChange={value => setSearchFilters({ ...searchFilters, pin: value })} />
        </div>
      </div>
      <div className={styles.patientControlList}>
        <Button
          title='Clear'
          type={'transparency'}
          className={'darkest -uppercase'}
          width={'100px'}
          onPress={() => {
            setSearchFilters(initialPatientSearching)
            searchPatient && searchPatient(initialPatientSearching)
            }} />
        <Button
          disabled={disabledSearchButton}
          title='Search'
          className={'-ml-10 -uppercase'}
          width={'100px'}
          onPress={() => handleSearchPatients()} />
      </div>
      </> : null}
    </>
  );
};

export default QuestionnairesSearch;