import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import App from './App';
import "react-datepicker/dist/react-datepicker.css";
import './assets/styles/styles.scss';

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <React.StrictMode>
            <App/>
          </React.StrictMode>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
