import React, { FC, useEffect, useState } from 'react';
import { QuestionCategory, Question, TemplatePreviewSaveModel, PreviewTemplate } from '../../../../../models';
import AvatarPlaceholder from "../../../../../assets/images/icons/avatar-placeholder.svg";
import DatePicker from "react-datepicker";
import Textarea from "../../../../controls/Textarea/Textarea";
import Button from "../../../../controls/Button/Button";
import Input from "../../../../controls/Input/Input";
import SurveyCategory from "../../../Survey/parts/SurveyCategory/SurveyCategory";
import ReactSpeedometer from "react-d3-speedometer"
import {useDispatch, useSelector} from "react-redux";
import { RootState } from '../../../../../store/reducers';
import { clearChartPreview, sendPreviewSurvey } from '../../../../../store/templates/templatesSlice';
import moment from 'moment';

const relatedQuestionnaires = [
    {
      number: 13,
      category: 'Readmission Risk: Low Risk',
      date: 'Tue 04-21-2020',
      time: '09:46pm',
      name: 'Superuser',
    },
    {
      number: 18,
      category: 'Readmission Risk: Medium Risk',
      date: 'Tue 04-21-2020',
      time: '09:46pm',
      name: 'Superuser',
    },
    {
      number: 48,
      category: 'Readmission Risk: High Risk',
      date: 'Tue 04-21-2020',
      time: '09:46pm',
      name: 'Dr. John Doe',
    },
  
  ];

type IProps = {
    template: PreviewTemplate | null,
    setPreviewIsOpen: (
        PreviewIsOpen: boolean | ((PreviewIsOpen: boolean) => boolean)
    ) => void;
};

type AccordionOpen = {
    history: boolean,
    share: boolean,
};

const TemplatePreviewContainer: FC<IProps> = ({ template, setPreviewIsOpen }) => {
    const userData = useSelector((state: RootState) => state.user.userData);
    const surveyChart = useSelector((state: RootState) => state.templates.previewScore);
    const dispatch = useDispatch();

    const initialAccordionOpen = {
        history: false,
        share: false,
    }

    const initialFormData = {
        answers: [],
    }

    const [accordionOpen, setOpen] = useState<AccordionOpen>(initialAccordionOpen);
    const [formData, setFormData] = useState(initialFormData);
    const [categoryList, setCategoryList] = useState<QuestionCategory[] | null>(template && template.fields);

    const showScoreByType =
      template?.type_color.alias === 'with_dial_and_score' ||
      template?.type_color.alias === 'with_score';
    const showDialByType =
      template?.type_color.alias === 'with_dial_and_score' ||
      template?.type_color.alias === 'with_dial';

    useEffect(() => {
      dispatch(clearChartPreview());
    }, [template?.type_color]);

    const collectFields = (id : number, categoryQuestions : Question[]) => {
        const currentCategoryIndex = categoryList?.findIndex(category => category.id === id)

        const updateCategory = (list : QuestionCategory[] | null) => {
            const newCategory = JSON.parse(JSON.stringify(list));
            if(currentCategoryIndex !== undefined) {
                newCategory[currentCategoryIndex].items = categoryQuestions;
            }
            return newCategory;
        }

        setCategoryList(updateCategory(categoryList));
        setFormData({...formData, answers: updateCategory(categoryList)});
    }

    const disabled = (formData: TemplatePreviewSaveModel) => {
        let disabled = false;
        formData.answers.map((category) => {
            for (let i = 0; i < category.items.length; i++) {
                if (!category.items[i].answers?.length) {
                    return disabled = true;
                }
            }
        });

        return disabled;
    };

    const saveSurveyData = (e:Event, data : TemplatePreviewSaveModel) => {
        e.preventDefault();
        const sendData = {
            ...data,
        }
        dispatch(sendPreviewSurvey(sendData));
    }

    return (
        <>
            <button
                className="templates__backButton"
                onClick={() => setPreviewIsOpen(false)}
            >
                back to Survey
            </button>
            <div className={'b-mainContainer__wrapperInner'}>
                <div className="b-mainContainer__contentWrapper">

                    <div className="b-survey">
                        <div className="b-survey__heading">
                            <div
                            //  style={{backgroundColor: template?.type_color.color}}
                              className="b-survey__headingAva">{template?.initial}</div>
                            <div className="b-survey__headingHolder">
                                <div className="b-survey__headingTitle">{template?.title}</div>
                                <ul>
                                    <li>{template?.type_color.title}</li>
                                    {/* <li>{`${template.creator.first_name} ${template.creator.last_name}`}</li> */}
                                    <li>Creator template: {userData?.user.name}</li>
                                    <li>{moment(template?.date_of_creation).format('MM-DD-YYYY')}</li>
                                </ul>
                            </div>
                        </div>

                                    <div className="b-infoBar">
                                        <ul className="b-infoBar__list">
                                            <li><img src={AvatarPlaceholder} className={'b-infoBar__ava'}/> Patient: -</li>
                                            <li>ID: -</li>
                                            {/* <li>ID: {surveyInfo.patient.id}</li> */}

                                            <li>Date of Birth: -</li>
                                            {/* <li>Date of Birth: {surveyInfo.patient.dob}</li> */}

                                        </ul>
                                    </div>
                        {/* b-surveyForm start */}
                        <form action="#" className="b-surveyForm">

                            <div className="b-surveyForm__dateRow">

                                <div className={'b-surveyForm__dateCol'}>
                                    <div className={'b-form__datePikerLabel'}>
                                        Current admission date (if applicable)
                                    </div>
                                    <DatePicker minDate={new Date()}
                                                dateFormat={'MM.dd.yyyy'}
                                                selected={new Date()}
                                                // onChange={date => setFormData({...formData, admission_date: date})}/>
                                                onChange={date => null} />

                                </div>

                                <div className={'b-surveyForm__dateCol'}>
                                    <div className={'b-form__datePikerLabel'}>
                                        Discharge date (if known)
                                    </div>
                                    <DatePicker minDate={new Date()}
                                                dateFormat={'MM.dd.yyyy'}
                                                selected={new Date()}
                                                // onChange={date => setFormData({...formData, discharge_date: date})}/>
                                                onChange={date => null} />

                                </div>

                            </div>

                            {/* b-surveyForm block start */}
                            <div className="b-surveyForm__block">
                                {categoryList && categoryList.length > 0
                                     ? categoryList.map((category) => (
                                         <SurveyCategory
                                             key={`categoryItems-${category.id}`}
                                             title={category.title}
                                             categoryId={category.id}
                                             categoryItems={category.items}
                                             collectFields={collectFields}
                                         />
                                     ))
                                     : null}
                            </div>
                            {/* b-surveyForm block end */}

                            {/* b-surveyForm block start */}
                            {showScoreByType || showDialByType && !disabled(formData) ? 
                            <div className="b-surveyForm__block">
                                <h2 className="b-surveyForm__tilte">Assessment</h2>
                                <div className="b-surveyForm__chart">


                                {/* {surveyChart ? */}
                                    <div className="b-surveyForm__chart__sideBlock -chart">
                                        <div className="b-surveyForm__chart__chartHolder">
                                            <div className='b-surveyForm__chart__chartFrame'>
                                                <ReactSpeedometer
                                                    width={400}
                                                    height={250}
                                                    minValue={0}
                                                    maxValue={100}
                                                    fluidWidth={true}
                                                    needleHeightRatio={0.42}
                                                    ringWidth={25}
                                                    value={showScoreByType ? surveyChart?.score || 0 : 0}
                                                    maxSegmentLabels={3}
                                                    segments={3}
                                                    customSegmentStops={[0,17,32,100]}
                                                    currentValueText={' '}
                                                    // customSegmentLabels={[
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    //     {
                                                    //         text: "",
                                                    //     },
                                                    // ]}
                                                    segmentColors={[
                                                        "#6FDE47",
                                                        "#E5D012",
                                                        "#DB4E6F",
                                                    ]}
                                                    needleColor="#515151"
                                                />
                                                <div className={`score-container`}>
                                                    <div className={`score`}>
                                                        <span>{showScoreByType ? surveyChart?.score && Math.round(surveyChart?.score) || 0 : 0}</span>
                                                    </div>
                                                </div>
                                                <div className={`score-level-container`}>
                                                    <div className={'score-level'}>
                                                        <span>{showScoreByType ? surveyChart?.score_level && `${surveyChart?.score_level} Risk` : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         
                                    </div>
                                    <div className="b-surveyForm__chart__sideBlock -chartDetails">
                                        <h2 className="b-surveyForm__tilte">Contributing Factors</h2>
                                        <div className={"b-surveyForm__chart__factors"}>
                                            {showScoreByType && surveyChart?.category_score_level ?
                                               surveyChart.category_score_level.map((category,index) => {
                                                    return (
                                                        <div key={index} className={`factor ${category.score_level.toLowerCase()}`}>
                                                            <span className={"factor-text"}>{category.title}</span>
                                                        </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                <ul className="riskList">
                                    <li
                                    className="riskList__item --high"
                                    >
                                    High risk
                                    </li>
                                    <li
                                    className="riskList__item --medium"
                                    >
                                    Medium risk
                                    </li>
                                    <li
                                    className="riskList__item --low"
                                    >
                                    Low risk
                                    </li>
                                </ul>
                            </div>
                            : null}
                            {/* b-surveyForm block end */}

                            {/* b-surveyForm block start */}
                            <div className="b-surveyForm__block">
                                <h2 className="b-surveyForm__tilte">Related Questionnaires</h2>

                                <div className="b-relatedQuestionnaires">
                                    {relatedQuestionnaires.map((questionnaire, index) => {
                                        let riskClassName;
                                        if (questionnaire.number < 15) {
                                            riskClassName = 'low';
                                        } else if (questionnaire.number > 30) {
                                            riskClassName = 'high';
                                        } else {
                                            riskClassName = 'medium';
                                        }
                                        return (
                                            <div
                                                key={index}
                                                className="b-relatedQuestionnaires__item"
                                            >
                                                <span
                                                    className={`b-relatedQuestionnaires__number ${riskClassName}`}
                                                >
                                                    {questionnaire.number}
                                                </span>
                                                <div className="b-relatedQuestionnaires__holder">
                                                    <div className="b-relatedQuestionnaires__title">
                                                        {questionnaire.category}
                                                    </div>
                                                    <ul>
                                                        <li>{questionnaire.date}</li>
                                                        <li>{questionnaire.time}</li>
                                                        <li>{questionnaire.name}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {/* b-surveyForm block end */}
                            {showScoreByType ? 
                            <div className="b-surveyForm__buttons">
                                <div className="b-surveyForm__buttonsLeft">
                                    <Button
                                        title={'save'}
                                        disabled={disabled(formData)}
                                        className={'-uppercase'}
                                        onPress={(e: Event) => saveSurveyData(e, formData)}
                                    />
                                    {/* <Button
                                        title='Complete'
                                        className={'darkest -mr-10 -uppercase'}
                                        onPress={(e: any) => saveSurveyData(e, 'save' ,formData)}
                                    /> */}
                                </div>
                            </div>
                            : null}
                        </form>
                        {/* b-surveyForm end */}

                        {/* accordion start */}
                        {showDialByType ? 
                        <>
                        <div className="b-surveyForm__notes">
                            <Textarea
                                value={'Preview'}
                                label={'Notes'}
                                placeholder={'Lorem Dolor'}
                                onChange={() => null}/>
                        </div>
                        <div className="b-accordion">
                            <div className={`b-accordion__item ${accordionOpen.history ? '--opened' : ''}`}>
                                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, history: !accordionOpen.history })}>
                                    <span className="b-accordion__title">Questionnaire History</span>
                                    <span className="b-accordion__arrow"></span>
                                </div>
                                <div className="b-accordion__content">
                                    <div className="b-accordion__contentHolder">
                                        <div className="b-accordion__contentText" style={{width : '100%'}}>
                                            <div className={'b-historyTable'}>
                                                <table>
                                                    <tr>
                                                        <td><span className="b-historyTable__icon b-historyTable__icon--created"></span></td>
                                                        <td><b>John Dragunovsky</b></td>
                                                        <td>Created Document</td>
                                                        <td><b>Fri</b></td>
                                                        <td>06-06-2021   03:42pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="b-historyTable__icon b-historyTable__icon--updated"></span></td>
                                                        <td><b>John Dragunovsky</b></td>
                                                        <td>Updated Document</td>
                                                        <td><b>Sat</b></td>
                                                        <td>06-07-2021   02:15pm</td>
                                                    </tr>
                                                    <tr>
                                                        <td><span className="b-historyTable__icon b-historyTable__icon--completed"></span></td>
                                                        <td><b>John Dragunovsky</b></td>
                                                        <td>Completed Document</td>
                                                        <td><b>Wed</b></td>
                                                        <td>06-09-2021   04:12pm</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`b-accordion__item ${accordionOpen.share ? '--opened' : ''}`}>
                                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, share: !accordionOpen.share })}>
                                    <span className="b-accordion__title"> Share This Questionnaire</span>
                                    <span className="b-accordion__arrow"></span>
                                </div>
                                <div className="b-accordion__content">
                                    <div className="b-accordion__contentHolder">
                                        <div className="b-accordion__contentText">
                                            <p>Sharing the information contained in this survey is only intended for appropriate discharge planning purposes. You agree that this function is part of your official job duties and responsibilities, and the intended recipient is involved in provision of care and services to this patient. You also agree that you will NOT use this service to violate any applicable state and federal laws, contracts, third-party rights, or internal GENERIC Health System rules, policies and/or procedures. You acknowledge and agree that the officers, directors and employees of GENERIC Health System are not responsible/liable for your conduct in using this service inappropriately.</p>
                                            <div className="b-shareLink">
                                                <span className="b-shareLink__label">Copy this link to your clipboard</span>
                                                <div className="b-shareLink__inputRow">
                                                    <Input
                                                        disable={true}
                                                        className="b-shareLink__input"
                                                        value={'https://satsuki.avixena.com/#acp-8844f2f6-c54b-11eb-986e-aecff5682a73'}
                                                        onChange={() => null}/>
                                                    <button disabled={true} type="button" className="b-shareLink__btn b-shareLink__btn--print"></button>
                                                    <button disabled={true} type="button" className="b-shareLink__btn b-shareLink__btn--send"></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                        : null}
                        {/* accordion end */}
                    </div>

                </div>
            </div>
        </>
    );
};

export default TemplatePreviewContainer;
