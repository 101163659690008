import React, {FC, useState} from 'react';
import {Button, Input, Loader, Select} from '../../../controls';
import {validate, password} from '../../../../helpers/validatior';

import {useDispatch} from "react-redux";
import {KeyValueModel} from "../../../../models";
import {isEmptyObject} from "../../../../helpers";
import {resetPassword} from "../../../../store/user/userSlice";
import PswdIc from '../../../../assets/images/icons/pswd_ic.svg'
import { useParams, useHistory, useLocation } from "react-router-dom"
import AvixenaLogo from "../../../../assets/images/icons/avixenalogo.svg";
import Modal from '../../../common/Modal/Modal';
import { Routes } from '../../../../routes/routes';

interface IState {
    modalType: 'success' | 'error' | null,
}

interface IProps {

}

const initialState: IState = {
    modalType: null,
};

const validatorScheme = {
    required: ['password'],
    custom: [password(['password'])],
}


const ResetPasswordContainer: FC<IProps> = () => {
    const dispatch = useDispatch();

    const {token} = useParams<KeyValueModel<string>>();

    const history = useHistory();
    const {pathname} = useLocation<KeyValueModel<string>>();

    const [pass, setPass] = useState<string>('');

    const [errors, setErrors] = useState<KeyValueModel<string | boolean>>({});

    const [loading, setLoading] = useState<boolean>(false);
    let [state, setState] = useState<IState>(initialState);

    const handleCloseModal = () => {
        setState({ ...state, modalType: null });
    };
    const handleChange = (value: string) => {
        setPass(value);
        setErrors({});
    };

    const handleSubmit = () => {
        const {errors} = validate(validatorScheme, {password:pass});
        setErrors(errors);
        if (isEmptyObject(errors)) {
            setLoading(true);
            dispatch(resetPassword({
                ...{password:pass,token:token},
                callback: (message) => {
                    if (message.token) {
                        setState({...state, modalType: 'error'});
                    } else if (message.message.indexOf('successfully changed') !== -1) {
                        setState({...state, modalType: 'success'});
                    } else if (message) {
                        setErrors({...errors, password: true});
                    }
                    setLoading(false);
                }
            }));
        }
    };

    return (
        <div className='auth__page'>
            {loading && <Loader/>}
            <img src={AvixenaLogo} style={{marginBottom:'30px'}}/>
            <div className='auth__form'>
                <div className='auth__textWrapper -forgotpass'>
                    <div className='auth__title'>
                    {
                    pathname.indexOf("/create-password") !== -1 ? 'Create a password' : 'Reset your password'
                    }</div>
                    <div className='auth__text -forgotpass'>
                        Welcome to Avixena health solution. Once you log in you will be able to create and track your patient{`'`}s surveys and their progress.
                    </div>
                    <div className='auth__titleLine'/>
                </div>
                <div className='auth__input -forgotpass'>
                    <Input
                        height={50}
                        label={'Password:'}
                        classLabelName={'-authStyle'}
                        onChange={handleChange}
                        value={pass}
                        type={'password'}
                        error={errors.password}
                        leftIcon={<img src={PswdIc} alt=""/>}
                    />
                </div>
                <Button
                    height={50}
                    onPress={handleSubmit}
                    title={pathname.indexOf("/create-password") !== -1 ? 'CREATE PASSWORD' : 'RESET PASSWORD'}
                />
            </div>
            {state.modalType === 'success' && <Modal
                title='Success'
                width={520}
                onClose={() => history.push(Routes.SIGNIN)}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Password successfully changed
                </div>
                </div>
            </Modal>}
            {state.modalType === 'error' && <Modal
                title='Error'
                width={520}
                onClose={handleCloseModal}>
                <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                    Forgot Password link expired, please try again
                </div>
                <div className={'b-modal__btnWrapper'}>
                    <Button title={'Forgot Password'} onPress={() => history.push(Routes.FORGOTPASS)} width='160px'/>
                    <Button title={'Sign In'} onPress={() => history.push(Routes.SIGNIN)} type={'transparency'} className={'-ml-10'} width='100px'/>
                </div>
                </div>
            </Modal> }
        </div>
    )
};

export default ResetPasswordContainer;
