import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
  getUsersList,
  getUsersListSuccess,
  getUserById,
  deleteUserById,
  addUser,
  updateUser,
} from './manageUsersSlice';
import Api from '../../services/api';
import { DeletePayload } from '../../models/templates/templates.model';
import { ManageUsersPayload, UserAddUpdatePayload, UserByIdPayload } from '../../models/manageUsers/manageUsers.model';

function* getUsersListSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { data, page, callback } }: { payload: ManageUsersPayload } = yield take(getUsersList.toString());

    try {
      const response = yield call(Api.post, `manager/list?page=${page}`, data);
      if (response) {
        yield put(getUsersListSuccess(response.data.resource));
        if (callback) {
          callback({message:'success'});
        }
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
      if (e.response.status === 403) {
        if (callback){
          callback(e.response.data);
        }
      }
    }
  }
}

function* getUserByIdSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {id , callback} }: { payload: UserByIdPayload } = yield take(getUserById.toString());

    try {
      const response = yield call(Api.post, `manager/get`, {id});
      if (response) {
          callback(response.data.resource);
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteUserByIdSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { ids, callback } }: { payload: DeletePayload } = yield take(deleteUserById.toString());

    try {
      const response = yield call(Api.delete, `manager/delete-staff`, { ids });
      if (response) {
        callback();
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* addUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { data, callback } }: { payload: UserAddUpdatePayload } = yield take(addUser.toString());
    try {
      const response = yield call(Api.post, `manager/staff-add`, data);
      if (response) {
        // yield put(addPatientSuccess(response.data.resource));
        callback({message:'success'});
      }
    } catch (e) {
      console.log('error message222', e.response);
      if (e.response.status === 400) {
        callback(e.response.data.errors);
      }
    }
  }
}

function* updateUserSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: { data, callback } }: { payload: UserAddUpdatePayload } = yield take(updateUser.toString());
    try {
      const response = yield call(Api.put, `manager/update-staff`, undefined, data);
      if (response) {
        // yield put(updatePatientSuccess(response.data.resource));
        callback({message:'success'});
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([
    getUsersListSaga(),
    getUserByIdSaga(),
    deleteUserByIdSaga(),
    addUserSaga(),
    updateUserSaga()
  ]);
}
