import React, {FC, useEffect} from 'react';
import styles from './Modal.module.scss'
import closeIcon from '../../../assets/images/icons/modal_close_ic.svg';
import Image from "../../controls/Image/Image";
import { isMobileOnly } from "react-device-detect";

interface IProps {
  width?: number,
  className?: string,
  onClose: () => void,
  title?: string,
  cross?: boolean,
  classNameTitle?: string,
  classNameBack?: string,
  disableScroll?: boolean,
}

const Modal: FC<IProps> = ({
                               children,
                               width,
                               className,
                               onClose,
                               title,
                               classNameTitle,
                               classNameBack,
                               disableScroll,
}) => {

  const scroll = disableScroll || false;

  useEffect(() => {
    if (!scroll) {
      window.scrollTo({ top: 0 });
    }
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  }, []);

  return (
      <>
        <div className={`${styles.back} ${classNameBack || ''}`} onClick={onClose} style={scroll ? {top: window.pageYOffset} : undefined}>
          <div
              onClick={(e) => e.stopPropagation()}
              className={`
              ${styles.modal}
              ${className || ''}
            `}
              style={width ? {maxWidth: width}: undefined}>
            {title && <div className={`${styles.wrapTitle} ${isMobileOnly ? styles.wrapMobileTitle : ''} ${classNameTitle || ''}`}>
                <span className={styles.title}>{title}</span>
                <div className={styles.backBtn} onClick={onClose}>
                    <Image className={styles.icon} width={'18px'} height={'18px'} source={closeIcon}/>
                </div>
            </div> }
            {children}
          </div>
        </div>

      </>
  );
};

Modal.defaultProps = {
  width: 545
}

export default Modal;
