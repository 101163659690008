import {call, all, take, StrictEffect} from 'redux-saga/effects';
import {getReports, Reports} from './reportSlice';
import {httpRequestReport} from "../../services/httpRequest";
const fileDownload = require('js-file-download');


function* getReportSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: data}: {payload: Reports} = yield take(getReports.toString());

    try {
      const response = yield call(
          httpRequestReport,
          `reports/questionnaire`,
          { method: 'POST', ...data, responseType: 'blob' },
      );
      
      if (response) {
        fileDownload(response, 'report.xlsx');
      }
    } catch (e) {
      console.log('error message222', e);
      if (e.response.status === 403) {
        if (data.callback){
          data.callback(e.response);
        }
      }
    }
  }
}

export function* saga() {
  yield all([
    getReportSaga()
  ]);
}
