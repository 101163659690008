import React, { useState, useEffect } from 'react';
import styles from './SettingsView.module.scss'
import { useDispatch, useSelector } from "react-redux";
import HeaderSearch from "../../common/HeaderSearch/HeaderSearch";
import Modal from "../../common/Modal/Modal"
import Input from "../../controls/Input/Input";
import Button from "../../controls/Button/Button";
import Loader from "../../controls/Loader/Loader";
import {RootState} from "../../../store/reducers";
import {
  getUserDetails,
  updateUserDetails,
  updateUserPassword
} from "../../../store/user/userSlice";
import {email, password, phone, validate} from "../../../helpers/validatior";
import {IFileTemp, KeyValueModel} from "../../../models";
import {isEmptyObject} from "../../../helpers";
import Avatar from "./parts/Avatar";

interface IState {
  modalType: 'password' | null,
}

const initialState: IState = {
  modalType: null,
};

interface UpdateProfileModel {
  first_name: string,
  last_name: string,
  email: string,
  phone: string,
  mobile: string,
  comments: string,
  role: string,
  photos: IFileTemp[]
}

const validatorScheme = {
  required: [
      'first_name',
      'last_name',
      'email',
  ],
  custom: [
    email(['email']),
    phone(['phone']),
    phone(['mobile']),
  ],
};

const passwordValidatorScheme = {
  custom: [
    password(['password']),
  ],
};

const SettingsView = () => {
  const dispatch = useDispatch();

  const updateUserProfile = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    mobile: '',
    comments: '',
    role: '',
    photos: []
  };

  const updateUserPasswordData = {
    password: ''
  };

  const userProfile = useSelector((state: RootState) => state.user.userData);
  const [errors, setErrors] = useState<KeyValueModel<string | boolean>>({});
  const [passwordError, setPasswordError] = useState<KeyValueModel<string | boolean>>({});
  const [state, setState] = useState<IState>(initialState);
  const [updateData, setUpdateData] = useState<UpdateProfileModel>(updateUserProfile);
  const [updatePassword, setUpdatePassword] = useState(updateUserPasswordData);
  let [isPhotoChanged, setIsPhotoChanged] = useState(false);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    if (userProfile) {
      const photos = userProfile.profile.photos.map(item => ({
        name: item.details.name,
        ext: item.details.ext,
        id: item.id
      }));

      setUpdateData({
        first_name: userProfile.profile.first_name,
        last_name: userProfile.profile.last_name,
        email: userProfile.user.email,
        phone: userProfile.contact.phone,
        mobile: userProfile.contact.mobile,
        comments: userProfile.profile.comments,
        role: userProfile.role.name,
        photos,
      });
      setIsPhotoChanged(false);
    }
  }, [userProfile]);

  if (!updateData) {
    return <Loader/>
  }

  const handleChangePassword = (value: string) => {
    setUpdatePassword({
      password: value
    });
    setPasswordError({});
  };

  const handleChangeAvatar = (file: IFileTemp) => {
    setUpdateData({
        ...updateData,
      photos: [file]
    })
    setIsPhotoChanged(true);
  };

  const handleSavePassword = (e: any) => {
    e.preventDefault();
    const {errors} = validate(passwordValidatorScheme, updatePassword);
    setPasswordError(errors);
    if (isEmptyObject(errors)) {
      dispatch(
          updateUserPassword({
            data: updatePassword,
            callback: () => {
              setUpdatePassword({password: ''});
            }
          }),
      );

      setState({...state, modalType: null})
    }
  };

  const handleChangeInput = (name: string, value: string) => {
    setUpdateData({
      ...updateData,
      [name]: value
    });
    setErrors({});
  };

  const handleSaveProfile = (e: any) => {
    e.preventDefault();
    const {errors} = validate(validatorScheme, updateData);
    setErrors(errors);
    if (isEmptyObject(errors)) {
      const data: any = {
        ...updateData,
      };
      delete data.photos;
      if (isPhotoChanged) {
        data.photos = updateData.photos.map(item => item.id)
      }
      dispatch(
        updateUserDetails({
          data,
          callback: (errors) => {
            if (errors) {
              setErrors(errors)
            } else {
              dispatch(getUserDetails());
            }

          }
        }),
      );
    }
  };

  const handleModalOpen = (type: 'password' | null ) => (e: any) => {
    e.preventDefault();
    setState({...state, modalType: type})
  };

  const handleCloseModal = () => {
    setState({...state, modalType: null});
    setUpdatePassword({password: ''});
  };

  return (
      <div className={'b-mainContainer__wrapper'}>
        <HeaderSearch
            pageName='Settings'
            isSearhVisible={false}
        />

        <div className={'b-mainContainer__wrapperInner'}>
          <div className={`b-mainContainer__contentWrapper ${styles.contentWrapper}`}>
            <div className={styles.topWrapper}>
              <h2 className={styles.topWrapper__title}>Your Settings</h2>
            </div>
            <form className={`b-form ${styles.formSettings}`}>
              <div className={'b-form__row -one_column'}>
                <Avatar onChange={handleChangeAvatar} img={updateData.photos[0]}/>
              </div>
              <div className={'b-form__row -two_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.first_name}
                      label={'First name *'}
                      error={errors.first_name}
                      onChange={(value: string) => handleChangeInput('first_name',value)} />
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.last_name}
                      label={'Last name *'}
                      error={errors.last_name}
                      onChange={(value: string) => handleChangeInput('last_name', value)} />
                </div>
              </div>
              <div className={'b-form__row -two_column'}>
                <div className={'b-form__formGroup'}>
                  <div className={styles.passwordLabel}>Password *</div>
                  <Button
                      className={'darkest'}
                      title={'Change password'}
                      onPress={handleModalOpen("password")}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.role || ''}
                      label={'Title or Role'}
                      disable
                      onChange={() => {}} />
                </div>
              </div>
              <div className={'b-form__row -two_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.phone}
                      label={'Phone'}
                      error={errors.phone}
                      onChange={(value: string) => handleChangeInput('phone', value)} />
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.email}
                      label={'Email'}
                      error={errors.email}
                      onChange={(value: string) => handleChangeInput('email', value)} />
                </div>
              </div>
              <div className={'b-form__row -two_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={updateData.mobile}
                      label={'Mobile'}
                      error={errors.mobile}
                      onChange={(value: string) => handleChangeInput('mobile', value)} />
                </div>
              </div>
              <div className={`b-form__row ${styles.btnWrapper}`}>
                <Button
                    title={'Save'}
                    onPress={handleSaveProfile}/>
              </div>
              {state.modalType === 'password' && <Modal
                  title='Change Password'
                  width={520}
                  onClose={handleCloseModal}>
                <div className={'b-modal'}>
                  <div className={styles.modalInner}>
                    <div className={'b-form__formGroup'}>
                      <Input
                          value={updatePassword.password}
                          label={'New Password'}
                          type={'password'}
                          error={passwordError.password}
                          onChange={value => handleChangePassword(value) } />
                    </div>
                    <div className={'b-modal__btnWrapper'}>
                      <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
                      <Button title={'Yes'} onPress={handleSavePassword} className={'-ml-10'} width='100px'/>
                    </div>
                  </div>
                </div>
              </Modal> }
            </form>
          </div>
        </div>
      </div>
  );
};

export default SettingsView;
