import React, { FC, useState, useEffect } from 'react';
import styles from './TemplatesListView.module.scss'

import { Link, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { HeaderSearch } from '../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import Checkbox from "../../controls/CheckBox/CheckBox";
import Button from "../../controls/Button/Button";
import Select from "../../controls/Select/Select";
import Modal from "../../common/Modal/Modal"
import {Routes} from "../../../routes/routes";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { copyTemplateById, deleteTemplateById, getTemplateCategory, getTemplates } from '../../../store/templates/templatesSlice';
import { Template, TemplateCategory} from '../../../models/templates/templates.model';
import { Loader } from '../../controls';
import Pagination from '../../common/Pagination/Pagination';
import {PaginationModel} from "../../../models";

const selectDemo = [
  {label: 'Type One', value: 'one'},
  {label: 'Type Two', value: 'two'},
];

interface IState {
  modalType: 'fill' | 'send' | 'delete-all' | 'delete-one' | 'permissions-fail' | null,
}

const initialState: IState = {
  modalType: null,
};

interface InitialSearch {
  searching: {
    templates: {
      title: string,
    },
    type_colors: {
      title: string,
    },
    categories: {
      title: string,
    },
  },
  filtering: {
    categories: {
      id: undefined | number[]
    },
    type_colors: {
      id: undefined | number[]
    }
  },
  as_array: number,
}


const TemplatesList = () => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const templateCategory = useSelector((state: RootState) => state.templates.templateCategory);
  const templatesList: PaginationModel<Template[]> | null = useSelector((state: RootState) => state.templates.templates);
  const dispatch = useDispatch();
  const history = useHistory();

  const [arrayIdsForDelete, setArrayIdsForDelete] = useState<number[]>([]);
  const [deleteOneItem, setDeleteOneItem] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [choicedType, setchoicedType] = useState<number[]>([]);
  const [choicedCategory, setChoicedCategory] = useState<number[]>([0]);
  const [loading, setLoading] = useState<boolean>(false);
  let [state, setState] = useState<IState>(initialState);
  const [permissionsError, setpermissionsError] = useState<string>(`You don't have permissions to manage Surveys`);
  const [searchTemplates, setSearchTemplates] = useState<InitialSearch>({
    searching: {
      templates: {
        title: '',
      },
      type_colors: {
        title: '',
      },
      categories: {
        title: '',
      },
    },
    filtering: {
      categories: {
        id: undefined
      },
      type_colors: {
        id: undefined
      }
    },
    as_array: 0,
  });

  useEffect(() => {
    dispatch(getTemplateCategory());
  }, []);

  useEffect(() => {
    currentPage === 1 ?
      dispatch(getTemplates({ data: searchTemplates, page: 1, callback: (message) => {
          if (message.message !== 'success') {
            setState({...state, modalType: 'permissions-fail'});
            // setpermissionsError(message.message)
          }
          setLoading(false);
        }
    }))
      :
      setCurrentPage(1);
  }, [searchTemplates]);

  useEffect(() => {
    // if (templatesList) {
    //   setLoading(true);
    // }
    dispatch(getTemplates({
      data: searchTemplates, page: currentPage, callback: () => {
        setLoading(false);
      }
    }));
  }, [currentPage]);

  const handleSearchInTemplates = (filterName: string, value: string) => {
    setSearchTemplates({
      ...searchTemplates,
      searching: {
        ...searchTemplates.searching,
        [filterName]: { title: value },
      },
    });
  };

  const handleFilterByCategory = (value: number) => {
    let item: number[] = [value];
    if (choicedCategory.includes(value) && value !==0) {
      item = [];
    }
    setChoicedCategory(item);
    setSearchTemplates({
      ...searchTemplates,
      filtering: {
        ...searchTemplates.filtering,
        categories: {
          ...searchTemplates.filtering.categories,
          id: item.length && value !==0 ? [value] : undefined,
        },
      },
    });
  };

  const handleFilterByType = (value: number) => {
    let item: number[] = [value];
    if (choicedType.includes(value)) {
      item = [];
    }
    setchoicedType(item);
    setSearchTemplates({
      ...searchTemplates,
      filtering: {
        ...searchTemplates.filtering,
        type_colors: {
          ...searchTemplates.filtering.type_colors,
          id: item.length ? [value] : undefined,
        },
      },
    });
  };

  const _generateCategoryListForSelect = (templateCategory: TemplateCategory[]) => {
    let newList: { label: string; value: number }[] = [{label: 'All Categories', value: 0}];

    templateCategory.map((item) =>
      newList.push({ label: item.title, value: item.id })
    );
    return newList;
  };

  const copyTemplate = (template_id: number) => {
    dispatch(copyTemplateById(
      {
        id: template_id,
        callback: () => {
          currentPage === 1 ?
            dispatch(getTemplates({ data: searchTemplates, page: 1 }))
            :
            setCurrentPage(1);
        }

      }
    ));
  };

  const deleteTemplate = (deleteOneItem: number[]) => {
    dispatch(deleteTemplateById({
      ids: deleteOneItem,
      callback: () => {
        currentPage === 1 ?
          dispatch(getTemplates({ data: searchTemplates, page: 1 }))
          :
          setCurrentPage(1);
      }

    }));
    setDeleteOneItem([]);
    handleCloseModal();
  };


  const deleteSelected = (arrayTemplateForDelete: number[]) => {
    dispatch(deleteTemplateById({
      ids: arrayTemplateForDelete,
      callback: () => {
        currentPage === 1 ?
          dispatch(getTemplates({ data: searchTemplates, page: 1 }))
          :
          setCurrentPage(1);
      }
    }));
    setArrayIdsForDelete([]);
    handleCloseModal();
  };

  const checkItem = (template: Template) => {
    setArrayIdsForDelete((prev) => {
      if (prev.includes(template.id)) {
        prev = prev.filter((item) => item !== template.id);
        return prev;
      } else {
        return [...prev, template.id];
      }
    });
  };


  const handleModalOpen = (type: 'fill' | 'send' | 'delete-all' | 'delete-one' | null) => () => {
    setState({ ...state, modalType: type });
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null });
  };

  const allSelected = () => {
    let checkedCounter = 0;
    if (templatesList && templatesList.data) {
      templatesList.data.map((item) =>
        arrayIdsForDelete.includes(item.id) ? checkedCounter++ : item,
      );
      if (arrayIdsForDelete.length > 0 && templatesList.data.length === checkedCounter) {
        return true;
      }
    } 
    return false;
  };

  const checkAll = () => {
    if (templatesList && templatesList.data) {
      let checkedCounter = 0;
      templatesList.data.map((item) =>
        arrayIdsForDelete.includes(item.id) ? checkedCounter++ : item,
      );
      if (arrayIdsForDelete.length > 0 && templatesList.data.length === checkedCounter) {
        let array: number[] = [];
        templatesList.data.map((item) => array.push(item.id));
        const filteredCheckedIds = arrayIdsForDelete.filter((item) => !array.includes(item));

        setArrayIdsForDelete(filteredCheckedIds);
      } else {
        let array: number[] = [...arrayIdsForDelete];
        templatesList.data.map((item) => (array.includes(item.id) ? item : array.push(item.id)));

        setArrayIdsForDelete(array);
      }
    }
  };

  const filters = (
    <div className="filters">
      {templateCategory ? 
      <Select
      value={
        _generateCategoryListForSelect(templateCategory).filter(
          (item) =>
          choicedCategory.includes(item.value)
        ) || _generateCategoryListForSelect(templateCategory)[0]
      }
        options={_generateCategoryListForSelect(templateCategory)}
        label={''}
        className={'mb-0'}
        menuPosition={'fixed'}
        // placeholder={'Select a Category'}
        onChange={(value) => handleFilterByCategory(value.value)}
        />
        : null}

      <form action="#" className="searchForm">
        <input
          type="search"
          placeholder="Search"
          value={searchTemplates.searching.templates.title}
          onChange={(e) => handleSearchInTemplates('templates', e.target.value)}
        />
      </form>

      <ul className="categoryList">
        <li
          className="categoryList__item --inpatient"
          style={choicedType.includes(2) ? { opacity: '1' } : {}}
          onClick={() => handleFilterByType(2)}
        >
          Inpatient
        </li>
        <li
          className="categoryList__item --home"
          style={choicedType.includes(1) ? { opacity: '1' } : {}}
          onClick={() => handleFilterByType(1)}
        >
          Home
        </li>
        <li
          className="categoryList__item --careTransitions"
          style={choicedType.includes(3) ? { opacity: '1' } : {}}
          onClick={() => handleFilterByType(3)}
        >
          Care Transitions
        </li>
      </ul>

      <span className="checkAll">
        <Checkbox
          checked={allSelected()}
          onChange={checkAll}
        />
      </span>
    </div>
  );

  const headerGroup = <ColumnGroup>
    <Row>
      <Column colSpan={2} header={filters}></Column>
    </Row>
  </ColumnGroup>

  const templateEdit = (template: Template) => {

    const location = {
      pathname: Routes.CREATE_TEMPLATE,
      state: template
    };

    return (
      <>
      {userData?.option.permissions.create_edit_questionnaire ? 
      <div className={styles.patientControl}>
        <button
          className={`${styles.button} ${styles.button__copy}`}
          aria-label="Copy"
          data-tip
          data-for="copy"
          onClick={() => copyTemplate(template.id)}>
          <ReactTooltip id="copy" className="b-tooltip">
            Copy
          </ReactTooltip>
        </button>
        <button
          className={`${styles.button} ${styles.button__edit}`}
          aria-label="Edit"
          data-tip
          data-for="edit"
          onClick={() => history.push(location)}>
          <ReactTooltip id="edit" className="b-tooltip">
            Edit
          </ReactTooltip>
        </button>
        <button
          className={`${styles.button} ${styles.button__remove}`}
          aria-label="Remove"
          data-tip
          data-for="remove"
          onClick={() => setDeleteOneItem([template.id])}>
          <ReactTooltip id="remove" className="b-tooltip">
            Remove
          </ReactTooltip>
        </button>
        <Checkbox
          checked={arrayIdsForDelete.includes(template.id)}
          onChange={() => checkItem(template)}
        />
      </div>
      : null}
      </>
    );
  };
  const renderInfo = (template: Template) => {
    const avatar = template.title.split(' ').map((word)=>{return word[0]}).join('').substring(0, 2);
    const categoryClass = 'ava'+ template.type_title.split(' ').join('');
    
    return (
      <div className={styles.templateInfo}>
        <span className={`${styles.ava} ${styles[categoryClass]}`}>{avatar}</span>
        <div className={styles.templateInfo__holder}>
          <span className={styles.title}>{template.title}</span>
          <ul className={styles.detailsList}>
            <li>{template.category_title}</li>
            <li>{`${template.creator.first_name} ${template.creator.last_name}`}</li>
            <li>{template.date_of_creation}</li>
          </ul>
          <span className={`${styles.creator}`}>{`${template.creator.first_name} ${template.creator.last_name}`}</span>
        </div>
      </div>
    )
  };
  const footer = (
    <div className="patientControlList">
      {!userData?.option.permissions.create_edit_questionnaire ? 
        <ReactTooltip id='surveysListBtn' className='b-tooltipPermission'>
          {`You don't have permissions to manage Surveys`}
        </ReactTooltip> : null}

      <Link
      to={userData?.option.permissions.create_edit_questionnaire ? Routes.CREATE_TEMPLATE : '#'}
      className={`footerBtn ${userData?.option.permissions.create_edit_questionnaire ? '' :'disabled'}`}
      data-tip data-for='surveysListBtn'
      >
       create new survey</Link>
       
      {userData?.option.permissions.create_edit_questionnaire ? 
      <div className="patientControlList__innerWrapper">
        <Button
          title='delete'
          dataFor={'deleteToolTip'}
          className={'darkest -uppercase'}
          onPress={!arrayIdsForDelete.length ? (() => null) : handleModalOpen('delete-all')}/>
         {!arrayIdsForDelete.length ?
            <ReactTooltip  id='deleteToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
              Please select at least one survey to delete survey
            </ReactTooltip>
          : null}
      </div>
      : null}
    </div>
  );

  return (
  <>
    {templatesList ? (
    <div className={'b-mainContainer__wrapper'}>
      {/* {loading && <Loader fitParent={true}/>} */}
      <HeaderSearch
          pageName='Surveys'
          isSearhVisible={false}
      />
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={`b-mainContainer__contentWrapper ${styles.tableWrapper}`}>
          <DataTable headerColumnGroup={headerGroup}
            value={templatesList.data}
            className={`-paginator ${styles.templateTable}`}
            footer={footer}
            >
            <Column field='info' body={renderInfo}/>
            {userData?.option.permissions.create_edit_questionnaire ?
            <Column field='edit' body={templateEdit}/>
            : null}
          </DataTable>
          <Pagination
              displayTitle={'templates'}
              data={templatesList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
        </div>
      </div>
      {state.modalType === 'delete-all' && <Modal
          title='Delete Surveys'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to delete <br/>
            this survey(s)?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Yes'} onPress={() => deleteSelected(arrayIdsForDelete)} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {deleteOneItem.length ? <Modal
          title='Delete Survey'
          width={520}
          onClose={() => setDeleteOneItem([])}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to delete <br/>
            this survey?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Cancel'} onPress={() => setDeleteOneItem([])} type={'transparency'} width='100px'/>
            <Button title={'Yes'} onPress={() => deleteTemplate(deleteOneItem)} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> : null}
    </div>
    )
    : null }
    {state.modalType === 'permissions-fail' && <Modal
          title='Error'
          width={520}
          onClose={() => {
            setState({...state, modalType: null}),
            // setpermissionsError(''),
            history.push(Routes.DASHBOARD);
          }}>
          <div className={'b-modal'}>
            <div className={'b-modal__text'}>
              {permissionsError}
            </div>
          </div>
         </Modal>}
    </>
  );
};

export default TemplatesList;