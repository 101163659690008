import React, { FC, useEffect, useState } from 'react';
import { getQuestionTypes, getQuestionCategory, getTemplateCategory, getColorType, createTemplate, updateTemplate, sendPreviewSurvey } from '../../../store/templates/templatesSlice';
import { Options, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useDispatch } from 'react-redux';

import { Question, QuestionType, QuestionCategory, MainForm, Template, TemplateCategory, ColorType, PreviewTemplate, TemplatePreviewSaveModel, QuestionFormErrorsModel, QuestionOptions, QuestionParent, MainFormErrorsModel } from '../../../models/templates/templates.model';
import QuestionTypeItem from './parts/QuestionTypeItem/QuestionTypeItem';
import QuestionForm from './parts/QuestionForm/QuestionForm';
import { generateId, isEmptyObject } from '../../../helpers';
import CategoryItem from './parts/CategoryItem/CategoryItem';
import AddCategoryButton from './parts/AddCategoryButton/AddCategoryButton';
import MainCategory from './parts/MainCategory/MainCategory';
import { Routes } from '../../../routes/routes';
import { useHistory } from 'react-router-dom';
import TemplatePreviewContainer from './parts/TemplatePreview/TemplatePreviewContainer';
import Modal from '../../common/Modal/Modal';
import Button from '../../controls/Button/Button';
import ReactSpeedometer from 'react-d3-speedometer';
import { validate, weight } from '../../../helpers/validatior';
import { KeyValueModel } from '../../../models/key-value.model';
import addIcon from '../../../assets/images/icons/add_icon_darkest.svg'
import closeIcon from '../../../assets/images/icons/button_close_icon.svg';

import moment from 'moment';
import { useUnsavedChangesWarning } from '../../common';

interface IState {
  modalType: 'create-success' | 'update-success' | 'create-category' | null,
}

const initialState: IState = {
  modalType: null,
};

const questionFormValidatorScheme = {
  required: ['title'],
};

const questionFormOptionsValidatorScheme = {
  required: ['title'],
  custom: [weight(['weight'])],
};

const initialFormData = {
  answers: [],
}

const mainFormValidatorScheme = {
  required: ['title', 'initial'],
};

// const generator = generateId();

const CreateTemplateContainer: FC = () => {

  const questionTypes = useSelector((state: RootState) => state.templates.questionTypes);
  const questionCategory = useSelector((state: RootState) => state.templates.questionCategory);
  const colorType = useSelector((state: RootState) => state.templates.colorType);
  const templateCategory = useSelector((state: RootState) => state.templates.templateCategory);
  const surveyChart = useSelector((state: RootState) => state.templates.previewScore);

  const dispatch = useDispatch();
  const history = useHistory();
  let editableTemplate: Template = history.location.state;

  const [Prompt, setDirty, setClear] = useUnsavedChangesWarning();
  const [clearChangesWarning, setClearChangesWarning] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false);
  const [currentQuestions, setCurrentQuestions] = useState<Question[]>([]);
  const [categoryList, setCategoryList] = useState<QuestionCategory[]>([]);
  const [formPreview, setFormPreview] = useState<PreviewTemplate | null>(null);
  const [questionTypesOpen, setQuestionTypesOpen] = useState(false);
  const [errors, setErrors] = useState<QuestionFormErrorsModel>();
  const [errorsMainForm, setErrorsMainForm] = useState<MainFormErrorsModel>();

  const [mainForm, setMainForm] = useState<MainForm>(
    {
      type_color_id: 1,
      category_id: 1,
      title: '',
      initial: '',
      date_of_creation: moment(new Date()).format('YYYY-MM-DD'),
      fields: [] || null,
    }
  );

  let [state, setState] = useState<IState>(initialState);

  const [showScoreByType, setShowScoredByType] = useState(false);
  const [showDialByType, setShowDialByType] = useState(false);

  useEffect(() => {
    if (
      colorType?.filter((item) => item.title === mainForm.type_color_id)[0]?.alias ===
        'with_dial_and_score' ||
      colorType?.filter((item) => item.title === mainForm.type_color_id)[0]?.alias === 'with_score'
    ) {
      setShowScoredByType(true);
    } else {
      setShowScoredByType(false);
    }

    if (
      colorType?.filter((item) => item.title === mainForm.type_color_id)[0]?.alias ===
        'with_dial_and_score' ||
      colorType?.filter((item) => item.title === mainForm.type_color_id)[0]?.alias === 'with_dial'
    ) {
      setShowDialByType(true);
    } else {
      setShowDialByType(false);
    }

  }, [mainForm.type_color_id]);

  const successfulCategorySave = () => {
    setState({ ...state, modalType: 'create-category' });
  }
  
  const handleCloseModal = () => {
    setState({...state, modalType: null});
    // history.push(Routes.TEMPLATES_LIST)
  };

  useEffect(() => {
    setClearChangesWarning(false);
  }, [mainForm]);

  useEffect(() => {
    if (history.location.state) {
      let categoryListCopy = JSON.parse(JSON.stringify(editableTemplate.fields));

      setCategoryList(categoryListCopy)
      setMainForm(
        {
          type_color_id: editableTemplate.type_title || editableTemplate.type_color.title,
          category_id: editableTemplate.category_title || editableTemplate.category.title,
          title: editableTemplate.title,
          initial: editableTemplate.initial,
          // date_of_creation: moment(editableTemplate.date_of_creation).format('YYYY-MM-DD'),
          date_of_creation: editableTemplate.date_of_creation,
          fields: categoryListCopy,
        }
      )
    }
  }, [editableTemplate]);

  useEffect(() => {
    const inputErrorElements = document.getElementsByName('input_error');
    if (inputErrorElements.length) {
      const coord = inputErrorElements[0].getBoundingClientRect();
      window.scrollTo(0, coord.y);
    }
  }, [errorsMainForm]);

  useEffect(() => {
    if (history.location.state) {
      let categoryListCopy = JSON.parse(JSON.stringify(editableTemplate.fields));

      setCategoryList(categoryListCopy)
      setMainForm(
        {
          type_color_id: editableTemplate.type_title,
          category_id: editableTemplate.category_title,
          title: editableTemplate.title,
          initial: editableTemplate.initial,
          date_of_creation: moment(new Date(editableTemplate.date_of_creation.split('-').join('/'))).format('YYYY-MM-DD'),
          // date_of_creation: editableTemplate.date_of_creation,
          fields: categoryListCopy,
        }
      )
    }
    dispatch(getQuestionTypes());
    dispatch(getQuestionCategory());
    dispatch(getColorType());
    dispatch(getTemplateCategory());

    if (
      colorType?.filter((item) => item.id === mainForm.type_color_id)[0]?.alias ===
        'with_dial_and_score' ||
      colorType?.filter((item) => item.id === mainForm.type_color_id)[0]?.alias === 'with_score'
    ) {
      setShowScoredByType(true);
    }

    if (
      colorType?.filter((item) => item.id === mainForm.type_color_id)[0]?.alias ===
        'with_dial_and_score' ||
      colorType?.filter((item) => item.id === mainForm.type_color_id)[0]?.alias === 'with_dial'
    ) {
      setShowDialByType(true);
    }
  }, []);

  const moveItem = (item: QuestionType, categoryId: number) => {
    let categoryListCopy = [...categoryList];
    let findMaxId: number[] = [0];
    categoryListCopy.map((item) => item.items.map((elem) => findMaxId.push(elem.id)))
    const maxId = findMaxId.sort((a, b) => b - a)[0];

    let options, newOptions;
    if (item.field.type !== 'input') {
      options = [...item.field.options];
      newOptions = options.map((el) => {return {...el, subQuestions: [], subAnswer: false}})
    }

    let question: Question = {
      id: maxId + 1,
      nameOfType: item.field.title,
      // title: item.field.title,
      title: '',
      type: item.field.type,
      // parent: { parent_id: 1, option_id: 0 },
      options: item.field.type !== 'input' ? newOptions : [{id: 1, title: '1', weight: '0.01', subQuestions: [], subAnswer: false}],
      // answers: item.field.type !== 'input' ? [] : undefined,
      answers: [],
      category_id: categoryId,
      description: '',
    };
    if (item.field.type === 'input') {
      question = { ...question, textArea: '' }
    }

    // categoryListCopy.map((el) => (el.id === categoryId ? el.items.push(question) : el));
    // setCategoryList(categoryListCopy);

    openPopup(question);
  };

  const moveSubQuestion = (item: QuestionType, optionId: number, question: Question) => {
    let findMaxId: number[] = [0];

    question.options?.map((option) => option.id === optionId ? 
    option.subQuestions.map((el) => findMaxId.push(el.id))
    : option)

    const maxId = findMaxId.sort((a, b) => b - a)[0];

    let options, newOptions;
    if (item.field.type !== 'input') {
      options = [...item.field.options];
      newOptions = options.map((el) => {return {...el, subQuestions: [], subAnswer: false}})
    }
    let subQuestion: Question = {
      id: maxId + 1,
      nameOfType: item.field.title,
      // title: item.field.title,
      title: '',
      type: item.field.type,
      parent: { category_id: question.category_id, mainQuestion_id: question.id, option_id: optionId },
      options: item.field.type !== 'input' ? newOptions : [{id: 1, title: '1', weight: '0', subQuestions: [], subAnswer: false}],
      // answers: item.field.type !== 'input' ? [] : undefined,
      answers: [],
      category_id: question.category_id,
      description: '',
    };
    if (item.field.type === 'input') {
      subQuestion = { ...subQuestion, textArea: '' }
    }
    // optionCopy.push(subQuestion)

    // categoryListCopy = categoryListCopy.map((category) => (category.id === question.category_id ? {...category, items: [...category.items.map((item) => item.id === question.id ? {...item, options: [...item.options?.map((el) => el.id === optionId ? 
    //   {...el, subQuestions: optionCopy, subAnswer: true}
    //   : {...el, subAnswer: false})]} : item)]} : category));

    // const updatedCurrentQuestion: Question[] = []
    // categoryListCopy.map((category) => category.id === question.category_id ? category.items.map((item)=> updatedCurrentQuestion.push(item)) : category)
    let optionCopy: Question[] = [];
    question.options?.map((el) => (el.id === optionId ? optionCopy.push(...el.subQuestions) : el));

    let currentQuestionsCopy = [...currentQuestions];
    let questionCopy: Question = JSON.parse(JSON.stringify(question));
    const updatedOptions = question.options?.map((item) =>
      item.id === optionId ? { ...item, subQuestions: optionCopy, weight: '0' } : item,
    );
    questionCopy = { ...questionCopy, options: updatedOptions };
    currentQuestionsCopy[currentQuestionsCopy.length - 1] = questionCopy;

    setCurrentQuestions([...currentQuestionsCopy, subQuestion]);
  };

  const moveItemInAnotherCategory = (
    item: Question,
    toCategoryId: number,
    fromCategoryId: number,
  ) => {
    let categoryListCopy = [...categoryList];
    item.category_id = toCategoryId;

    categoryListCopy.map((el) => (el.id === toCategoryId ? el.items.push(item) : el));

    const startCategory = categoryListCopy.filter((elem) => elem.id === fromCategoryId);
    let arrayItems: Question[] = startCategory[0].items;
    arrayItems = arrayItems.filter((elem) => elem.id !== item.id);
    categoryListCopy = categoryListCopy.map((el) =>
      el.id === fromCategoryId ? { ...el, items: [...arrayItems] } : el,
    );

    setCategoryList(categoryListCopy);
  };

  const questionManipulate = (
    type: 'copy' | 'delete',
    questionId: number,
    categoryId: number,
  ): void => {
    closePopup();
    let categoryListCopy: QuestionCategory[];

    categoryListCopy = [...categoryList];
    const category = categoryList.filter((item) => item.id === categoryId);
    let arrayItems: Question[] = category[0].items;

    switch (type) {
      case 'copy': {
        break;
      }
      case 'delete': {
        arrayItems = arrayItems.filter((item) => item.id !== questionId);
        categoryListCopy = categoryListCopy.map((el) =>
          el.id === categoryId ? { ...el, items: [...arrayItems] } : el,
        );
        break;
      }
    }

    setCategoryList(categoryListCopy);
  };


  const moveCategoryHandler = (dragIndex: number, hoverIndex: number) => {
    const dragItem = categoryList[dragIndex];
    if (dragItem) {
      setCategoryList((prevState) => {
        const coppiedStateArray = [...prevState];
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);
        coppiedStateArray.map((item, index) => item.position = '' + (index + 1));
        return coppiedStateArray;
      });
    }
  };

  const moveItemHandler = (
    categoryData: Question[],
    dragIndex: number,
    hoverIndex: number,
    categoryId: number,
  ) => {
    closePopup();
    const dragItem = categoryData[dragIndex];
    const prevItem = categoryData.splice(hoverIndex, 1, dragItem);
    categoryData.splice(dragIndex, 1, prevItem[0]);

    let categoryListCopy: QuestionCategory[];
    categoryListCopy = [...categoryList];

    let i = 0;
    for (let idx in categoryListCopy) {
      if (categoryListCopy[idx].id === categoryId) {
        categoryListCopy[idx].items = categoryData;
        i = i + 1;
      } else {
        categoryListCopy[idx];
      }
    }

    return setCategoryList(categoryListCopy);
  };

  const deleteCategoryItem = (categoryId: number) => {
    closePopup();

    const categoryListCopy = categoryList.filter((item) => item.id !== categoryId);
    setCategoryList(categoryListCopy);
  };

  const openPopup = (data: Question, prevData?: Question) => {
    // if (currentQuestion === data) {
    //   setCurrentQuestion(null);
    // } else {
    if (prevData) {
      setCurrentQuestions([prevData, data]);
    } else {
      let currentQuestionCopy: Question[] = [...currentQuestions];
      currentQuestionCopy.push(data);
      setCurrentQuestions(currentQuestionCopy);
    }
    setErrors(undefined);
  };

  // const getPosition = () => {
  //   let posTop = window.pageYOffset;
  //   const findQuestion = document.getElementById(`question${currentQuestions[currentQuestions.length - 1]?.id}`);
  //   const coord = findQuestion?.getBoundingClientRect();

  //   if (coord?.y) {
  //     return coord?.y + posTop
  //   } else return 0;
  // }

  const closePopup = () => {
    let currentQuestionCopy: Question[] = [...currentQuestions];
      currentQuestionCopy.pop();
      setCurrentQuestions(currentQuestionCopy);
  };

  const collectFields = (id: number, categoryQuestions: Question[]) => {
    const currentCategoryIndex = categoryList?.findIndex((category) => category.id === id);

    const updateCategory = (list: QuestionCategory[] | null) => {
      const newCategory = JSON.parse(JSON.stringify(list));
      if (currentCategoryIndex !== undefined) {
        newCategory[currentCategoryIndex].items = categoryQuestions;
      }
      return newCategory;
    };

    setCategoryList(updateCategory(categoryList));
    setFormData({ ...formData, answers: updateCategory(categoryList) });
  };

  const disabled = (formData: TemplatePreviewSaveModel) => {
    let disabled = false;
    formData.answers.map((category) => {
      if (!category.items?.length) {
        return (disabled = true);
      } else {
        for (let i = 0; i < category.items.length; i++) {
          if (!category.items[i].answers?.length) {
            return (disabled = true);
          }
        }
      }
    });

    return disabled;
  };

  const saveSurveyData = (e: Event, data: TemplatePreviewSaveModel) => {
    e.preventDefault();
    const sendData = {
      ...data,
    };
    dispatch(sendPreviewSurvey(sendData));
  };

  const saveForm = (form: Question) => {
    const { errors: questionFormErrors } = validate(questionFormValidatorScheme, form);

    const questionFormOptionsErrors: KeyValueModel<string>[] = [];
    if (form.options && form.type !== 'input') {
      form.options.map((item) => {
        const { errors: value } = validate(questionFormOptionsValidatorScheme, item);
        questionFormOptionsErrors.push(value);
      });
    }
    setErrors({
      questionForm: questionFormErrors,
      questionFormOptions: questionFormOptionsErrors,
    });

    if (
      isEmptyObject(questionFormErrors) &&
      !questionFormOptionsErrors.map((item) => isEmptyObject(item)).includes(false)
    ) {
      if (form.parent?.category_id) {
        // let categoryListCopy = categoryList.map((category) => (category.id === form.parent?.category_id ?
        //    {...category, items: [...category.items.map((item) => item.id === form.parent?.mainQuestion_id ?
        //     {...item, options: [...item.options?.map((el) => el.id === form.parent?.option_id ?
        //     {...el, weight: Array.isArray(el.weight) ? [...el.weight.map((el) => el.id === form.id ?
        //       {...form}
        //       : el)] : el.weight}
        //       : el)]} : item)]} : category));

        let currentQuestionCopy: Question[] = [...currentQuestions];
        let previousForm: Question = currentQuestionCopy[currentQuestionCopy.length - 2];
        let previousFormCurrentOption = previousForm.options?.filter(
          (item) => item.id === form.parent?.option_id,
        );
        let hasSubQuestions = previousFormCurrentOption && previousFormCurrentOption[0].subQuestions.filter(
          (el) => el.id === form.id,
        );
        let arrayItems: QuestionOptions[] | undefined;

        if (hasSubQuestions?.length) {
          arrayItems = previousForm.options?.map((item) =>
            item.id === form.parent?.option_id
              ? {
                  ...item,
                  subQuestions: [
                    ...item.subQuestions.map((el) => (el.id === form.id ? { ...form } : el)),
                  ],
                }
              : item,
          );
        } else if (previousFormCurrentOption?.length && !!hasSubQuestions?.length === false) {
          arrayItems = previousForm.options?.map((item) =>
            item.id === form.parent?.option_id
              ? { ...item, subQuestions: [...item.subQuestions, form] }
              : item,
          );
        } else {
          arrayItems = previousForm.options?.map((item) =>
            item.id === form.parent?.option_id ? { ...item, subQuestions: [form] } : item,
          );
        }

        currentQuestionCopy = currentQuestionCopy.map((item) =>
          item.id === previousForm.id ? { ...item, options: arrayItems } : item,
        );
        currentQuestionCopy.pop();

        setCurrentQuestions(currentQuestionCopy);
      } else {
        let categoryListCopy = categoryList.filter((item) => item.id === form.category_id);
        let arrayItems: Question[] = categoryListCopy[0].items;

        let hasQuestion = arrayItems.filter((elem) => elem.id === form.id);

        if (hasQuestion.length) {
          arrayItems = arrayItems.map((elem) => (elem.id === form.id ? { ...form } : elem));
        } else {
          arrayItems.push(form);
        }

        categoryListCopy = categoryList.map((el) =>
          el.id === form.category_id ? { ...el, items: [...arrayItems] } : el,
        );

        setCategoryList(categoryListCopy);
        closePopup();
      }
      setErrors(undefined);
      setDirty();
    }
  };

  const saveTemplate = (mainForm: MainForm) => {
    closePopup();

    const { errors: mainFormErrors } = validate(mainFormValidatorScheme, mainForm);
    setErrorsMainForm({
      mainForm: mainFormErrors
    });

    if (
      isEmptyObject(mainFormErrors)
    ) {
      if (templateCategory && colorType) {
        let findedCategory: TemplateCategory;
        let findedType: ColorType;

        findedCategory = templateCategory.filter((item) => item.title === mainForm.category_id)[0]
        findedType = colorType.filter((item) => item.title === mainForm.type_color_id)[0]

        const newCategoryList = JSON.parse(JSON.stringify(categoryList), (key, value) => key === 'textArea' ? '' : key === 'answers' ? [] : value && key === 'weight' ? +value : value);

        const template: MainForm = {
          type_color_id: findedType?.id || colorType[colorType.length - 1].id,
          category_id: findedCategory?.id || templateCategory[templateCategory.length - 1].id,
          title: mainForm.title,
          initial: mainForm.initial,
          date_of_creation: mainForm.date_of_creation,
          fields: newCategoryList,
        };

        if (history.location.state) {
          dispatch(
            updateTemplate({
              data: {
                ...template,
                id: '' + editableTemplate.id,
              },
              callback: () => {
                setState({ ...state, modalType: 'update-success' });
                setClear();
                setClearChangesWarning(true);
              },
            }),
          );
        } else {
          dispatch(
            createTemplate({
              data: template,
              callback: (response) => {
                setState({ ...state, modalType: 'create-success' });
                setClear();
                setClearChangesWarning(true);
                if (response) {
                  history.location.state = response;
                  editableTemplate = response;
                }
              },
            }),
          );
        }
        //  setErrors(undefined);
        setMainForm(template);
      }
    }
  };

  const templateForPreview = (mainForm: MainForm) => {
    if (templateCategory && colorType) {
      let findedCategory: TemplateCategory;
      let findedType: ColorType;

      findedCategory = templateCategory.filter((item) => item.title === mainForm.category_id || item.id === mainForm.category_id)[0];
      findedType = colorType.filter((item) => item.title === mainForm.type_color_id || item.id === mainForm.type_color_id)[0];

      const template: PreviewTemplate = {
        type_color: findedType,
        category: findedCategory,
        title: mainForm.title,
        initial: mainForm.initial,
        date_of_creation: mainForm.date_of_creation,
        fields: [...categoryList],
      };
      setFormPreview({ ...template });
      setPreviewIsOpen(true);
    }
  };

  return (
    <>
      {previewIsOpen ? (
        <TemplatePreviewContainer
          template={formPreview ? formPreview : null}
          setPreviewIsOpen={setPreviewIsOpen}
        />
      ) : (
        <>
          {colorType && templateCategory ? (
            <MainCategory
              colorType={colorType}
              mainForm={mainForm}
              templateCategory={templateCategory}
              setMainForm={setMainForm}
              successfulCategorySave={successfulCategorySave}
              errorsMainForm={errorsMainForm}
              clearChangesWarning={clearChangesWarning}
            />
          ) : null}
          {questionTypes ? (
            <>
            <div className="b-mainContainer__wrapperInner">
              <div className="templates__box">
                <div className="templates__leftBox">
                  <div className="templates__title">Question</div>
                  <div className="templates__holder">
                    {categoryList.length > 0
                      ? categoryList.map((category, index) => (
                        <CategoryItem
                          key={`categoryItem - ${category.id}`}
                          moveCategoryHandler={moveCategoryHandler}
                          index={index}
                          title={category.title}
                          categoryId={category.id}
                          deleteCategoryItem={deleteCategoryItem}
                          categoryItems={category.items}
                          openPopup={openPopup}
                          questionManipulate={questionManipulate}
                          moveItemInAnotherCategory={moveItemInAnotherCategory}
                          moveItemHandler={moveItemHandler}
                          closePopup={closePopup}
                          collectFields={collectFields}
                        />
                      ))
                      : null}
                    <AddCategoryButton
                      questionCategory={questionCategory}
                      categoryList={categoryList}
                      setCategoryList={setCategoryList}
                    />
                    {currentQuestions.length ? (
                      <QuestionForm
                        style={{ top: `${window.pageYOffset < 500 ? '15' : `${window.pageYOffset-431}`}px` }}
                        categoryList={categoryList}
                        data={currentQuestions[currentQuestions.length - 1]}
                        openPopup={openPopup}
                        closePopup={closePopup}
                        saveForm={saveForm}
                        errors={errors}
                      />
                    ) : null}
                    {categoryList.length > 0 ? (
                      <div className="templates__buttonBox">
                      <Button
                        title={`${editableTemplate ? 'Update Survey' : 'Create Survey'}`}
                        width={'auto'}
                        className={'-uppercase'}
                        onPress={() => saveTemplate(mainForm)}
                      />
                      <Button
                        title={'Preview'}
                        width={'auto'}
                        className={'-uppercase'}
                        onPress={() => templateForPreview(mainForm)}
                      />
                      </div>
                    ) : null}
                    
                    {showScoreByType || showDialByType && categoryList.length > 0 ? (

                      <div className="b-surveyForm__block">
                      <div className="b-surveyForm__buttons">
                            <Button
                                title={'Check Assessment'}
                                disabled={disabled(formData)}
                                className={'-uppercase'}
                                onPress={(e: Event) => saveSurveyData(e, formData)}
                            />
                              </div>
                                  <h2 className="b-surveyForm__tilte">Assessment</h2>
                                  <div className="b-surveyForm__chart">
                                  {/* {surveyChart ? */}
                                  {/* <div className="b-surveyForm__chart__sideBlock -chartCreateSurveyPage"> */}
                                      <div className="b-surveyForm__chart__sideBlock -chart">
                                        <div className="b-surveyForm__chart__chartHolder">
                                          <div className="b-surveyForm__chart__chartFrame">
                                              <ReactSpeedometer
                                                  width={415}
                                                  height={250}
                                                  minValue={0}
                                                  maxValue={100}
                                                  fluidWidth={true}
                                                  needleHeightRatio={0.42}
                                                  ringWidth={25}
                                                  value={showScoreByType ? surveyChart?.score || 0 : 0}
                                                  maxSegmentLabels={3}
                                                  segments={3}
                                                  customSegmentStops={[0,17,32,100]}
                                                  currentValueText={' '}
                                                  // customSegmentLabels={[
                                                  //     {
                                                  //         text: "",
                                                  //     },
                                                  //     {
                                                  //         text: "",
                                                  //     },
                                                  //     {
                                                  //         text: "",
                                                  //     },
                                                  // ]}
                                                  segmentColors={[
                                                      "#6FDE47",
                                                      "#E5D012",
                                                      "#DB4E6F",
                                                  ]}
                                                  needleColor="#515151"
                                              />
                                              {/* <div className={`score-containerCreateSurveyPage`}> */}
                                              <div className={`score-container`}>
                                                  <div className={`score`}>
                                                      <span>{showScoreByType ? surveyChart?.score && Math.round(surveyChart?.score) || 0 : 0}</span>
                                                  </div>
                                              </div>
                                              <div className={`score-level-container`}>
                                                  <div className={'score-level'}>
                                                      <span>{showScoreByType ? surveyChart?.score_level && `${surveyChart?.score_level} Risk` : ''}</span>
                                                  </div>
                                              </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="b-surveyForm__chart__sideBlock -chartDetails">
                                          <h2 className="b-surveyForm__tilte">Contributing Factors</h2>
                                          <div className={"b-surveyForm__chart__factors"}>
                                              {showScoreByType && surveyChart?.category_score_level ?
                                                surveyChart.category_score_level.map((category,index) => {
                                                      return (
                                                          <div key={index} className={`factor ${category.score_level.toLowerCase()}`}>
                                                              <span className={"factor-text"}>{category.title}</span>
                                                          </div>
                                                      )
                                                  })
                                              : null}
                                          </div>
                                      </div>
                                  </div>
                                  <ul className="riskList">
                                      <li
                                      className="riskList__item --high"
                                      >
                                      High risk
                                      </li>
                                      <li
                                      className="riskList__item --medium"
                                      >
                                      Medium risk
                                      </li>
                                      <li
                                      className="riskList__item --low"
                                      >
                                      Low risk
                                      </li>
                                  </ul>
                              </div>
                    ) : null }
                  </div>
                </div>

               <div className="templates__rightBox">

                 {!questionTypesOpen && 
               <div className="fixed">
               <Button
                    title={'Add a question'}
                    type={'white'}
                    className={'-d-flex -br-15'}
                    width={'180px'}
                    iconLeft={<img src={addIcon} />}
                    onPress={() => setQuestionTypesOpen(true)} />
                    </div>}
               {questionTypesOpen ? 
              <div className="fixed">
                <>
                  <div className="templates__questionTypeBox">
                    <div className="templates__title">Questions types</div>
                    <div className="templates__infoType">Drag and drop questions type to left side to build your template</div>
                    <div className="templates__list">
                      {questionTypes?.map((item) => (
                        <QuestionTypeItem
                          data={item}
                          key={item.id}
                          moveItem={moveItem}
                          moveSubQuestion={moveSubQuestion}
                        />
                      ))}
                    </div>
                  </div>
                  <Button
                    title={''}
                    type={'primary'}
                    className={'darkest -d-flex -br-15 -ml-15'}
                    width={'40px'}
                    iconLeft={<img width={'15px'} height={'15px'} src={closeIcon} style={{ marginRight: '-10px' }} />}
                    onPress={() => setQuestionTypesOpen(false)} />
                </>
              </div>
                : null}
                </div>
                
              </div>
            </div>
            {state.modalType === 'create-success' && <Modal
              title='Success'
              width={520}
              onClose={handleCloseModal}>
              <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                  Survey<i><b>&nbsp;“{mainForm.title}” </b></i>&nbsp; created
                </div>
              </div>
            </Modal>}
            {state.modalType === 'update-success' && <Modal
              title='Success'
              width={520}
              onClose={handleCloseModal}>
              <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                  Survey<i><b>&nbsp;“{mainForm.title}” </b></i>&nbsp; updated
                </div>
              </div>
            </Modal>}
            {state.modalType === 'create-category' && <Modal
              title='Success'
              width={520}
              onClose={handleCloseModal}>
              <div className={'b-modal'}>
                <div className={'b-modal__text'}>
                  Category<i><b>&nbsp;{templateCategory?.length ? `“${templateCategory[0].title}”` : ''} </b></i>&nbsp; created
                </div>
              </div>
            </Modal>}
            </>
          ) : null}
        </>
      )}
      {Prompt}
    </>
  );
};

export default CreateTemplateContainer;
