import React, {FC, useEffect, useState} from 'react';
import {useHistory, Link, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import ReactTooltip from 'react-tooltip';


import AvixenaLogo from "../../../assets/images/icons/avixenalogo.svg";

import notificationsIcon from "../../../assets/images/icons/notifications.svg";
import settingsIcon from "../../../assets/images/icons/settings.svg";
import logoutIcon from "../../../assets/images/icons/logout.svg";
import dashboardIcon from "../../../assets/images/icons/dashboard.svg";
import patientsIcon from "../../../assets/images/icons/patients.svg";
import manageUsersIcon from "../../../assets/images/icons/manage-users.svg";
import reportsIcon from "../../../assets/images/icons/reports.svg";
import templatesIcon from "../../../assets/images/icons/templates.svg";
import Modal from "../../common/Modal/Modal";
import Button from "../../controls/Button/Button";

import './SideBarStyles.scss'

import {getUserDetails, logout} from "../../../store/user/userSlice";
import {Routes} from "../../../routes/routes";
import { RootState } from '../../../store/reducers';
import {API_URI} from "../../../constants";
import styles from "../../pages/Settings/SettingsView.module.scss";
import profileImg from "../../../assets/images/icons/profile_img_default.svg";

interface IState {
  modalType: 'logout' | null,
}

const initialState: IState = {
  modalType: null,
};

const SideBarView: FC = () => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { pathname } = location;
  const [isOpen, setIsOpen] = useState(false);
  const [desktopBurgerIsOpen, setDesktopBurgerIsOpen] = useState(false);
  let [state, setState] = useState<IState>(initialState);

  const handleModalOpen = (type: 'logout' | null) => () => {
    setState({ ...state, modalType: type });
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null });
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    history.push(Routes.SIGNIN);
  };

    return (
      userData &&
      <>
      <button type="button" className={`navOpenerDesktop ${desktopBurgerIsOpen ? 'navOpenerDesktop--active' : ''}`} onClick={()=>setDesktopBurgerIsOpen(!desktopBurgerIsOpen)}><span></span></button>
      <div className={`sideBar ${desktopBurgerIsOpen ? 'sideBar--active' : ''}`}>
          <div className={'sideBar__inner'}>

            <div className="sideBar__mobileHeading">
              <img src={AvixenaLogo} className={'sideBar__logo'}/>
              <button type="button" className={`navOpener ${isOpen ? 'navOpener--active' : ''}`} onClick={()=>setIsOpen(!isOpen)}><span></span></button>
            </div>

            <div className={`sideBar__mobileDropdown ${isOpen ? 'sideBar__mobileDropdown--active' : ''}`}>
              {userData?.profile.photos.length
                ?  <img
                      src={`${API_URI}file/get-file/${userData.profile.photos[0].details.name}`}
                      alt={'Avatar'}
                      className={'sideBar__avatar'}
                  />
                :  <div className={'sideBar__avatar'}/>}
              <div className={'sideBar__username'}>
                <span>{userData?.user.name}</span>
              </div>
              <div className={'sideBar__userrole'}>
                <span>{userData?.role.name.toUpperCase()}</span>
              </div>
              <div className={'sideBar__settings'}>
                <Link to={Routes.SETTINGS} onClick={() => setIsOpen(false)} className={`sideBar__settingsButton ${pathname === Routes.SETTINGS ? "-active" : ""}`}>
                  <img src={settingsIcon} className={'sideBar__icon'}/>
                </Link>
                <div className={'sideBar__settingsButton'}>
                  <img src={logoutIcon} className={'sideBar__icon'} onClick={handleModalOpen('logout')} />
                </div>
              </div>
              <div className={'sideBar__tabsContainer'}>
                <Link to={Routes.DASHBOARD} className={`sideBar__tab ${pathname === Routes.DASHBOARD || pathname === Routes.QUESTIONNAIRES ? "-active" : ""}`} onClick={() => {setIsOpen(false)}}>
                  <img src={dashboardIcon} width="24"/>
                  <span className={'sideBar__tabText'}>DASHBOARD</span>
                </Link>
                <Link to={userData.option.permissions.view_patient_list ? Routes.PATIENTS_LIST : '#'} className={`sideBar__tab ${pathname === Routes.PATIENTS_LIST || pathname === Routes.PATIENTS_ADD  ? "-active" : ""}`} onClick={() => {userData.option.permissions.view_patient_list ? setIsOpen(false) : null}}
                data-tip data-for='patientsList'
                >
                  {!userData.option.permissions.view_patient_list ?
                  <ReactTooltip id='patientsList' className='b-tooltipPermission'>
                    {`You don't have permissions to manage Patients`}
                  </ReactTooltip> : null}
                  <img src={patientsIcon} width="20"/>
                  <span className={'sideBar__tabText'}>PATIENTS</span>
                </Link>
                <Link to={userData.option.permissions.view_manage_user_list ? Routes.MANAGE_USERS_LIST : '#'} className={`sideBar__tab ${pathname === Routes.MANAGE_USERS_LIST || pathname === Routes.MANAGE_USERS_ADD  ? "-active" : ""}`} onClick={() => {userData.option.permissions.view_manage_user_list ? setIsOpen(false) : null}}
                data-tip data-for='manageUsersList'
                >
                  {!userData.option.permissions.view_manage_user_list ?
                  <ReactTooltip id='manageUsersList' className='b-tooltipPermission'>
                    {`You don't have permissions to manage system users`}
                  </ReactTooltip> : null}
                  <img src={manageUsersIcon} width="24"/>
                  <span className={'sideBar__tabText'}>MANAGE USERS</span>
                </Link>
                <Link to={userData.option.permissions.view_reports ? Routes.REPORTS : '#'} className={`sideBar__tab ${pathname === Routes.REPORTS ? "-active" : ""}`} onClick={() => {userData.option.permissions.view_reports ? setIsOpen(false) : null}}
                data-tip data-for='reports'
                >
                  {!userData.option.permissions.view_reports ?
                  <ReactTooltip id='reports' className='b-tooltipPermission'>
                    {`You don't have permissions to manage Reports`}
                  </ReactTooltip> : null}
                  <img src={reportsIcon}/>
                  <span className={'sideBar__tabText'}>REPORTS</span>
                </Link>
                <div data-tip data-for='surveysList'>
                <Link
                    to={userData.option.permissions.view_questionnaire_list ? Routes.TEMPLATES_LIST : '#'} className={`sideBar__tab ${pathname === Routes.TEMPLATES_LIST ||  pathname === Routes.CREATE_TEMPLATE ? "-active" : ""}`} onClick={() => {userData.option.permissions.view_questionnaire_list ? setIsOpen(false) : null}}
                
                >
                  {!userData.option.permissions.view_questionnaire_list ?
                  <ReactTooltip id='surveysList' className='b-tooltipPermission'>
                    {`You don't have permissions to manage Surveys`}
                  </ReactTooltip> : null}
                  <img src={templatesIcon} width="20"/>
                  <span className={'sideBar__tabText'}> SURVEYS</span>
                </Link>
                </div>
              </div>
              <Link to={Routes.CLINICAL_PRACTICE_GUIDES} className={'sideBar__guidesBtn'} onClick={() => setIsOpen(false)}>CLINICAL PRACTICE GUIDES</Link>
              {/* <Link to={Routes.MANAGE_USERS_ADD} className={styles.footerBtn}>add new User</Link> */}
            </div>

          </div>
      </div>
      {state.modalType === 'logout' && <Modal
          title='Logout'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to log out now?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Yes'} onPress={handleLogout} width='100px'/>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      </>
  );
};

export default SideBarView;
