import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import user from './user/userSlice';
import templates from './templates/templatesSlice';
import survey from './survey/surveySlice';
import patients from './patients/patientsSlice';
import report from './reports/reportSlice';
import manageUsers from './manageUsers/manageUsersSlice';
import hccCodes from './hccCodes/hccCodesSlice';

export const combinedReducers = combineReducers({
  user,
  templates,
  survey,
  patients,
  report,
  manageUsers,
  hccCodes
});

export type RootState = ReturnType<typeof combinedReducers>;

export const rootReducer: Reducer = (
    state: RootState,
    action: AnyAction
): RootState => {
  return combinedReducers(state, action);
};

