import React, {FC, useState} from 'react';
import {Button, Input, Loader, Select} from '../../../controls';
import {validate, email, password} from '../../../../helpers/validatior';
import Modal from "../../../common/Modal/Modal"

import {useDispatch} from "react-redux";
import {KeyValueModel} from "../../../../models";
import {isEmptyObject} from "../../../../helpers";
import {login} from "../../../../store/user/userSlice";
import UserIc from '../../../../assets/images/icons/user.svg'
import PswdIc from '../../../../assets/images/icons/pswd_ic.svg'
import AuthJpg from '../../../../assets/images/auth-background.jpg'
import AvixenaLogo from "../../../../assets/images/icons/avixenalogo.svg";

interface IProps {

}

interface IState {
  modalType: 'permissions-fail' | null,
}

const initialState: IState = {
  modalType: null,
};

interface IFields {
  email: string,
  password: string,
}

const validatorScheme = {
  required: ['email', 'password'],
  custom: [
    email(['email']), password(['password'])
  ],
};

const SignInContainer: FC<IProps> = () => {
  const dispatch = useDispatch();

  const [fields, setFields] = useState<IFields>({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState<KeyValueModel<string | boolean>>({});
  let [state, setState] = useState<IState>(initialState);
  const [permissionsError, setpermissionsError] = useState<string>('Your account was deactivated, please connect to admin');

  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (fieldName: string) => (value: string) => {
    setFields({
        ...fields,
      [fieldName]: value
    });
    setErrors({});
  };

  const handleSubmit = () => {
    const {errors} = validate(validatorScheme, fields);
    setErrors(errors);
    if (isEmptyObject(errors)) {
      setLoading(true);
      dispatch(login({
        ...fields,
        callback: (errors) => {
          if (errors) {
            setErrors({...errors, email: 'The selected email is invalid'});
          }
          if (errors.status == '403') {
            setState({...state, modalType: 'permissions-fail'});
            // setpermissionsError(errors.data.message)
          }
          setLoading(false);
        }
      }));
    }
  };

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]

  return (
    <>
      <div className='auth__page'>
        {loading && <Loader/>}
        <img src={AvixenaLogo} style={{marginBottom:'30px'}}/>
        <div className='auth__form'>
          <div className='auth__textWrapper'>
            <div className='auth__title'>Good morning</div>
            <div className='auth__text'>
            avixena Population Health Solutions
            </div>
            <div className='auth__titleLine'/>
          </div>
          <div className='auth__input'>
            <Input
                height={50}
                label={'Email:'}
                classLabelName={'-authStyle'}
                onChange={handleChange('email')}
                value={fields.email}
                error={errors.email}
                leftIcon={<img src={UserIc} alt=""/>}
            />
          </div>
          <div className='auth__input' style={{marginBottom: '30px'}}>
            <Input
                height={50}
                label={'Password:'}
                classLabelName={'-authStyle'}
                onChange={handleChange('password')}
                value={fields.password}
                type={'password'}
                error={errors.password}
                leftIcon={<img src={PswdIc} alt=""/>}
            />
          </div>
          <Button
              height={50}
              onPress={handleSubmit}
              title={'Sign In'}
          />
          <a href="/forgotpass" className='auth__forgot b-link'>
            Forgot Your Password?
          </a>
        </div>
      </div>
      {state.modalType === 'permissions-fail' && <Modal
       title='Error'
       width={520}
       onClose={() => setState({...state, modalType: null})}>
       <div className={'b-modal'}>
         <div className={'b-modal__text'}>
           {permissionsError}
         </div>
       </div>
      </Modal>}
      </>
  )
};

export default SignInContainer;
