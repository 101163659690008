import React, {useState, FC, useEffect} from 'react';
import axios from 'axios';
import styles from "../SettingsView.module.scss";
import profileImg from "../../../../assets/images/icons/profile_img_default.svg";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import {API_URI} from "../../../../constants";
import {IFileTemp} from "../../../../models";

interface IProps {
  img: IFileTemp,
  onChange: (file: IFileTemp) => void
}

const Avatar: FC<IProps> = ({img, onChange}) => {
  const user = useSelector((state: RootState) => state.user);
  let [file, setFile] = useState<IFileTemp | null>(null);

  useEffect(() => {
    if (img) {
      setFile(img)
    }
  }, [img]);

  const uploadFile = async (
      file: Blob,
      file_type?: string,
      marker?: string
  ): Promise<any> => {
    const token = user.access_token;

    const formData = new FormData();
    formData.append('file', file);
    file_type && formData.append('file_type', file_type);
    marker && formData.append('marker', marker);

    let headers: object = {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
      'Cache-Control': 'no-cache',
      Authorization: `Bearer ${token}`,
    };

    return axios.post(`${API_URI}file/upload-file`, formData, { headers });
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList?.length) {
      const res = await uploadFile(fileList[0]);
      if (res && res.status === 200) {
        onChange(res.data.data.file);
      }
    }
  };

  return (
      <div className={styles.profileImg}>
        {file
          ?  <img className={styles.profileImg__img} src={`${API_URI}file/get-file/${file.name}`} alt="avatar"/>
          :  <div className={styles.profileImg__imgWrapper}>
              <img className={styles.profileImg__ic} src={profileImg} alt=""/>
            </div>}

        <label className={styles.profileImg__edit}>
          <input
              type="file"
              accept="image/png,image/jpeg,image/gif,image/jpg,image/pjpeg"
              onChange={handleChange}
          />
        </label>
      </div>
  );
};

export default Avatar;
