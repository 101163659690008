import React, {CSSProperties} from 'react';
import './LoaderStyles.css';

type LoaderProps = {
    fitParent?: boolean;
    containerStyle?: CSSProperties;
};

const Loader = ({
                    fitParent,
                    containerStyle,
                }: LoaderProps): JSX.Element => {
    return (
        <div
            className="b-loader-container"
            style={
                fitParent
                    ? {width: '100%', height: '100%', top: '0'}
                    : containerStyle
                        ? {...containerStyle}
                        : {}
            }
        >
            <div className="b-loader"></div>
        </div>
    )
};

export default Loader
