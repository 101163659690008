import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import questionnaires_filled from "../../../../assets/images/questionnaires_filled.jpg";
import questionnaires_sent from "../../../../assets/images/questionnaires_sent.jpg";

import { Loader, Select } from '../../../controls';
import { RootState } from '../../../../store/reducers';
import { getChartData } from '../../../../store/survey/surveySlice';
import { ColorType, ISelectOptions, Template } from '../../../../models';
import { getTemplates } from '../../../../store/templates/templatesSlice';

Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale, Legend);

type IProps = {
  colorType: ColorType[];
  _generateTypeListForSelect: (typeList: ColorType[]) => void;
}

interface Filter {
      template_id: undefined | number,
      template_type_color_id: undefined | number,
      period: string,
}

const ChartComponent: FC<IProps> = ({ colorType }) => {
  const chartData = useSelector((state: RootState) => state.survey.chartData);
  const templates = useSelector((state: RootState) => state.templates.templates);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [typesForSelect, setTypesForSelect] = useState<ISelectOptions[]>();
  
  const [selectedTypeTemplates, setSelectedTypeTemplates] = useState<ISelectOptions[]>();
  const [selectedType, setSelectedType] = useState<ISelectOptions>({label: 'All Types', value: 0});
  
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectOptions>({label:'All Surveys', value: ''});
  
  const [filterChartData, setFilterChartData] = useState<Filter>(
  {
    template_id: undefined,
    template_type_color_id: undefined,
    period: "6-month",
  });

  useEffect(() => {
    setLoading(true);
    dispatch(
      getChartData({
        data: filterChartData,
        callback: () => {
          setLoading(false);
        },
      }),
    );
  }, [filterChartData]);

  useEffect(() => {
    dispatch(getTemplates({ data: {
      filtering: {
        type_colors: {
        }
      },
      as_array: 1
    }
  }));
    if(colorType && colorType[0]) {
      const selectTypes = colorType.map(type => {
        return {
          label: type.title,
          value: type.id,
        }
      })
      selectTypes.unshift({label: 'All Types', value: 0})
      setTypesForSelect(selectTypes);
    }
   
  },[colorType])

  useEffect(() => {
    if (templates) {
      const formattedTemplates = Object.entries(templates).map( template => {
            return {
              label: template[1],
              value: template[0],
            }
        }
      )
      formattedTemplates.unshift({label: 'All Surveys', value: ''})
      setSelectedTypeTemplates(formattedTemplates)
    }
  }, [templates])

  const handleTypeChange = (value: ISelectOptions) => {
    setSelectedType(value);
    setSelectedTemplate({ label: 'All Surveys', value: '' });
    setFilterChartData({
      ...filterChartData,
      template_type_color_id: +value.value !== 0 ? +value.value : undefined,
      template_id: undefined,
    });

    if (+value.value !== 0) {
      dispatch(
        getTemplates({
          data: {
            filtering: {
              type_colors: {
                id: [+value.value],
              },
            },
            as_array: 1,
          },
        }),
      );
    } else {
      dispatch(
        getTemplates({
          data: {
            filtering: {
              type_colors: {},
            },
            as_array: 1,
          },
        }),
      );
    }
  };

  const handleTemplateChange = (value: ISelectOptions) => {
    setSelectedTemplate(value)
    setFilterChartData({ ...filterChartData, template_id: +value.value !== 0 ? +value.value : undefined})
    }

  const handlePeriodChange = (value: string) => {
    // setSelectedTemplate(value)
    setFilterChartData({ ...filterChartData, period: value})
    }

  let arrayStaffData: number[] = [];
  let arrayPatientsData: number[] = [];
  let selectedPeriod: string[] = [];

  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  if (chartData) {
    if (
      filterChartData.period === 'year' ||
      filterChartData.period === '6-month' ||
      filterChartData.period === '3-month'
    ) {
      const currentMonth = new Date().getMonth();
      for (let i = currentMonth; i >= 0; i--) {
        if (selectedPeriod.length < 12) {
          selectedPeriod.unshift(month[i]);
          if (i === 0) {
            i = 12;
          }
        }
      }
      selectedPeriod = selectedPeriod.filter((item) => item in chartData.staff);
      selectedPeriod.map((item) => {
        if (item in chartData.staff) {
          arrayStaffData.push(chartData.staff[item]);
          arrayPatientsData.push(chartData.patient[item]);
        }
      });
    }

    if (
      filterChartData.period === 'month' ||
      filterChartData.period === 'week' ||
      filterChartData.period === 'all-time'
    ) {
      for (let key in chartData.staff) {
        // let str = key.split('/')[1]
        selectedPeriod.push(key);
      }
      selectedPeriod.map((item) => {
        {
          arrayStaffData.push(chartData.staff[item] || 0);
          arrayPatientsData.push(chartData.patient[item] || 0);
        }
      });
      if (filterChartData.period === 'all-time' && selectedPeriod.length === 1) {
        selectedPeriod.unshift(String(Number(selectedPeriod[0]) - 1));
        arrayStaffData.unshift(0);
        arrayPatientsData.unshift(0);
      }
      if (filterChartData.period === 'all-time' && selectedPeriod.length === 0) {
        const currentYear = new Date().getFullYear();
        selectedPeriod.push(String(currentYear - 1), String(currentYear));
        arrayStaffData.push(0, 0);
        arrayPatientsData.push(0, 0);
      }
    }
  }

  const data = {
    labels: selectedPeriod,
    datasets: [
      {
        label: 'Surveys filled',
        data: arrayStaffData,
        fill: true,
        backgroundColor: 'white',
        pointBorderColor: '#53A0DF',
        borderColor: '#53A0DF',
        borderWidth: 2,
        pointBorderWidth: 5,
        pointRadius: 8,
      },
      {
        label: 'Surveys sent',
        data: arrayPatientsData,
        fill: true,
        backgroundColor: 'white',
        pointBorderColor: '#6FDE47',
        borderColor: '#6FDE47',
        borderWidth: 2,
        pointBorderWidth: 5,
        pointRadius: 8,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        // position: 'top',
        // labels: {
        //   boxWidth: 100,
        //   boxHeight: 2,
        //   usePointStyle: true,
        //   textAlign: 'center',
        //   padding: 50,
        // },
      },
    },
    // layout: { padding: { bottom: 10 } },
    // indexAxis: 'x',
    scales: {
      y: {
        beginAtZero: true,
        grace: '10%',
        ticks: {
          padding: 10,
          color: '#B6B3B3',
          font: {
            size: 18,
          },
        },
        grid: {
          color: '#E2EAF6',
        },
      },
      x: {
        ticks: {
          padding: 15,
          align: 'start',
          color: '#B6B3B3',
          font: {
            size: 18,
          },
        },
        grid: {
          drawTicks: false,
          drawOnChartArea: false,
          // false or true>>???? drawBorder: false,
          drawBorder: false,
          color: '#E2EAF6',
        },
      },
    },
  };

  return (
    <div className="b-mainContainer__contentWrapper">
      {loading && <Loader fitParent={true} />}
      <div className="b-chartBlock">
        <div className="b-chartBlock__heading">
          <div className="b-chartBlock__tabset">
            <button type="button" className={filterChartData.period === 'week' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('week')}>Week</button>
            <button type="button" className={filterChartData.period === 'month' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('month')}>Month</button>
            <button type="button" className={filterChartData.period === '3-month' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('3-month')}>3 Months</button>
            <button type="button" className={filterChartData.period === '6-month' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('6-month')}>6 Months</button>
            <button type="button" className={filterChartData.period === 'year' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('year')}>Year</button>
            <button type="button" className={filterChartData.period === 'all-time' ? "b-chartBlock__tabsetItem b-chartBlock__tabsetItem--active" : "b-chartBlock__tabsetItem"} onClick={() => handlePeriodChange('all-time')}>All Time</button>
          </div>
        </div>
        <div className="b-chartBlock__content">
          {!!arrayStaffData.length && !!arrayPatientsData.length ?
            <div className="b-chartBlock__chart">
              <Line data={data} options={options} />
            </div>
            : null}
          <div className="b-chartBlock__filters">
            {/* {templates && filterChartData ? ( */}
            { filterChartData ? (
              <Select
                value={selectedTemplate}
                options={selectedTypeTemplates}
                label={'Select Survey:'}
                className={'mb-0'}
                onChange={(value) => handleTemplateChange(value)}
              />)
              : null}
            <br />
            {colorType && filterChartData ? (
              <Select
                value={selectedType}
                options={typesForSelect}
                label={'Surveys Types:'}
                className={'mb-0'}
                onChange={value => handleTypeChange(value)}
              />
            ) : null}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }} >
                <img src={questionnaires_filled} alt="filled" width="150px" style={{ paddingTop: 20 }} />
                <div style={{ fontSize: 12, lineHeight: '16px', fontWeight: 700 }}>SURVEYS FILLED</div>
                <img src={questionnaires_sent} alt="sent" width="150px" style={{ paddingTop: 20 }} />
                <div style={{ fontSize: 12, lineHeight: '16px', fontWeight: 700 }}>SURVEYS SENT</div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartComponent;
