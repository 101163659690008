import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
    SignInContainer,
    Dashboard,
    ForgotPasswordContainer,
    ResetPasswordContainer,
    PolicyContainer,
    PatientsList,
    PatientsAdd,
    ManageUsersList,
    ManageUsersAdd,
    ClinicalPracticeGuides,
    TemplatesList,
    SurveyPage,
    CreateTemplatePage,
    ReportsView,
    Questionnaires,
    SettingsView,
    HccList,
    AllHccList
} from '../components/pages';
import { RootState } from '../store/reducers';
import { Routes } from './routes';

export const Router = (): JSX.Element => {
  const access_token = useSelector((state: RootState) => state.user.access_token);
  const is_policy_accepted = useSelector((state: RootState) => state.user.is_policy_accepted);

  let routes: JSX.Element = (
    <Switch>
      <Route exact path={Routes.SIGNIN} component={SignInContainer} />
      <Route path={Routes.FORGOTPASS} component={ForgotPasswordContainer} />
      <Route path={Routes.RESETPASS} component={ResetPasswordContainer} />
      <Route path={Routes.CREATEPASS} component={ResetPasswordContainer} />
      <Route path={Routes.SURVEY_PAGE} component={SurveyPage}/>
      {/* Rest auth routes */}
      <Redirect to={Routes.SIGNIN} />
    </Switch>
  );

  if (access_token && is_policy_accepted === '0') {
    routes = (
      <Switch>
        <Route path={Routes.POLICY} component={PolicyContainer}/>

        <Redirect to={Routes.POLICY} />
      </Switch>
    );
  }
  
  if (access_token && is_policy_accepted === '1') {
  // if (access_token) {

    routes = (
      <Switch>
        {/* Main routes */}
        <Route path={Routes.DASHBOARD} component={Dashboard} />
        <Route path={Routes.PATIENTS_LIST} component={PatientsList} />
        <Route path={Routes.PATIENTS_ADD} component={PatientsAdd} />
        <Route path={Routes.PATIENTS_EDIT} component={PatientsAdd} />
        <Route path={Routes.MANAGE_USERS_LIST} component={ManageUsersList} />
        <Route path={Routes.MANAGE_USERS_ADD} component={ManageUsersAdd} />
        <Route path={Routes.MANAGE_USERS_EDIT} component={ManageUsersAdd} />
        <Route path={Routes.REPORTS} component={ReportsView}/>
        <Route path={Routes.CLINICAL_PRACTICE_GUIDES} component={ClinicalPracticeGuides} />
        <Route path={Routes.CREATE_TEMPLATE} component={CreateTemplatePage} />
        <Route path={Routes.TEMPLATES_LIST} component={TemplatesList} />
        <Route path={Routes.SURVEY_PAGE} component={SurveyPage}/>
        <Route path={Routes.QUESTIONNAIRES} component={Questionnaires} />
        <Route path={Routes.SETTINGS} component={SettingsView}/>
        {/* <Route path={Routes.PATIENTHCCCODES} component={HccList}/> */}
        <Route path={Routes.ALLHCCCODES} component={AllHccList}/>

        <Redirect to="/dashboard" />
      </Switch>
    );
  }

  return routes;
};
