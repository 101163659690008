import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import styles from '../Patients/PatientsList/PatientsListView.module.scss';

import { Link, useHistory } from "react-router-dom";
import { HeaderSearch, Pagination } from '../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from "../../controls/CheckBox/CheckBox";
import Button from "../../controls/Button/Button";
import Select from "../../controls/Select/Select";
import {Routes} from "../../../routes/routes";
import { getQuestionnaires, getScoreLevelQuestionnaire, getStatusQuestionnaire, sendSurveyOnEmail, getSurveyForFill, clearSurveyChart } from '../../../store/survey/surveySlice';
import DatePicker from "react-datepicker";

import { RootState } from '../../../store/reducers';
import { Loader } from '../../controls';
import { ColorType, FilterQuestionnaires, ISelectOptions, PatientsTableModel, ScoreLevelQuestionnaire, StatusQuestionnaire } from '../../../models';
import { getColorType, getTemplates } from '../../../store/templates/templatesSlice';
import Chart from './Chart/Chart';
import Modal from '../../common/Modal/Modal';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

interface IState {
  modalType: 'fill' | 'send' | 'success' | null,
}

const selectionTimeToPass = [
  {label: '1 Day', value: '1440'},
  {label: '2 Day', value: '2880'},
  {label: 'Week', value: '10080'},
];

const initialData = {
  date_from: null,
  date_to: null,
};

const initialState: IState = {
  modalType: null,
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const questionnairesList = useSelector((state: RootState) => state.survey.questionnaires);
  const statusQuestionnaire = useSelector((state: RootState) => state.survey.statusQuestionnaire);
  const scoreLevelQuestionnaire = useSelector((state: RootState) => state.survey.scoreLevelQuestionnaire);
  const colorType = useSelector((state: RootState) => state.templates.colorType);
  const templates = useSelector((state: RootState) => state.templates.templates);

  const [arrayOfColumns, setArrayOfColumns] = useState({ dob: false, email: false, dateOfResponse: false });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState(true);
  const [choicedType, setChoicedType] = useState<number | undefined>(0);
  const [openChartPopup, setOpenChartPopup] = useState(false);
  const [date, setDate] = useState(null);
  const [dateValue, setDateValue] = useState<{date_from: Date | any, date_to: Date | any}>(initialData);
  const [typesForSelect, setTypesForSelect] = useState<ISelectOptions[]>();
  const [selectedType, setSelectedType] = useState<ISelectOptions>();
  const [selectedTypeTemplates, setSelectedTypeTemplates] = useState<ISelectOptions[]>();
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectOptions>();

  const [selectedTimeToPass, setSelectedTimeToPass] = useState<ISelectOptions>();
  const [patientForFillId, setPatientForFillId] = useState<number>();

  const [checkedSurveys, setCheckedSurveys] = useState<string[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);


  let [state, setState] = useState<IState>(initialState);
  const [searchQuestionnaires, setSearchQuestionnaires] = useState<FilterQuestionnaires>({
    ordering: {
      questionnaires: {
        created_at: 'desc',
      },
    },
    searching: {
      type_colors: {
        id: undefined,
      },
      questionnaires: {
        created_at: undefined,
        score_level: undefined,
        status: undefined,
      },
    },
    search: ''
  });

  const filterActive = !!searchQuestionnaires.searching?.type_colors?.id || !!searchQuestionnaires.searching?.questionnaires?.created_at || !!searchQuestionnaires.searching?.questionnaires?.score_level || !!searchQuestionnaires.searching?.questionnaires?.status

  const navTo = (route: string) => {
    history.push(route);
  };

  const handleSearchByPeriod = (filterName: string, value: Date | any) => {
    setDateValue({ ...dateValue, [filterName]: value });
    if (
      (dateValue.date_from !== null && filterName === 'date_to') ||
      (dateValue.date_to !== null && filterName === 'date_from')
    ) {
      const sendData =
        filterName === 'date_to'
          ? {
              ...dateValue,
              date_to: moment(value).format('YYYY-MM-DD'),
              date_from: moment(dateValue.date_from).format('YYYY-MM-DD'),
            }
          : {
              ...dateValue,
              date_to: moment(dateValue.date_to).format('YYYY-MM-DD'),
              date_from: moment(value).format('YYYY-MM-DD'),
            };

      setSearchQuestionnaires({
        ...searchQuestionnaires,
        searching: {
          ...searchQuestionnaires.searching,
          questionnaires: {
            ...searchQuestionnaires.searching?.questionnaires,
            created_at: [sendData.date_from, sendData.date_to],
          },
        },
      });
    }
  };


  const handleSearchInQuestionnaires = (filterName: string, value: string | any) => {
    setLoading(true);
      setSearchQuestionnaires({
        ...searchQuestionnaires,
        searching: {
          ...searchQuestionnaires.searching,
          questionnaires: {
            ...searchQuestionnaires.searching?.questionnaires,
            [filterName]: value ? value : undefined,
          },
        },
      });
  };

  const handleSearchByType = (value: number) => {
    setLoading(true);
    setChoicedType(value);
    setSearchQuestionnaires({
      ...searchQuestionnaires,
      searching: {
        ...searchQuestionnaires.searching,
        type_colors: {
          ...searchQuestionnaires.searching?.type_colors,
          id: value ? value : undefined,
        },
      },
    });
  };

  const handleCloseModal = () => {
    setState({...state, modalType: null})
    setSelectedTemplate({});
    setSelectedType({});
  };

  const handleOpenSendQuestionnaire = () => {
    setState({...state, modalType: 'send'})
  }

  const handleOpenFillQuestionnaire = (id : number) => {
    setState({...state, modalType: 'fill'})
    // dispatch(getColorType());
    setPatientForFillId(id);
  }

  const handleSendSurvey = (type : 'send' | 'fill') => {
    if (selectedTemplate) {
      if (type === 'send' && selectedTimeToPass) {
        dispatch(sendSurveyOnEmail(
          { data: {
             ids: Array.from(new Set(checkedIds)),
             time_to_leave: +selectedTimeToPass.value,
             template_id: +selectedTemplate?.value
           },
           callback: () => setState({...state, modalType: 'success'})
         }
       ))
        setCheckedSurveys([]);
      }

      if (type === 'fill' && patientForFillId) {
        dispatch(getSurveyForFill({
          data: {
            template_id: +selectedTemplate?.value,
            user_id: patientForFillId,
          },
          callback: (token : string) => {
            dispatch(clearSurveyChart());
            navTo(`/fill-questionnaire/${token}`)
          }
        }))
      }

      handleCloseModal();
    }
  }

  const handleTypeChange = (value : ISelectOptions) => {
    setSelectedType(value);
    setSelectedTemplate({});

    dispatch(getTemplates({ data: {
        filtering: {
          type_colors: {
            id: [
              +value.value
            ]
          }
        },
        as_array: 1
      }
    }));
  }

  useEffect(() => {
    if(colorType && colorType[0]) {
      const selectTypes = colorType.map(type => {
        return {
          label: type.title,
          value: type.id,
        }
      })
      setTypesForSelect(selectTypes);
    }
  }, [colorType])

  useEffect(() => {
    if (templates) {
      const formattedTemplates = Object.entries(templates).map( template => {
            return {
              label: template[1],
              value: template[0],
            }
        }
      )
      setSelectedTypeTemplates(formattedTemplates)
    }
  }, [templates]);

  const checkItem = (rowData: any) => {
    setCheckedSurveys((prev) => {
      if (prev.includes(rowData.token)) {
        prev = prev.filter((item) => item !== rowData.token);
        return prev;
      } else {
        return [...prev, rowData.token];
      }
    });

    let arrayIds: number[] = [...checkedIds];
    if (checkedSurveys.includes(rowData.token)) {
      arrayIds.splice(arrayIds.indexOf(rowData.patient.id),1);
    } else {
      arrayIds.push(rowData.patient.id)
    }

    setCheckedIds(arrayIds);
  };

  const checkAll = () => {
    if (questionnairesList?.data) {
      let checkedCounter = 0;
      questionnairesList.data.map((item) => (checkedSurveys.includes(item.token) ? checkedCounter++ : item));
      if (checkedSurveys.length > 0 && questionnairesList.data.length === checkedCounter) {
        let array: string[] = [];
        questionnairesList.data.map((item) => array.push(item.token));
        const filteredCheckedSurveys = checkedSurveys.filter((item) => !array.includes(item));
        setCheckedSurveys(filteredCheckedSurveys);

        let arrayIds: number[] = [];
        questionnairesList.data.map((item) => arrayIds.push(item.patient.id));
        const filteredCheckedIds = checkedIds.filter((item) => !arrayIds.includes(item));
        setCheckedIds(filteredCheckedIds);
      } else {
        let array: string[] = [...checkedSurveys];
        questionnairesList.data.map((item) => (array.includes(item.token) ? item : array.push(item.token)));
        setCheckedSurveys(array);

        let arrayIds: number[] = [...checkedIds];
        questionnairesList.data.map((item) => arrayIds.push(item.patient.id));
        setCheckedIds(arrayIds);
      }
    }
  };

  const allSelected = () => {
    let checkedCounter = 0;
    if (questionnairesList?.data) {
      questionnairesList.data.map((item) => (checkedSurveys.includes(item.token) ? checkedCounter++ : item));
      if (checkedSurveys.length > 0 && questionnairesList.data.length === checkedCounter) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    dispatch(getStatusQuestionnaire());
    dispatch(getScoreLevelQuestionnaire());
    dispatch(getColorType());
  }, []);

  const handleSearch = (value: string) => {
    setSearchQuestionnaires({
      ...searchQuestionnaires,
      search: value
    });
  };

  useEffect(() => {
    if (searchQuestionnaires.searching?.questionnaires?.created_at?.length !== 2) {
      setDateValue(initialData);
    }
    currentPage === 1 ?
      dispatch(
        getQuestionnaires({
          data: searchQuestionnaires,
          page: 1,
          callback: () => {
            setLoading(false);
          },
        }),
      )
      : setCurrentPage(1);
  }, [searchQuestionnaires]);

  useEffect(() => {
    if (searchQuestionnaires.searching?.questionnaires?.created_at?.length !== 2) {
      setDateValue(initialData);
    }
    if (questionnairesList) {
      setLoading(true);
    }
    dispatch(
      getQuestionnaires({
        data: searchQuestionnaires,
        page: currentPage,
        callback: () => {
          setLoading(false);
        },
      }),
    );
  }, [currentPage]);

  const templateScoreResults = (rowData: any) => {
    return <b>{Math.round(rowData.score) || 0}</b>;
  };

  const dateOfResponseTemplate = (rowData: any) => {
    if (rowData.date_of_response) {
      const dateResponse = rowData.date_of_response.split(' ')[0];
      return <b className='date'>{dateResponse}</b>;
    }
    return <b>{'-'}</b>;
  };

  const createDateTemplate = (rowData: any) => {
    const date = rowData.created_at.split(' ')[0];
    return <b className='date'>{date}</b>;
  };

  const creatorOfTemplate = (rowData: any) => {
    return <b>{rowData.creator.first_name}</b>;
  };

  const templateEmail = (rowData: any) => {
    return <span>{rowData.patient.email}</span>;
  };
  const patientDoBTemplate = (rowData: any) => {
    return <b className='date'>{rowData.patient.dob}</b>;
  };

  const titleTemplateLink = (rowData: any) => {
    return (
      <Link to={`/fill-questionnaire/${rowData.token}`} className="">
        {rowData.title}
      </Link>
    );
  };

  const patientTemplateLink = (rowData: any) => {
    return (
      <a
        href={`/patient-edit/${rowData.patient.id}`}>{`${rowData.patient.first_name} ${rowData.patient.last_name}`}</a>
    );
  };

  const status = (rowData: any) => {
    let colorStatus;
    if (rowData.status === 'Sent') {
      colorStatus = 'sent';
    } else if (rowData.status === 'In Progress') {
      colorStatus = 'inProgress';
    } else {
      colorStatus = 'completed';
    }

    return <span className={`status --${colorStatus}`}>{rowData.status}</span>;
  };

  const footer = (
    <div className="patientControlList">
      <Link to={Routes.QUESTIONNAIRES} className="footerBtn">
        run new Survey
      </Link>
      <div className="patientControlList__innerWrapper">
        <Button
          title='send Survey'
          dataFor={'sendToolTip'}
          className={'darkest -uppercase'}
          onPress={!checkedSurveys.length ? (() => null) : handleOpenSendQuestionnaire}/>
          {!checkedSurveys.length ?
            <ReactTooltip id='sendToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
              Please select at least one patient to send survey
            </ReactTooltip>
          : null}
      </div>
    </div>
  );

  const title = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchQuestionnaires({
          ordering: {
            templates: {
              title: sort ? 'asc' : 'desc',
            },
          },
          searching: { ...searchQuestionnaires.searching },
          search: searchQuestionnaires.search
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Title</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const dateOfCreate = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchQuestionnaires({
          ordering: {
            questionnaires: {
              created_at: sort ? 'asc' : 'desc',
            },
          },
          searching: { ...searchQuestionnaires.searching },
          search: searchQuestionnaires.search
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Date</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const dateOfResponse = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchQuestionnaires({
          ordering: {
            questionnaires: {
              completed_at: sort ? 'asc' : 'desc',
            },
          },
          searching: { ...searchQuestionnaires.searching },
          search: searchQuestionnaires.search
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Date of Response</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const creator = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchQuestionnaires({
          ordering: {
            creator_profiles: {
              first_name: sort ? 'asc' : 'desc',
            },
          },
          searching: { ...searchQuestionnaires.searching },
          search: searchQuestionnaires.search
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Creator</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const patient = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchQuestionnaires({
          ordering: {
            patient_profiles: {
              first_name: sort ? 'asc' : 'desc',
            },
          },
          searching: { ...searchQuestionnaires.searching },
          search: searchQuestionnaires.search
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Patient</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const headerEdit = (
    <span className='p-column-title -flex'>
      {/* <span className='p-column-title-inner'>Edit</span> */}
      <Checkbox
        checked={allSelected()}
        onChange={checkAll}/>
    </span>
  );

  const patientEdit = (rowData:any) => {

    return (
      <div className={styles.patientControl}>
        <a 
            className={styles.patientDocLink} 
            href="#" 
            aria-label=""
            onClick={() => handleOpenFillQuestionnaire(rowData.patient.id)}
            data-tip data-for='patientDoc'>
          <ReactTooltip id='patientDoc' className='b-tooltip'>
            Fill Survey
          </ReactTooltip>  
        </a>
        <Checkbox 
          checked={checkedSurveys.includes(rowData.token)}
          onChange={() => checkItem(rowData)}/>
      </div>
    )
  };

  const _generateListForSelect = (obj: StatusQuestionnaire | ScoreLevelQuestionnaire) => {
    let newList: { label: string; value: string }[] = [{ label: 'completed' in obj ? 'All Status' : 'All Scores', value: '' }];
    for (let key in obj) {
      newList.push({ label: obj[key], value: key });
    }
    return newList;
  };

  const _generateTypeListForSelect = (typeList: ColorType[]) => {
    let newList: { label: string; value: number }[] = [{ label: 'All Types', value: 0 }];

    typeList.map((item) => newList.push({ label: item.title, value: item.id }));
    return newList;
  };

  const typeInfo = (
    <div className="p-datatable-filters__popupContainerInfo">
      <button type="button" className="p-datatable-filters__popupOpenerInfo"></button>
      <div className="p-datatable-filters__popupInfo">
        <div className="p-datatable-filters__popupFrame">
          <div className="p-datatable-filters__popupHeading">TYPE INFO</div>
          <div className="p-datatable-filters__popupContent">
            <ul className="p-datatable-filters__popupList">
              <li>
                <b>Hybrid survey</b> - Has a score and a dial. This is for clinicians only.
              </li>
              <li>
                <b>Task survey</b> that creates a task list or action plan based on how the questions are
                answered, Example Social determinants of Health. Only for clinicians to fill out.
              </li>
              <li>
                <b>Standard survey</b> - Just informational to get responses from patient or survey
                taker, sent to patient to fill out. Example patient pain. Patients and clinicians
                may fill out.
              </li>
              <li>
                <b>Scored survey</b> (with score) - Patients or clinicians
                may fill out
              </li>
              <li>
                <b>Weighted survey</b> (with dial) example, the readmission surveys. Only clinicians
                fill out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={'b-mainContainer__wrapper'}>
      <HeaderSearch
          pageName="Dashboard"
          isSearhVisible={false}
      />
      <div className={'b-mainContainer__wrapperInner'}>
       
        <div className={`b-mainContainer__contentWrapper`}>
          {/* {loading && <Loader fitParent={true} />} */}
          <div className="p-datatable-filters">
            <h2 className="p-datatable-filters__title">Sent Surveys</h2>
            <div className="p-datatable-filters__container">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  placeholder="Search"
                  value={searchQuestionnaires.search}
                  onChange={e => handleSearch(e.target.value)}
                />
                </form>
            </div>
              <div className="p-datatable-filters__iconsBox">
              <div className="p-datatable-filters__popupContainer">
                <button type="button" className={`p-datatable-filters__popupFilter ${filterActive ? '--active' : ''}`}></button>
                <div className="p-datatable-filters__popup">
                  <div className="p-datatable-filters__popupFrame">
                    <div className="p-datatable-filters__popupHeading">Filter</div>
                    <div className="p-datatable-filters__popupContent">
                    <div className="p-datatable-filters__container">

                    <div className={'p-datatable-filters__col'}>
                      <div className={'b-form__datePikerLabel'}>
                        From Date:
                      </div>
                      <DatePicker maxDate={dateValue.date_to || new Date()}
                            dateFormat={'MM.dd.yyyy'}
                            selected={dateValue.date_from}
                            onChange={date => handleSearchByPeriod('date_from', date)}/>
                    </div>
                    <div className={'p-datatable-filters__col'}>
                      <div className={'b-form__datePikerLabel'}>
                        To Date:
                      </div>
                      <DatePicker minDate={dateValue.date_from || new Date()}
                            dateFormat={'MM.dd.yyyy'}
                            selected={dateValue.date_to}
                            onChange={date => handleSearchByPeriod('date_to', date)}/>
                    </div>

              <div className="p-datatable-filters__col">
                {scoreLevelQuestionnaire && searchQuestionnaires ? (
                  <Select
                    value={
                      _generateListForSelect(scoreLevelQuestionnaire).filter(
                        (item) =>
                          item.value ===
                          searchQuestionnaires.searching?.questionnaires?.score_level,
                      )[0] || _generateListForSelect(scoreLevelQuestionnaire)[0]
                    }
                    options={_generateListForSelect(scoreLevelQuestionnaire)}
                    label={'Score:'}
                    className={'mb-0'}
                    onChange={(value) => handleSearchInQuestionnaires('score_level', value.value)}
                  />
                ) : null}
              </div>
              <div className="p-datatable-filters__col">
                {colorType && searchQuestionnaires ? (
                  <Select
                  value={
                    _generateTypeListForSelect(colorType).filter(
                      (item) =>
                        item.value === choicedType
                    ) || _generateTypeListForSelect(colorType)[0]
                  }
                    options={_generateTypeListForSelect(colorType)}
                    label={'Type:'}
                    className={'mb-0'}
                    onChange={(value) => handleSearchByType(value.value)}
                  />
                ) : null}
              </div>
              <div className="p-datatable-filters__col">
                {statusQuestionnaire && searchQuestionnaires? (
                  <Select
                    value={
                      _generateListForSelect(statusQuestionnaire).filter(
                        (item) =>
                          item.value ===
                          searchQuestionnaires.searching?.questionnaires?.status,
                      )[0] || _generateListForSelect(statusQuestionnaire)[0]
                    }
                    options={_generateListForSelect(statusQuestionnaire)}
                    label={'Status:'}
                    className={'mb-0'}
                    onChange={(value) => handleSearchInQuestionnaires('status', value.value)}
                  />
                ) : null}
              </div>
              <Button
                title={'clear'}
                type={'transparency'}
                className={'-uppercase -m-5'}
                // width={'100px'}
                onPress={() => {
                  setDate(null);
                  setDateValue(initialData);
                  setSearchQuestionnaires({
                    ordering: {
                      questionnaires: {
                        created_at: 'desc',
                      },
                    },
                    searching: {
                      type_colors: {
                        id: undefined,
                      },
                      questionnaires: {
                        created_at: undefined,
                        score_level: undefined,
                        status: undefined
                      },
                    },
                    search:''
                  });
                  setChoicedType(0)
                }} />
                    </div>
                  </div>
                </div>
              </div>
              </div>

              <div className="p-datatable-filters__popupContainer">
                <button type="button" className="p-datatable-filters__popupOpener"></button>
                <div className="p-datatable-filters__popup">
                  <div className="p-datatable-filters__popupFrame">
                    <div className="p-datatable-filters__popupHeading">Hide/Show columns</div>
                    <div className="p-datatable-filters__popupContent">
                      <ul className="b-checkList">
                        <li className="b-checkList__item">
                          <Checkbox
                            checked={arrayOfColumns.dob}
                            onChange={() =>
                              setArrayOfColumns({ ...arrayOfColumns, dob: !arrayOfColumns.dob })
                            }>
                            <label>DoB</label>
                          </Checkbox>
                        </li>
                        <li className="b-checkList__item">
                          <Checkbox
                            checked={arrayOfColumns.email}
                            onChange={() =>
                              setArrayOfColumns({ ...arrayOfColumns, email: !arrayOfColumns.email })
                            }>
                            <label>Email</label>
                          </Checkbox>
                        </li>
                        <li className="b-checkList__item">
                          <Checkbox
                            checked={arrayOfColumns.dateOfResponse}
                            onChange={() =>
                              setArrayOfColumns({
                                ...arrayOfColumns,
                                dateOfResponse: !arrayOfColumns.dateOfResponse,
                              })
                            }>
                            <label>Date of Response</label>
                          </Checkbox>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-datatable-filters__popupContainer">
                <button type="button" className="p-datatable-filters__chartPopup" onClick={() => setOpenChartPopup(true)}></button>
              </div>
            </div>
          </div>
          {questionnairesList ? (
            <>
              <DataTable
                value={questionnairesList.data}
                className="-paginator"
                footer={footer}
              >
                <Column field="score" header="Score" body={templateScoreResults} />
                <Column field="patient.first_name" header={patient} body={patientTemplateLink} />
                <Column field="title" header={title} body={titleTemplateLink} />
                <Column field="created_at" header={dateOfCreate} body={createDateTemplate} />
                {arrayOfColumns.dateOfResponse ? (
                  <Column
                    field="date_of_response"
                    header={dateOfResponse}
                    body={dateOfResponseTemplate}
                  />
                ) : null}
                <Column field="creator.first_name" header={creator} body={creatorOfTemplate} />
                <Column field='status' header="Status" body={status}/>
                {arrayOfColumns.email ? (
                  <Column field="patient.email" header="Email" body={templateEmail} />
                ) : null}
                {arrayOfColumns.dob ? (
                  <Column field="patient.dob" header="DoB" body={patientDoBTemplate} />
                ) : null}
                <Column field='edit' header={headerEdit} body={patientEdit}/>
              </DataTable>
              <Pagination
                displayTitle={'surveys'}
                data={questionnairesList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : null}
        </div>
      </div>
      {state.modalType === 'send' && <Modal
          title='Send Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect?.filter(item => item.label === 'Standard survey' || item.label === 'Scored survey')}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <Select 
              label={'Time to pass *'}
              value={selectedTimeToPass}
              options={selectionTimeToPass}
              width='100%'
              onChange={value => setSelectedTimeToPass(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button
              title={'Cancel'}
              onPress={handleCloseModal}
              type={'transparency'}
              width="100px"
            />
            <Button
              title={'Send'}
              disabled={!selectedTemplate?.value || !selectedTimeToPass}
              onPress={() => handleSendSurvey('send')}
              className={'-ml-10'}
              width="100px"
            />
          </div>
        </div>
      </Modal> }
      {state.modalType === 'success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Survey<i><b>&nbsp;
              {/* “${selectedTemplate?.label}” */}
               </b></i>&nbsp;has been sent
          </div>
        </div>
      </Modal>}
      {state.modalType === 'fill' && <Modal
          title='Fill Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Fill'} disabled={!selectedTemplate?.value} onPress={() => handleSendSurvey('fill')} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {openChartPopup && colorType ?
        <Modal
          title='Surveys Statistics'
          width={1144}
          onClose={() => setOpenChartPopup(false)}>
          <Chart colorType={colorType} _generateTypeListForSelect={_generateTypeListForSelect} />
        </Modal>
        : null
      }
    </div>
  );
};

export default Dashboard;
