import React, {ReactNode, FC} from 'react';
import './Button.scss';

export interface IProps {
  title: string;
  onPress: (e: Event) => void;

  width?: string | number,
  height?: string | number,

  disabled?: boolean;
  type?: 'primary' | 'transparency' | 'white' | undefined;

  className?: string,
  iconLeft?: ReactNode,
  dataFor?: string,
}

const Button: FC<IProps> = ({
                  disabled,
                  title,
                  onPress,
                  type,
                  className,
                  width,
                  height,
                  iconLeft,
                  dataFor,
                }: IProps) => {
  const styles: any = {};
  if (width) styles.width = width;
  if (height) styles.height = height;

  return (
      <button
          data-tip
          data-for={dataFor}
          disabled={disabled}
          onClick={e => onPress(e)}
          className={`
            b-button
            ${type}
            ${className || ''}
            ${disabled ? `b-button-disabled` : ''}
            `}
          style={styles}
      >
        <div className='b-button__ic'>{iconLeft}</div>
        {title}
      </button>
  );
};

Button.defaultProps = {
  type: 'primary',
};

export default Button;
