import moment from 'moment';

export const roundNumber = (
  val: string | number,
  multiplier: number = 100
): number => {
  return Math.round(Number(val) * multiplier) / multiplier;
};

export const isToday = (date: Date): boolean => {
  return moment(date).format('ll') === moment(new Date()).format('ll');
};

export const isEmptyObject = (obj: object) => {
  if (!obj) return true;
  return !Object.values(obj).length;
};

export const generateHash = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const arrToObject = (arr: any[]) => {
  let rv: any = {};
  for (let i = 0; i < arr.length; ++i) {
    if (arr[i] !== undefined) {
      rv[arr[i].id] = arr[i];
    }
  }
  return rv;
};

export const cutString = (str: string, length: number) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const capitalize = (str: string): string => {
  return str.substring(0, 1).toUpperCase() + str.substring(1)
};

export const generateId = () => {
  let id = 1;
  return () => id++;
};