import React, { FC, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';

import { Question, QuestionParent } from '../../../../../models/templates/templates.model';
import { XYCoord } from 'dnd-core';
import SurveyQuestion from '../../../Survey/parts/SurveyQuestion/SurveyQuestion';
import styles from '../../../TemplatesList/TemplatesListView.module.scss'

interface DragItem {
  data: Question;
  index: number;
  id: number;
  type: string;
}

type IProps = {
  closePopup: () => void;
  categoryItems: Question[];
  name: string;
  data: Question;
  index: number;
  openPopup: (data: Question) => void;
  questionManipulate: (type: 'copy' | 'delete', questionId: number, categoryId: number) => void;
  moveItemHandler: (
    categoryData: Question[],
    dragIndex: number,
    hoverIndex: number,
    categoryId: number,
  ) => void;
  moveItemInAnotherCategory: (item: Question, toCategoryId: number, fromCategoryId: number) => void;
  collectAnswers: (type: 'sub' | 'main', id : number, answers?: number[], parent?: QuestionParent, text?: string) => void;
};

const QuestionItem: FC<IProps> = ({
  closePopup,
  name,
  data,
  index,
  openPopup,
  questionManipulate,
  moveItemInAnotherCategory,
  moveItemHandler,
  categoryItems,
  collectAnswers
}) => {
  const { title, category_id, nameOfType } = data;

  const refQ = useRef<HTMLDivElement>(null);
  const [{ handlerId }, dropRefQ] = useDrop({
    accept: 'questionDrag',
    drop: () => ({ categoryId: category_id }),

    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!refQ.current) {
        return;
      } else {
        closePopup();
      }
      if (category_id === item.data.category_id) {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }
        const hoverBoundingRect = refQ.current?.getBoundingClientRect();
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset: XYCoord | null = monitor.getClientOffset();
        if (clientOffset) {
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
          moveItemHandler(categoryItems, dragIndex, hoverIndex, category_id);
          item.index = hoverIndex;
        }
      } else {
        return;
      }
    },
  });

  const [{ isDragging }, dragQ] = useDrag({
    item: () => {
      return { data, index };
    },
    type: 'questionDrag',
    end: (item, monitor) => {
      const dropResult: { categoryId: number } | null = monitor.getDropResult();
      if (data && dropResult && dropResult.categoryId) {
        if (dropResult.categoryId === data.category_id) {
          return;
        }
        closePopup();
        moveItemInAnotherCategory(data, dropResult.categoryId, data.category_id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;
  dragQ(dropRefQ(refQ));

  return (
    <>
              {/* {!data.title ?

              <div
                className="templates__listItemQuestion"
                id={name}
                data-handler-id={handlerId}
                ref={refQ}
                style={{ opacity: `${opacity}` }}>
                <p onClick={() => openPopup(data)}>{title || nameOfType}</p>

                <button onClick={() => questionManipulate('delete', data.id, data.category_id)}>—</button>
              </div>
              : */}
                  <div 
                    className="templates__listItemQuestionPreview"
                    id={name}
                    data-handler-id={handlerId}
                    ref={refQ}
                    style={{ opacity: `${opacity}`}}
                    >
                      <div className='previewEdit'> 
                      {/* <button
                        className={`${styles.buttonPreview} ${styles.buttonPreview__copy}`}
                        // onClick={() => copyTemplate(template.id)}
                        >
                      </button> */}
                      <button
                        className={`${styles.buttonPreview} ${styles.buttonPreview__edit}`}
                        onClick={() => openPopup(data)}>
                      </button>
                      <button
                        className={`${styles.buttonPreview} ${styles.buttonPreview__remove}`}
                        onClick={() => questionManipulate('delete', data.id, data.category_id)}>
                      </button>
                      </div>
                    <SurveyQuestion
                        id={`question-${data.id}${data.id}`}
                        data={data}
                        key={`questionItem-${data.id}`}
                        collectAnswers={collectAnswers}
                        preview={true}
                    /> 
                  </div>

              {/* } */}
    </>
  );
};

export default QuestionItem;
