import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  KeyValueModel,
  TokenData,
  UserData,
  UserPasswordUpdatePayload,
  UserProfile
} from "../../models";
import {UserDataUpdatePayload} from "../../models/user/user.model";

export interface LoginSuccessPayload {
  tokenData: TokenData,
  userData: UserData,
  userProfile: UserProfile,
}

export interface LoginPayload {
  email: string,
  password: string,
  callback: (error: KeyValueModel<string>) => void
}

export interface PolicyPayload {
  is_policy_accepted: number,
  callback: () => void,
}

export interface RestorePasswordTokenPayload {
  email: string,
  callback: (error: KeyValueModel<string>) => void
}

export interface ResetPasswordPayload {
  password: string,
  token:string,
  callback: (error: KeyValueModel<string>) => void
}

export interface InitialState {
  userData: UserData | null,
  access_token: string | null,
  is_policy_accepted: string | null,
  userProfile: UserProfile | null,
}

const initialState: InitialState = {
  userData: null,
  userProfile: null,

  access_token: localStorage.getItem('access_token') || null,
  is_policy_accepted: localStorage.getItem('is_policy_accepted') || null,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, {payload}: PayloadAction<LoginPayload>) => {},
    loginSuccess: (state, {payload}: PayloadAction<LoginSuccessPayload>) => {
      state.access_token = payload.tokenData.access_token;
      state.is_policy_accepted = ''+payload.userData.option.is_policy_accepted;
      state.userData = payload.userData;
      state.userProfile = payload.userProfile;
    },
    logout: (state) => {},
    logoutSuccess: (state) => {
      state.userData = null;
      state.access_token = null;
      state.is_policy_accepted = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('is_policy_accepted');
    },
    getRestorePasswordToken: (state, {payload}: PayloadAction<RestorePasswordTokenPayload>) => {},
    resetPassword:(state, {payload}: PayloadAction<ResetPasswordPayload>) => {},
    getUserDetails: (state) => {},
    getUserDetailsSuccess: (state, {payload}: PayloadAction<UserData>) => {
      state.userData = payload;
    },
    updateUserDetails: (state, { payload }: PayloadAction<UserDataUpdatePayload>) => { },
    updateUserPassword: (state, { payload }: PayloadAction<UserPasswordUpdatePayload>) => { },
    acceptPolicy: (state, {payload}: PayloadAction<PolicyPayload>) => {},
    acceptPolicySuccess: (state) => {
      state.is_policy_accepted = '1';
    },
  }
});

const {
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  getRestorePasswordToken,
  resetPassword,
  getUserDetails,
  getUserDetailsSuccess,
  updateUserDetails,
  updateUserPassword,
  acceptPolicy,
  acceptPolicySuccess
} = user.actions;

export {
  login,
  loginSuccess,
  logout,
  logoutSuccess,
  getRestorePasswordToken,
  resetPassword,
  getUserDetails,
  getUserDetailsSuccess,
  updateUserDetails,
  updateUserPassword,
  acceptPolicy,
  acceptPolicySuccess
}

export default user.reducer
