import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  QuestionType,
  QuestionCategory,
  TemplateCategory,
  ColorType,
  MainForm,
  AddedTemplate,
  NewTemplateCategory,
  TemplatePreviewSurveyChartModel,
  TemplatePreviewSaveModel,
  CopyTemplatePayload,
  TemplatesPayload,
  DeletePayload,
  PaginationModel,
  Template,
  MainFormPayload,
  NewTemplateCategoryPayload,
} from '../../models';
import store from '../configureStore';

export interface InitialState {
  questionTypes: QuestionType[] | null;
  questionCategory: QuestionCategory[] | null;
  templateCategory: TemplateCategory[] | null;
  colorType: ColorType[] | null;
  // addedTemplate: AddedTemplate | null;
  templates: PaginationModel<Template[]> | null,
  templateById: MainForm | null,
  previewScore: TemplatePreviewSurveyChartModel | null;

}

const initialState: InitialState = {
  questionTypes: null,
  questionCategory: null,
  templateCategory: null,
  colorType: null,
  // addedTemplate: null,
  templates: null,
  templateById: null,
  previewScore: null,

};

const templates = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    getQuestionTypes: (state) => { },
    getQuestionTypesSuccess: (state, { payload }: PayloadAction<QuestionType[]>) => {
      state.questionTypes = payload;
    },
    getQuestionCategory: (state) => { },
    getQuestionCategorySuccess: (state, { payload }: PayloadAction<QuestionCategory[]>) => {
      state.questionCategory = payload;
    },
    getTemplateCategory: (state) => { },
    getTemplateCategorySuccess: (state, { payload }: PayloadAction<TemplateCategory[]>) => {
      state.templateCategory = payload;
    },
    createTemplateCategory: (state, {payload}: PayloadAction<NewTemplateCategoryPayload>) => {},
    createTemplateCategorySuccess: (state, { payload }: PayloadAction<TemplateCategory>) => {
      if (state.templateCategory) {
        state.templateCategory.unshift(payload);
      }
    },
    getColorType: (state) => { },
    getColorTypeSuccess: (state, { payload }: PayloadAction<ColorType[]>) => {
      state.colorType = payload;
    },
    createTemplate: (state, {payload}: PayloadAction<MainFormPayload>) => {},
    // createTemplateSuccess: (state, { payload }: PayloadAction<AddedTemplate>) => {
    //   state.addedTemplate = payload;
    // },
    updateTemplate: (state, {payload}: PayloadAction<MainFormPayload>) => {},
    getTemplates: (state, {payload}: PayloadAction<TemplatesPayload>) => {},
    getTemplatesSuccess: (state, { payload }: PayloadAction<PaginationModel<Template[]>>) => {
      state.templates = payload;
    },
    copyTemplateById: (state, {payload}: PayloadAction<CopyTemplatePayload>) => {},
    
    deleteTemplateById: (state, {payload}: PayloadAction<DeletePayload>) => {},
    sendPreviewSurvey: (state, {payload}: PayloadAction<TemplatePreviewSaveModel>) => { },
    sendPreviewSurveySuccess: (state, { payload }: PayloadAction<TemplatePreviewSurveyChartModel>) => {
        state.previewScore = payload;
    },
    clearChartPreview: (state) => {
      state.previewScore = null;
    },
  },
});

const {
  getQuestionTypes,
  getQuestionTypesSuccess,
  getQuestionCategory,
  getQuestionCategorySuccess,
  getTemplateCategory,
  getTemplateCategorySuccess,
  createTemplateCategory,
  createTemplateCategorySuccess,
  getColorType,
  getColorTypeSuccess,
  createTemplate,
  // createTemplateSuccess,
  updateTemplate,
  getTemplates,
  getTemplatesSuccess,
  copyTemplateById,
  deleteTemplateById,
  sendPreviewSurvey,
  sendPreviewSurveySuccess,
  clearChartPreview,
} = templates.actions;

export {
  getQuestionTypes,
  getQuestionTypesSuccess,
  getQuestionCategory,
  getQuestionCategorySuccess,
  getTemplateCategory,
  getTemplateCategorySuccess,
  createTemplateCategory,
  createTemplateCategorySuccess,
  getColorType,
  getColorTypeSuccess,
  createTemplate,
  // createTemplateSuccess,
  updateTemplate,
  getTemplates,
  getTemplatesSuccess,
  copyTemplateById,
  deleteTemplateById,
  sendPreviewSurvey,
  sendPreviewSurveySuccess,
  clearChartPreview,
};

export default templates.reducer;
