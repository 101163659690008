import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    DeletePatientsPayload,
    DoctorsListModel,
    DoctorsSearch,
    GetPatientModel,
    HccCodeListModel,
    HccCodesSearch,
    ISelectOptions,
    PaginationModel,
    PatientAddUpdatePayload,
    PatientFormModel,
    PatientsPayload,
    PatientTableFields,
} from '../../models';

export interface InitialState {
    patient: PatientFormModel | null,
    patientsList: PaginationModel<PatientTableFields[]> | null,
    doctorsList: DoctorsListModel[] | null,
    hccCodeListBySearch: HccCodeListModel[] | null,
    stateList: ISelectOptions[] | null
}

const initialState: InitialState = {
    patient: null,
    patientsList: null,
    doctorsList: null,
    hccCodeListBySearch: null,
    stateList: null,
};

const survey = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        getPatientsList: (state, {payload}: PayloadAction<PatientsPayload>) => { },
        getPatientsListSuccess: (state, { payload }: PayloadAction<PaginationModel<PatientTableFields[]>>) => {
            state.patientsList = payload;
        },
        getPatient: (state, {payload}: PayloadAction<GetPatientModel>) => { },
        getPatientSuccess: (state, { payload }: PayloadAction<PatientFormModel>) => {
            state.patient = payload;
        },
        addPatient: (state, {payload}: PayloadAction<PatientAddUpdatePayload>) => { },
        addPatientSuccess: (state, { payload }) => {},
        deletePatients: (state, {payload}: PayloadAction<DeletePatientsPayload>) => { },
        deletePatientsSuccess: (state, { payload }) => {},
        updatePatient: (state, {payload}: PayloadAction<PatientAddUpdatePayload>) => { },
        updatePatientSuccess: (state, { payload }) => {},
        getDoctorsBySearch: (state, {payload}: PayloadAction<DoctorsSearch>) => { },
        getDoctorsBySearchSuccess: (state, { payload }: PayloadAction<DoctorsListModel[]>) => {
            state.doctorsList = payload;
        },
        getHccCodesBySearch: (state, {payload}: PayloadAction<HccCodesSearch>) => { },
        getHccCodesBySearchSuccess: (state, { payload }: PayloadAction<HccCodeListModel[]>) => {
            state.hccCodeListBySearch = payload;
        },
        getStateList: (state) => { },
        getStateListSuccess: (state, { payload }: PayloadAction<ISelectOptions[]>) => {
            state.stateList = payload;
        },
    },
});

const {
    getPatientsList,
    getPatientsListSuccess,
    getPatient,
    getPatientSuccess,
    addPatient,
    addPatientSuccess,
    updatePatient,
    updatePatientSuccess,
    getDoctorsBySearch,
    getDoctorsBySearchSuccess,
    getHccCodesBySearch,
    getHccCodesBySearchSuccess,
    getStateList,
    getStateListSuccess,
    deletePatients,
    deletePatientsSuccess,

} = survey.actions;

export {
    getPatientsList,
    getPatientsListSuccess,
    getPatient,
    getPatientSuccess,
    addPatient,
    addPatientSuccess,
    updatePatient,
    updatePatientSuccess,
    getDoctorsBySearch,
    getDoctorsBySearchSuccess,
    getHccCodesBySearch,
    getHccCodesBySearchSuccess,
    getStateList,
    getStateListSuccess,
    deletePatients,
    deletePatientsSuccess,
};

export default survey.reducer;
