import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { NotificationManager } from "react-notifications";
import {
  getQuestionTypes,
  getQuestionTypesSuccess,
  getQuestionCategory,
  getQuestionCategorySuccess,
  getTemplateCategory,
  getTemplateCategorySuccess,
  getColorType,
  getColorTypeSuccess,
  createTemplate,
  updateTemplate,
  createTemplateCategory,
  createTemplateCategorySuccess,
  getTemplates,
  getTemplatesSuccess,
  deleteTemplateById,
  copyTemplateById,
  sendPreviewSurvey,
  sendPreviewSurveySuccess,
} from './templatesSlice';
import Api from '../../services/api';
import { CopyTemplatePayload, DeletePayload, MainForm, MainFormPayload, NewTemplateCategory, NewTemplateCategoryPayload, TemplatePreviewSaveModel, TemplatesPayload } from '../../models/templates/templates.model';

function* getQuestionTypesSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getQuestionTypes.toString());

    try {
      const response = yield call(Api.post, `template/common-elements-list`);
      if (response) {
        yield put(getQuestionTypesSuccess(response.data.resource));
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getQuestionCategorySaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getQuestionCategory.toString());

    try {
      const response = yield call(Api.post, `template/contributing-factor-list`);
      if (response) {
        yield put(getQuestionCategorySuccess(response.data.resource));
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getTemplateCategorySaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getTemplateCategory.toString());

    try {
      const response = yield call(Api.post, `category/list`);
      if (response) {
        yield put(getTemplateCategorySuccess(response.data.resource));
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createTemplateCategorySaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {data, callback} }: { payload: NewTemplateCategoryPayload } = yield take(createTemplateCategory.toString());

    try {
      const response = yield call(Api.post, `category/add`, data);
      if (response) {
        yield put(createTemplateCategorySuccess(response.data.resource));
        callback();
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getColorTypeSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    yield take(getColorType.toString());

    try {
      const response = yield call(Api.post, `type-color/list`);
      if (response) {
        yield put(getColorTypeSuccess(response.data.resource));
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createTemplateSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {data, callback} }: { payload: MainFormPayload } = yield take(createTemplate.toString());

    try {
      const response = yield call(Api.post, `template/add`, data);
      if (response) {
        // yield put(createTemplateSuccess(response.data));
        callback(response.data.resource);
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateTemplateSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {data, callback} }: { payload: MainFormPayload } = yield take(updateTemplate.toString());

    try {
      const response = yield call(Api.put, `template/update`, undefined, data);
      if (response) {
        callback();
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getTemplatesSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {data, page, callback} }: { payload: TemplatesPayload } = yield take(getTemplates.toString());

    try {
      const response = yield call(Api.post, `template/list?page=${page}`, data);
      if (response) {
        yield put(getTemplatesSuccess(response.data.resource));
        if (callback){
        callback({message:'success'});
      }
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
      if (e.response.status === 403) {
        if (callback){
          callback(e.response.data);
        }
      }
    }
  }
}

function* copyTemplateByIdSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {id, callback} }: { payload: CopyTemplatePayload } = yield take(copyTemplateById.toString());

    try {
      const response = yield call(Api.post, `template/copy`, {id});
      if (response) {
        callback();
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteTemplateByIdSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const { payload: {ids, callback} }: { payload: DeletePayload } = yield take(deleteTemplateById.toString());

    try {
      const response = yield call(Api.delete, `template/delete`, {ids});
      if (response) {
      callback();
      }
    } catch (e) {
      console.log('error message222', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* sendPreviewSurveySaga(): Generator<StrictEffect, void, any> {
  while (true) {
      const { payload: data}: {payload: TemplatePreviewSaveModel} = yield take(sendPreviewSurvey.toString());

      try {
          const response = yield call(Api.post, `questionnaire/save-preview`, data);
          if (response) {
              yield put(sendPreviewSurveySuccess(response));
          }
      } catch (e) {
          console.log('error message222', e.response);
          // if (e.response.status === 400) {
          //   callback(e.response.data.errors);
          // }
      }
  }
}

export function* saga() {
  yield all([
    getQuestionTypesSaga(),
    getQuestionCategorySaga(),
    getTemplateCategorySaga(),
    getColorTypeSaga(),
    createTemplateSaga(),
    updateTemplateSaga(),
    createTemplateCategorySaga(),
    getTemplatesSaga(),
    copyTemplateByIdSaga(),
    deleteTemplateByIdSaga(),
    sendPreviewSurveySaga()
  ]);
}
