import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeletePayload, ManageUsersPayload, UsersList, UserById, UserByIdPayload, UserAddUpdatePayload } from '../../models';

export interface InitialState {
  usersList: UsersList | null;
}

const initialState: InitialState = {
  usersList: null,
};

const manageUsers = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    getUsersList: (state, { payload }: PayloadAction<ManageUsersPayload>) => { },
    getUsersListSuccess: (state, { payload }: PayloadAction<UsersList>) => {
      state.usersList = payload;
    },

    getUserById: (state, { payload }: PayloadAction<UserByIdPayload>) => { },

    deleteUserById: (state, { payload }: PayloadAction<DeletePayload>) => { },
    addUser: (state, { payload }: PayloadAction<UserAddUpdatePayload>) => { },
    updateUser: (state, { payload }: PayloadAction<UserAddUpdatePayload>) => { },
  },
});

const {
  getUsersList,
  getUsersListSuccess,
  getUserById,
  deleteUserById,
  addUser,
  updateUser,
} = manageUsers.actions;

export {
  getUsersList,
  getUsersListSuccess,
  getUserById,
  deleteUserById,
  addUser,
  updateUser,
};

export default manageUsers.reducer;
