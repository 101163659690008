import React, {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import { HeaderSearch } from '../../common';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SurveyContainer from './SurveyContainer';
import {RootState} from "../../../store/reducers";
import {getSurvey, getSurveyRelated, getSurveyHistory, clearSurvey} from "../../../store/survey/surveySlice";
import {useParams} from "react-router-dom";
import {KeyValueModel, SurveySaveModel} from "../../../models";
import { clearChartPreview } from '../../../store/templates/templatesSlice';

const SurveyPage = () => {
    const dispatch = useDispatch();

    const {token} = useParams<KeyValueModel<string>>();

    const template = useSelector((state: RootState) => state.survey.survey?.template);
    const questionnaire = useSelector((state: RootState) => state.survey.survey?.questionnaire);
    const surveyChart = useSelector((state: RootState) => state.survey.surveyChart);
    const surveyRelated = useSelector((state: RootState) => state.survey.related);
    const surveyHistory = useSelector((state: RootState) => state.survey.history);
    const surveyChartPreview = useSelector((state: RootState) => state.templates.previewScore);

    // useEffect(() => {
    //     if (questionnaire?.token !== token) {
    //         dispatch(getSurvey({token}));
    //     }
    // }, []);

    useEffect(() => {
        if (questionnaire) {
            dispatch(getSurveyRelated({user_id: questionnaire.patient.id ,template_id: questionnaire.template_id}));
            
        }
    }, [questionnaire]);

    useEffect(() => {
        dispatch(clearSurvey());
        dispatch(clearChartPreview());
        dispatch(getSurvey({token}));
        dispatch(getSurveyHistory({token}));
    }, []);

    useEffect(() => {
        dispatch(getSurveyHistory({token}));
    }, [surveyChart]);

    return (
        <div className="b-mainContainer__wrapper">
            <HeaderSearch
                pageName='Surveys'
                isSearhVisible={false}
            />
            {template && questionnaire ? (
                <SurveyContainer
                    template={template}
                    questionnaire={questionnaire}
                    token={token}
                    surveyChart={surveyChart}
                    surveyChartPreview={surveyChartPreview}
                    surveyRelated={surveyRelated}
                    surveyHistory={surveyHistory}
                />
            ) : null }
        </div>
    );
};

export default SurveyPage;