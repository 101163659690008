import {KeyValueModel} from "../models";

export interface ICustomFields {
    [custom: string]: string;
}

interface Validator {
    required?: Array<string>;
    custom?: any;
}

export const validate = <T = KeyValueModel<string>>(validator: Validator, fields: T): {errors: KeyValueModel<string>} => {
    if (validator) {
        let errors: KeyValueModel<string> = {};
        let unfilled = validator && validator.required && validator.required.filter(
            (field: string) => !fields[field]
        );
        if (validator.custom) {
            const result = validator.custom.map(
                ({error, validate, fields: validationFields}: any) => {
                    const valid = validate(
                        ...validationFields.map((field: string) => fields[field])
                    );
                    const errors: KeyValueModel<string> = {};
                    validationFields.forEach((item: string) => {
                        errors[item] = error;
                    });
                    return valid ? null : errors;
                }
            );
            const customErrors = result.filter(Boolean);
            if (customErrors.length) {
                customErrors.forEach((error: string) => {
                    errors = Object.assign(errors, error);
                });
            }
        }
        if (unfilled && unfilled.length) {
            unfilled.forEach((field: string) => {
                errors[field] = 'Please fill required field';
            });
        }
        return {errors};
    }
    return {errors: {}};
};

export const email = (fields: Array<string>) => ({
    error: 'Incorrect email format',
    validate: validateEmail,
    fields,
});

export const phoneFormat = (fields: Array<string>) => ({
    error: 'Phone format must be XXX-XXX-XXXX',
    validate: validatePhoneFormat,
    fields,
});

export const ssn = (fields: Array<string>) => ({
    error: 'SSN format must be XXX-XX-XXXX',
    validate: validateSSN,
    fields,
});

export const samePasswords = (fields: Array<string>) => ({
    error: 'Passwords must be same',
    validate: (password: string, repeat: string) => password === repeat,
    fields,
});

export const httpUrl = (fields: Array<string>) => ({
    error: 'Enter a valid URL',
    validate: validateURL,
    fields,
});

export const password = (fields: Array<string>) => ({
    error: 'Passwords must contain at least 8 characters',
    validate: validatePassword,
    fields,
});

export const activityTime = (start: Date | null, end: Date | null, fields: Array<string>) => ({
    error: 'Start time is required',
    validate: () => validateActivityTime(start, end),
    fields,
});

export const phone = (fields: Array<string>) => ({
    error: 'Number must be 10 digits',
    validate: validatePhone,
    fields,
});

export const zip = (fields: Array<string>) => ({
    error: 'Zip Code must be 5 digits',
    validate: validateZip,
    fields,
});

export const ssNumber = (fields: Array<string>) => ({
    error: 'SS Number must be 9 digits',
    validate: validateSsNumber,
    fields,
});

export const weight = (fields: Array<string>) => ({
    error: 'Weight must be from 0 to 10',
    validate: validateWeight,
    fields,
});

const validateWeight = (weight: string) => {
    const re = /^([0-9]|1[0])$/;
    return re.test(weight);
};

const validateSsNumber = (ssNumber: string) => {
    ssNumber = ssNumber.split('-').join('');
    const re = /\b\d{9}\b/;
    return re.test(ssNumber);
};

const validateZip = (zip: string) => {
    const re = /\b\d{5}\b/;
    return re.test(zip);
};

const validatePhone = (phone: string) => {
    const re = /\b\d{10}\b/;
    return re.test(phone);
};

const validatePassword = (password: string) => {
    const re = /^.*.{8,20}$/;
    return re.test(password);
};

const validateURL = (url: string) => {
    const re = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i');
    return re.test(url);
};

const validateEmail = (email: string) => {
    /* eslint-disable no-useless-escape */
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const validatePhoneFormat = (phone: string) => {
    /* eslint-disable no-useless-escape */
    const re = /^\d{3}-\d{3}-\d{4}$/;
    return re.test(phone);
};

const validateSSN = (ssn: string) => {
    /* eslint-disable no-useless-escape */
    const re = /^\d{3}-\d{2}-\d{4}$/;
    return re.test(ssn);
};

const validateActivityTime = (start_time: Date | null, end_time: Date | null) => {
    return !(start_time === null && end_time !== null)
}
