import React, { useState } from 'react';
import { HeaderSearch } from '../../common'
import Modal from "../../common/Modal/Modal"

import guideImg_1 from "../../../assets/images/guides/Advanced-Care-Planning-1.jpg";
import guideImg_2 from "../../../assets/images/guides/Advanced-Care-Planning-2.jpg";
import asthma_1 from "../../../assets/images/guides/Asthma-1.jpg";
import cellulitis_1 from "../../../assets/images/guides/Cellulitis-1.jpg";
import cellulitis_2 from "../../../assets/images/guides/Cellulitis-2.jpg";
import ckdm_1 from "../../../assets/images/guides/Chronic-Kidney-Disease-Management-1.jpg";
import ckdm_2 from "../../../assets/images/guides/Chronic-Kidney-Disease-Management-2.jpg";
import cap_1 from "../../../assets/images/guides/Community-Acquired-Pneumonia-1.jpg";
import chf_1 from "../../../assets/images/guides/Congestive-Heart-Failure-1.jpg";
import chf_2 from "../../../assets/images/guides/Congestive-Heart-Failure-2.jpg";
import copda_1 from "../../../assets/images/guides/COPD-Acute.jpg";
import copds_1 from "../../../assets/images/guides/COPD-Stable-1.jpg";
import copds_2 from "../../../assets/images/guides/COPD-Stable-2.jpg";
import dm_1 from "../../../assets/images/guides/Diabetes-Mellitus-1.jpg";
import dm_2 from "../../../assets/images/guides/Diabetes-Mellitus-2.jpg";
import gf_1 from "../../../assets/images/guides/Diabetic-Foot-1.jpg";
import dyslipidemia_1 from "../../../assets/images/guides/Dyslipidemia-1.jpg";
import dyslipidemia_2 from "../../../assets/images/guides/Dyslipidemia-2.jpg";
import hp_1 from "../../../assets/images/guides/Heel-Pain-1.jpg";
import hypertension_1 from "../../../assets/images/guides/Hypertension-1.jpg";
import hypertension_2 from "../../../assets/images/guides/Hypertension-2.jpg";
import md_1 from "../../../assets/images/guides/Major-Depression.jpg";
import migraine_1 from "../../../assets/images/guides/Migraine-1.jpg";
import migraine_2 from "../../../assets/images/guides/Migraine-2.jpg";
import obesity_1 from "../../../assets/images/guides/Obesity-1.jpg";
import obesity_2 from "../../../assets/images/guides/Obesity-2.jpg";
import os_1 from "../../../assets/images/guides/Office-Sedation-1.jpg";
import osteoarthritis_1 from "../../../assets/images/guides/Osteoarthritis-1.jpg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactImageMagnify from 'react-image-magnify';

type AccordionOpen = {
  index_0: boolean,
  index_1: boolean,
  index_2: boolean,
  index_3: boolean,
  index_4: boolean,
  index_5: boolean,
  index_6: boolean,
  index_7: boolean,
  index_8: boolean,
  index_9: boolean,
  index_10: boolean,
  index_11: boolean,
  index_12: boolean,
  index_13: boolean,
  index_14: boolean,
  index_15: boolean,
  index_16: boolean,
  index_17: boolean,
};

interface IState {
  modalType: 
      'advanced_1' | 
      'advanced_2' | 
      'asthma_1' | 
      'cellulitis_1' |
      'cellulitis_2' |
      'ckdm_1' | 
      'ckdm_2' | 
      'cap_1' | 
      'chf_1' | 
      'chf_2' | 
      'copda_1' |
      'copds_1' |
      'copds_2' |
      'dm_1' |
      'dm_2' |
      'gf_1' |
      'dyslipidemia_1' |
      'dyslipidemia_2' |
      'hp_1' |
      'hypertension_1' |
      'hypertension_2' |
      'md_1' |
      'migraine_1' |
      'migraine_2' |
      'obesity_1' |
      'obesity_2' |
      'os_1' |
      'osteoarthritis_1' | null,
}

const ClinicalPracticeGuides = () => {
  
  const initialState: IState = {
    modalType: null,
  };
  const initialAccordionOpen = {
    index_0: false,
    index_1: false,
    index_2: false,
    index_3: false,
    index_4: false,
    index_5: false,
    index_6: false,
    index_7: false,
    index_8: false,
    index_9: false,
    index_10: false,
    index_11: false,
    index_12: false,
    index_13: false,
    index_14: false,
    index_15: false,
    index_16: false,
    index_17: false,
  };

  let [state, setState] = useState<IState>(initialState);
  const [accordionOpen, setOpen] = useState<AccordionOpen>(initialAccordionOpen);

  const handleModalOpen = (type: 
                               'advanced_1' | 
                               'advanced_2' | 
                               'asthma_1' | 
                               'cellulitis_1' | 
                               'cellulitis_2' | 
                               'ckdm_1' | 
                               'ckdm_2' | 
                               'cap_1' | 
                               'chf_1' | 
                               'chf_2' | 
                               'copda_1' |
                               'copds_1' |
                               'copds_2' |
                               'dm_1' |
                               'dm_2' |
                               'gf_1' |
                               'dyslipidemia_1' |
                               'dyslipidemia_2' |
                               'hp_1' |
                               'hypertension_1' |
                               'hypertension_2' |
                               'md_1' |
                               'migraine_1' |
                               'migraine_2' |
                               'obesity_1' |
                               'obesity_2' |
                               'os_1' |
                               'osteoarthritis_1' | null ) => () => {
    setState({...state, modalType: type})
  };
  const handleCloseModal = () => {
    setState({...state, modalType: null})
  };

  return (
      <div className={'b-mainContainer__wrapper'}>
        <HeaderSearch
            pageName='Clinical Practice Guides'
            isSearhVisible={false}
        />
        <div className={'b-mainContainer__wrapperInner'}>
          <div className={'b-mainContainer__contentWrapper'}>
            {/* accordion start */}
            <div className="b-accordion">
              <div className={`b-accordion__item ${accordionOpen.index_0 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_0: !accordionOpen.index_0 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Advance Care Planning and Provision of Palliative Care For Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={guideImg_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('advanced_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={guideImg_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('advanced_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Advanced_Care_Planning.pdf" download>Download PDF</a>


                      {state.modalType === 'advanced_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: guideImg_1,
                            },
                            largeImage: {
                              src: guideImg_1,
                              width: 1000,
                              height: 1400
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'advanced_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: guideImg_2,
                            },
                            largeImage: {
                              src: guideImg_2,
                              width: 1000,
                              height: 1400
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>

              <div className={`b-accordion__item ${accordionOpen.index_1 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_1: !accordionOpen.index_1 })}>
                  <span className="b-accordion__title">Asthma</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={asthma_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('asthma_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Asthma.pdf" download>Download PDF</a>


                      {state.modalType === 'asthma_1' && <Modal
                          title=' '
                          width={800}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: asthma_1,
                            },
                            largeImage: {
                              src: asthma_1,
                              width: 1500,
                              height: 2000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_2 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_2: !accordionOpen.index_2 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Primary Care Treatment of Outpatient Skin and Soti Tissue Infections</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={cellulitis_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('cellulitis_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={cellulitis_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('cellulitis_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Cellulitis.pdf" download>Download PDF</a>

                      {state.modalType === 'cellulitis_1' && <Modal
                          title=' '
                          width={800}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: cellulitis_1,
                            },
                            largeImage: {
                              src: cellulitis_1,
                              width: 1200,
                              height: 1200
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'cellulitis_2' && <Modal
                          title=' '
                          width={800}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: cellulitis_2,
                            },
                            largeImage: {
                              src: cellulitis_2,
                              width: 1200,
                              height: 1200
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>

              <div className={`b-accordion__item ${accordionOpen.index_3 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_3: !accordionOpen.index_3 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines For Primary Care Diagnosis and Management of Chronic Kidney Disease in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={ckdm_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('ckdm_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={ckdm_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('ckdm_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Chronic_Kidney_Disease_Management.pdf" download>Download PDF</a>


                      {state.modalType === 'ckdm_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: ckdm_1,
                            },
                            largeImage: {
                              src: ckdm_1,
                              width: 1000,
                              height: 1000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'ckdm_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: ckdm_2,
                            },
                            largeImage: {
                              src: ckdm_2,
                              width: 1000,
                              height: 1000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>

              <div className={`b-accordion__item ${accordionOpen.index_4 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_4: !accordionOpen.index_4 })}>
                  <span className="b-accordion__title">Outpatient Community Acquired Pneumonia Assessment and Treatment</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={cap_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('cap_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Community_Acquired_Pneumonia.pdf" download>Download PDF</a>


                      {state.modalType === 'cap_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: cap_1,
                            },
                            largeImage: {
                              src: cap_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>

              <div className={`b-accordion__item ${accordionOpen.index_5 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_5: !accordionOpen.index_5 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Primary Care Management of Adults With Systolic Heart Failure</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={chf_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('chf_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={chf_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('chf_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Congestive_Heart_Failure.pdf" download>Download PDF</a>

                      {state.modalType === 'chf_1' && <Modal
                          title=' '
                          width={800}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: chf_1,
                            },
                            largeImage: {
                              src: chf_1,
                              width: 1000,
                              height: 800
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'chf_2' && <Modal
                          title=' '
                          width={800}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: chf_2,
                            },
                            largeImage: {
                              src: chf_2,
                              width: 1000,
                              height: 800
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>

              <div className={`b-accordion__item ${accordionOpen.index_6 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_6: !accordionOpen.index_6 })}>
                  <span className="b-accordion__title">Management of Acute Exacerbation of COPD</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={copda_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('copda_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/COPD_Acute.pdf" download>Download PDF</a>

                      {state.modalType === 'copda_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: copda_1,
                            },
                            largeImage: {
                              src: copda_1,
                              width: 1000,
                              height: 1000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_7 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_7: !accordionOpen.index_7 })}>
                  <span className="b-accordion__title">Clinicl Practice Guidelines for Management of Stable COPD</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={copds_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('copds_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={copds_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('copds_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/COPD_Stable.pdf" download>Download PDF</a>

                      {state.modalType === 'copds_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: copds_1,
                            },
                            largeImage: {
                              src: copds_1,
                              width: 1000,
                              height: 1000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'copds_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: copds_2,
                            },
                            largeImage: {
                              src: copds_2,
                              width: 1000,
                              height: 1000
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_8 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_8: !accordionOpen.index_8 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Primary Care Management of Diabetes Mellitus in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={dm_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('dm_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={dm_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('dm_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Diabetes_Mellitus.pdf" download>Download PDF</a>

                      {state.modalType === 'dm_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: dm_1,
                            },
                            largeImage: {
                              src: dm_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'dm_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: dm_2,
                            },
                            largeImage: {
                              src: dm_2,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_9 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_9: !accordionOpen.index_9 })}>
                  <span className="b-accordion__title">Clinical Practice Guideline for Podiatry Management of Diabetic Foot Infections</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={gf_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('gf_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Diabetic_Foot.pdf" download>Download PDF</a>

                      {state.modalType === 'gf_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: gf_1,
                            },
                            largeImage: {
                              src: gf_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div> 
              
              <div className={`b-accordion__item ${accordionOpen.index_10 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_10: !accordionOpen.index_10 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Primary Care Management and Treatment of Dyslipidemias</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={dyslipidemia_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('dyslipidemia_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={dyslipidemia_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('dyslipidemia_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Dyslipidemia.pdf" download>Download PDF</a>

                      {state.modalType === 'dyslipidemia_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: dyslipidemia_1,
                            },
                            largeImage: {
                              src: dyslipidemia_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'dyslipidemia_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: dyslipidemia_2,
                            },
                            largeImage: {
                              src: dyslipidemia_2,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_11 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_11: !accordionOpen.index_11 })}>
                  <span className="b-accordion__title">Clinical Practice Guideline For Podiatry Diagnosis And Treatment of Heel Pain</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={hp_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('hp_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Heel_Pain.pdf" download>Download PDF</a>

                      {state.modalType === 'hp_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: hp_1,
                            },
                            largeImage: {
                              src: hp_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_12 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_12: !accordionOpen.index_12 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines For Primary Care Management of Hypertension in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={hypertension_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('hypertension_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={hypertension_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('hypertension_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Hypertension.pdf" download>Download PDF</a>

                      {state.modalType === 'hypertension_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: hypertension_1,
                            },
                            largeImage: {
                              src: hypertension_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'hypertension_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: hypertension_2,
                            },
                            largeImage: {
                              src: hypertension_2,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_13 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_13: !accordionOpen.index_13 })}>
                  <span className="b-accordion__title">Primary Care Diagnosis and Management of Adults with Depression</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={md_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('md_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Major_Depression.pdf" download>Download PDF</a>

                      {state.modalType === 'md_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: md_1,
                            },
                            largeImage: {
                              src: md_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_14 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_14: !accordionOpen.index_14 })}>
                  <span className="b-accordion__title">Clinical Practice Guideline For Primary Care Treatment and Prevention of Migraine in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={migraine_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('migraine_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={migraine_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('migraine_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Migraine.pdf" download>Download PDF</a>

                      {state.modalType === 'migraine_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: migraine_1,
                            },
                            largeImage: {
                              src: migraine_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'migraine_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: migraine_2,
                            },
                            largeImage: {
                              src: migraine_2,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_15 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_15: !accordionOpen.index_15 })}>
                  <span className="b-accordion__title">Screening and Primary Care Management of Obesity in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={obesity_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('obesity_1')}>
                          </button>
                        </div>
                        <div className="slideWrapper">
                          <img src={obesity_2} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('obesity_2')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Obesity.pdf" download>Download PDF</a>

                      {state.modalType === 'obesity_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: obesity_1,
                            },
                            largeImage: {
                              src: obesity_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                      {state.modalType === 'obesity_2' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: obesity_2,
                            },
                            largeImage: {
                              src: obesity_2,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_16 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_16: !accordionOpen.index_16 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines For Office Based Anesthesia</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={os_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('os_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Office_Sedation.pdf" download>Download PDF</a>

                      {state.modalType === 'os_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: os_1,
                            },
                            largeImage: {
                              src: os_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className={`b-accordion__item ${accordionOpen.index_17 ? '--opened' : ''}`}>
                <div className="b-accordion__opener" onClick={() => setOpen({...accordionOpen, index_17: !accordionOpen.index_17 })}>
                  <span className="b-accordion__title">Clinical Practice Guidelines for Primary Care Management and Treatment of Osteoarthritis in Adults</span>
                  <span className="b-accordion__arrow">
                            </span>
                </div>
                <div className="b-accordion__content">
                  <div className="b-accordion__contentHolder">
                    <div className="b-accordion__contentIllus">

                      <Carousel>
                        <div className="slideWrapper">
                          <img src={osteoarthritis_1} alt=""/>
                          <button className="zoomBtn" onClick={handleModalOpen('osteoarthritis_1')}>
                          </button>
                        </div>
                      </Carousel>

                      <a className='b-accordion__downloadLink' href="/files/guide/Osteoarthritis.pdf" download>Download PDF</a>

                      {state.modalType === 'osteoarthritis_1' && <Modal
                          title=' '
                          width={500}
                          onClose={handleCloseModal}>
                        <div className={'b-modal b-guide'}>
                          <ReactImageMagnify {...{
                            smallImage: {
                              isFluidWidth: true,
                              src: osteoarthritis_1,
                            },
                            largeImage: {
                              src: osteoarthritis_1,
                              width: 1000,
                              height: 1500
                            },
                            enlargedImagePosition: 'over',
                            isHintEnabled: true,
                            shouldHideHintAfterFirstActivation: false
                          }} />
                        </div>
                      </Modal> }
                    </div>
                  </div>
                </div>
              </div>
              </div>
              {/* accordion end */}
          </div>
        </div>
      </div>
  );
};

export default ClinicalPracticeGuides;
