import React, { FC, useState, useEffect } from 'react';
import styles from './HccListView.module.scss'

import {useHistory} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { HeaderSearch } from '../../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Button from "../../../controls/Button/Button";
import Modal from "../../../common/Modal/Modal";
import DatePicker from "react-datepicker";

import {
  PaginationModel,
  HccCodesTableFields,
  HccCodesUpdateModel,
  HccCodesAddModel,
} from "../../../../models";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import Pagination from "../../../common/Pagination/Pagination";
import Input from "../../../controls/Input/Input";
import { addHccCode, deleteHccCode, getAllHccCodeList, updateHccCode } from '../../../../store/hccCodes/hccCodesSlice';
import moment from 'moment';

interface IState {
  modalType: 'create-code' | 'update-code' | 'create-success' | 'update-success' | 'create-error' | 'permissions-fail' | null,
}

const initialState: IState = {
  modalType: null,
};


const HccList = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const allHccCodesList: PaginationModel<HccCodesTableFields[]> | null = useSelector(
    (state: RootState) => state.hccCodes.allHccCodesList,
  );

  const [hccCodesTable, setHccCodesTable] = useState<HccCodesTableFields[] | []>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [editHccCode, setEditHccCode] = useState<HccCodesAddModel | HccCodesUpdateModel>({});
  // const [permissionsError, setpermissionsError] = useState<string>(`You don't have permissions to manage Hcc Codes`);
  const [deleteOneItem, setDeleteOneItem] = useState<number[]>([]);

  let [state, setState] = useState<IState>(initialState);

  const handleCloseModal = () => {
    setState({ ...state, modalType: null });
    setEditHccCode({});
  };

  const handleOpenCreateCode = () => {
    setState({ ...state, modalType: 'create-code' });

    setEditHccCode({
      code: '',
      diagnostic: '',
      created_at: new Date(),
    });
  };

  const handleOpenUpdateCode = (id: number) => {
    setState({ ...state, modalType: 'update-code' });
    setEditHccCode(hccCodesTable?.filter((item) => item.id === id)[0]);
  };

  useEffect(() => {
    dispatch(getAllHccCodeList({ data: {}, page: currentPage }));
  }, [currentPage]);

  useEffect(() => {
    if (allHccCodesList) {
      setHccCodesTable(allHccCodesList?.data);
    }
  }, [allHccCodesList]);

  const createCode = (creatableHccCode: HccCodesAddModel) => {
    dispatch(
      addHccCode({
        data: {
          code: creatableHccCode.code,
          diagnostic: creatableHccCode.diagnostic,
          created_at: moment(creatableHccCode.created_at).format('DD-MM-YYYY')
        },
        callback: () => {
          currentPage === allHccCodesList?.last_page
          ? dispatch(getAllHccCodeList({ data: {}, page: currentPage }))
          : setCurrentPage(allHccCodesList?.last_page || 1);
          setState({ ...state, modalType: null });
          setEditHccCode({});
        },
      }),
    );
  };

  const updateCode = (editableHccCode: HccCodesUpdateModel) => {
    dispatch(
      updateHccCode({
        data: {
          id: editableHccCode.id,
          code: editableHccCode.code,
          diagnostic: editableHccCode.diagnostic,
          created_at: moment(new Date(editableHccCode.created_at.split('-').join('/'))).format('DD-MM-YYYY')
        },
        callback: () => {
          dispatch(getAllHccCodeList({ data: {}, page: currentPage }));
          setState({ ...state, modalType: null });
          setEditHccCode({});
        },
      }),
    );
  };

  const updateDateOfCreateHcc = (hccCode: HccCodesUpdateModel, value: any) => {
    dispatch(
      updateHccCode({
        data: {
          id: hccCode.id,
          code: hccCode.code,
          diagnostic: hccCode.diagnostic,
          created_at: moment(new Date(value.split('-').join('/'))).format('DD-MM-YYYY')
        },
      }),
    );
  };

  const deleteCode = (deleteOneItem: number[]) => {
    dispatch(
      deleteHccCode({
        data: { ids: deleteOneItem },
        callback: () => {
          dispatch(getAllHccCodeList({ data: {}, page: currentPage }));
        },
      }),
    );
    setDeleteOneItem([]);
    handleCloseModal();
  };

  const dateOfCreateHeader = (
    <div>
      <span className="p-column-title">Date created</span>
    </div>
  );

  const dateOfUpdateHeader = (
    <div>
      <span className="p-column-title">Date updated</span>
    </div>
  );

  const codeHeader = (
    <div>
      <span className="p-column-title">Code</span>
    </div>
  );

  const diagnosticHeader = (
    <div>
      <span className="p-column-title">Diagnostic</span>
    </div>
  );

  const hccDateOfCreate = (rowData: any) => {
    return (
      <DatePicker
        maxDate={new Date()}
        dateFormat={'MM.dd.yyyy'}
        selected={new Date(rowData.created_at.split('-').join('/'))}
        className={'b-form__datePikerInput'}
        onChange={(value) => {
          setHccCodesTable((prevState) => {
            const table = prevState.map((item) =>
              item.id === rowData.id
                ? {
                    ...item,
                    created_at:
                      (value && moment(value).format('MM-DD-YYYY')) ||
                      moment(new Date()).format('MM-DD-YYYY'),
                  }
                : item,
            );
            return table;
          });
          updateDateOfCreateHcc(
            rowData,
            (value && moment(value).format('MM-DD-YYYY')) ||
              moment(new Date()).format('MM-DD-YYYY'),
          );
        }}
      />
    );
  };

  const hccDateOfUpdate = (rowData: any) => {
    return <span>{rowData.updated_at}</span>;
  };

  const hccCodeBody = (rowData: any) => {
    return <span>{rowData.code}</span>;
  };

  const hccDiagnostic = (rowData: any) => {
    return <span>{rowData.diagnostic}</span>;
  };

  const hccEditBody = (rowData: any) => {
    return (
      <div className={styles.hccControl}>
        <button
          className={styles.hccEditLink}
          aria-label="Edit"
          data-tip
          data-for="edit"
          onClick={() => handleOpenUpdateCode(rowData.id)}>
          <ReactTooltip id="edit" className="b-tooltip">
            Edit
          </ReactTooltip>
        </button>
        <button
          className={styles.hccDelete}
          aria-label="Remove"
          data-tip
          data-for="remove"
          onClick={() => setDeleteOneItem([rowData.id])}>
          <ReactTooltip id="remove" className="b-tooltip">
            Remove
          </ReactTooltip>
        </button>
      </div>
    );
  };

  const footer = (
    <div className="patientControlList">
      <Button
        title={'back'}
        type={'transparency'}
        className={'-uppercase'}
        width={'100px'}
        onPress={() => history.goBack()}
      />
      <Button
        title={'create code'}
        className={'-uppercase '}
        width={'145px'}
        onPress={handleOpenCreateCode}
      />
    </div>
  );

  return (
   <>
    { allHccCodesList ?
    <div className={'b-mainContainer__wrapper'}>
      <HeaderSearch
        pageName='HCC Codes'
        isSearhVisible={true}
      />
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={`b-mainContainer__contentWrapper`}>
          <DataTable 
            value={hccCodesTable}
            className='-paginator'
            resizableColumns={true}
            style={{marginTop: '10px'}}
            footer={footer}
            >
            <Column style={{width: 100}} field='first_name' header={codeHeader} body={hccCodeBody} />
            <Column field='last_name' header={diagnosticHeader} body={hccDiagnostic} />
            <Column className='' style={{width: 170}} field='created_at' header={dateOfCreateHeader} body={hccDateOfCreate} />
            <Column className='' style={{width: 130}} field='updated_at' header={dateOfUpdateHeader} body={hccDateOfUpdate} />
            <Column style={{width: 100}} field='edit' body={hccEditBody} />
            {/* <Column style={{width: 150}} field='edit' header={headerEdit} body={hccEditBody} /> */}
          </DataTable>
          <Pagination
              displayTitle={'HCC codes'}
              data={allHccCodesList}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      {state.modalType === 'create-code' && <Modal
          title='Create Code'
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
        <div className={'b-form__smallRow'}>
          <div className={'b-form__formGroup'}>
            <div className={'b-form__datePikerLabel'}>
              Date create *
            </div>
            <DatePicker
                maxDate={new Date()}
                dateFormat={'MM.dd.yyyy'}
                selected={editHccCode.created_at}
                className={'b-form__datePikerInput'}
                // error={errors?.patient_profiles.dob}
                showYearDropdown
                dropdownMode="select"
                onChange={value => setEditHccCode({...editHccCode, created_at: value})}/>
          </div>
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={editHccCode.code || ''}
            label={'Code *'}
            onChange={(value) => 
              setEditHccCode((prevState: any) => {
              const newState = {...prevState};
              newState.code = value;
              return newState
            })} 
          />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={editHccCode.diagnostic || ''}
            label={'Diagnostic *'}
            onChange={(value) => 
              setEditHccCode((prevState: any) => {
              const newState = {...prevState};
              newState.diagnostic = value;
              return newState
            })} 
          />
        </div>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal}
             type={'transparency'} width='100px'/>
            <Button title={'Create'}
            disabled={editHccCode && (!!editHccCode.code === false || !!editHccCode.diagnostic === false)} 
            onPress={() => createCode(editHccCode)}
             className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'update-code' && <Modal
          title='Update Code'
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
        <div className={'b-form__smallRow'}>
          <div className={'b-form__formGroup'}>
            <div className={'b-form__datePikerLabel'}>
              Date create *
            </div>
            <DatePicker
                maxDate={new Date()}
                dateFormat={'MM.dd.yyyy'}
                selected={new Date(editHccCode.created_at.split('-').join('/'))}
                className={'b-form__datePikerInput'}
                // error={errors?.patient_profiles.dob}
                showYearDropdown
                dropdownMode="select"
                onChange={value => setEditHccCode({...editHccCode, created_at: moment(value).format('MM-DD-YYYY')})}/>
          </div>
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={editHccCode.code || ''}
            label={'Code *'}
            onChange={(value) => 
              setEditHccCode((prevState: any) => {
              const newState = {...prevState};
              newState.code = value;
              return newState
            }
            )} 
          />
        </div>
        <div className={'b-form__formGroup'}>
          <Input
            value={editHccCode.diagnostic || ''}
            label={'Diagnostic *'}
            onChange={(value) => 
              setEditHccCode((prevState: any) => {
              const newState = {...prevState};
              newState.diagnostic = value;
              return newState
            })} 
          />
        </div>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal}
             type={'transparency'} width='100px'/>
            <Button title={'Update'} disabled={editHccCode && (!!editHccCode.code === false || !!editHccCode.diagnostic === false)} 
            onPress={() => updateCode(editHccCode)}
             className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
    </div>
    : null}
    {/* {state.modalType === 'permissions-fail' && <Modal
      title='Error'
      width={520}
      onClose={() => {
        setState({...state, modalType: null}),
        history.push(Routes.DASHBOARD);
      }}>
      <div className={'b-modal'}>
        <div className={'b-modal__text'}>
          {permissionsError}
        </div>
      </div>
      </Modal>} */}
      {deleteOneItem.length ? <Modal
          title='Delete Code'
          width={520}
          onClose={() => setDeleteOneItem([])}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to delete <br/>
            this HCC Code?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Cancel'} onPress={() => setDeleteOneItem([])} type={'transparency'} width='100px'/>
            <Button title={'Yes'} onPress={() => deleteCode(deleteOneItem)} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> : null}
   </>
  );
};

export default HccList;
