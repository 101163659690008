import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColorType, MainForm, MainFormErrorsModel, TemplateCategory } from '../../../../../models/templates/templates.model';
import { RootState } from '../../../../../store/reducers';
import { createTemplateCategory, getTemplateCategory } from '../../../../../store/templates/templatesSlice';
import styles from './MainCategory.module.scss';
import stylesWrapper from '../../../TemplatesList/TemplatesListView.module.scss'
import { Button, Input, Select } from '../../../../controls';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useUnsavedChangesWarning } from '../../../../common';


type IProps = {
  colorType: ColorType[];
  mainForm: MainForm;
  templateCategory: TemplateCategory[];
  setMainForm: (mainForm: MainForm | ((mainForm: MainForm) => MainForm)) => void;
  successfulCategorySave: () => void;
  errorsMainForm: MainFormErrorsModel | undefined;
  clearChangesWarning: boolean;
};

const MainCategory: FC<IProps> = ({ colorType, mainForm,templateCategory, setMainForm, successfulCategorySave, errorsMainForm, clearChangesWarning }) => {
  const dispatch = useDispatch();

  const [Prompt, setDirty, setClear] = useUnsavedChangesWarning();
  const [date, setDate] = useState<Date>(history.state.state && new Date(history.state.state.date_of_creation.split('-').join('/')) || new Date());

  const [newTemplateCategory, setNewTemplateCategory] = useState({
    title: '',
  });

  useEffect(() => {
    if (clearChangesWarning) {
      setClear();
    }
  }, [clearChangesWarning]);

  const _generateCategoryListForSelect = (list: TemplateCategory[] | ColorType[]) => {
    let newList: { label: string; value: number }[] = [];

    list.map((item) =>
      newList.push({ label: item.title, value: item.id })
    );
    return newList;
  };

  const handleChangeMainForm = (fieldName: string, value: string) => {
    setMainForm({ ...mainForm, [fieldName]: value });
    setDirty();
  };

  const handleChangeDate = (value: any) => {
    setDate(value);
    // setMainForm({ ...mainForm, date_of_creation: moment(value).isValid() ?  moment(value).format('YYYY-MM-DD') : '' });
    setMainForm({ ...mainForm, date_of_creation: moment(value).format('YYYY-MM-DD') });
    setDirty();
  };

  const handleChangeNewCategory = (value: string) => {
    setNewTemplateCategory({ title: value });
  };

  const validateForm = () => {
    return !newTemplateCategory.title;
  };

  const addNewTemplateCategory = () => {
    dispatch(
      createTemplateCategory({
        data: newTemplateCategory,
        callback: () => {
          successfulCategorySave();
        },
      }),
    );
    setNewTemplateCategory({ title: '' });
  };

  const typeInfo = (
    <div className="p-datatable-filters__popupContainerInfo">
      <button type="button" className="p-datatable-filters__popupOpenerInfo"></button>
      <div className="p-datatable-filters__popupInfo">
        <div className="p-datatable-filters__popupFrame">
          <div className="p-datatable-filters__popupHeading">TYPE INFO</div>
          <div className="p-datatable-filters__popupContent">
            <ul className="p-datatable-filters__popupList">
              <li>
                <b>Hybrid survey</b> - Has a score and a dial. This is for clinicians only.
              </li>
              <li>
                <b>Task survey</b> that creates a task list or action plan based on how the questions are
                answered, Example Social determinants of Health. Only for clinicians to fill out.
              </li>
              <li>
                <b>Standard survey</b> - Just informational to get responses from patient or survey
                taker, sent to patient to fill out. Example patient pain. Patients and clinicians
                may fill out.
              </li>
              <li>
                <b>Scored survey</b> (with score) - Patients or clinicians
                may fill out
              </li>
              <li>
                <b>Weighted survey</b> (with dial) example, the readmission surveys. Only clinicians
                fill out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="b-mainContainer__wrapperInner">
        <div className={`b-mainContainer__contentWrapper ${stylesWrapper.tableWrapper}`}>
        {/* <div className={styles.box}> */}
          <div className={styles.headerTitle}>{history.state.state ? 'Update Survey' : 'Create Survey'}</div>

          <div className={styles.categoryBox}>
            <div className={styles.selectBox}>
                <Select
                value={
                  _generateCategoryListForSelect(templateCategory).filter(
                    (item) =>
                    mainForm.category_id === item.label
                     || mainForm.category_id === item.value
                  )[0] || _generateCategoryListForSelect(templateCategory)[0]
                }
                  options={_generateCategoryListForSelect(templateCategory)}
                  label={'Category *'}
                  className={'mb-0'}
                  // placeholder={'Select a Category'}
                  onChange={(value) => handleChangeMainForm('category_id', value.label)}
                  />
            </div>
            <div className={styles.colorBox}>
              <Select
                value={
                  _generateCategoryListForSelect(colorType).filter(
                    (item) =>
                    mainForm.type_color_id === item.label
                     || mainForm.type_color_id === item.value
                  )[0] || _generateCategoryListForSelect(colorType)[0]
                }
                  options={_generateCategoryListForSelect(colorType)}
                  label={'Type *'}
                  withIconInfo={true}
                  info={typeInfo}
                  className={'mb-0'}
                  // placeholder={'Select a Type'}
                  onChange={(value) => handleChangeMainForm('type_color_id', value.label)}
                  />
            </div>
            <div className={styles.formGroupDate}>
              <div className={'b-form__datePikerLabel'}>
                Date of Creation *
              </div>
              <DatePicker
                  dateFormat={'MM.dd.yyyy'}
                  selected={date}
                  onChange={value => handleChangeDate(value)}/>
            </div>
            {/* </div> */}
            {/* <div className={styles.or}>
              <div>OR</div>
            </div> */}
            {/* <div className={styles.createBox}> */}
            <div className={styles.formGroupCategory}>
              <Input
                value={newTemplateCategory.title}
                label={'Create New Category'}
                // error={errors?.questionForm.title}
                onChange={(value) => handleChangeNewCategory(value)}
              />
            </div>
            {/* </div> */}
            <div>
              <Button
                title={'create'}
                width={'100px'}
                disabled={!!validateForm()}
                className={'-uppercase'}
                onPress={() => addNewTemplateCategory()}
              />
            </div>
            
          </div>
          <div className={styles.textBox}>
            <div className={styles.titleBox}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={mainForm.title}
                  label={'Title *'}
                  error={errorsMainForm?.mainForm.title}
                  onChange={(value) => handleChangeMainForm('title', value)}
                />
              </div>
            </div>
            <div className={styles.initialBox}>
              <div className={'b-form__formGroup'}>
                <Input
                  value={mainForm.initial}
                  label={'Initial *'}
                  error={errorsMainForm?.mainForm.initial}
                  onChange={(value) => handleChangeMainForm('initial', value)}
                />
              </div>
            </div>
          </div>
        {/* </div> */}
        </div>
      </div>
      {Prompt}
    </>
  );
};

export default MainCategory;
