import React, { FC, useState } from 'react';
import { generateId } from '../../../../../helpers';
import { QuestionCategory } from '../../../../../models/templates/templates.model';
import Button from '../../../../controls/Button/Button';

// const generatorPositionCategory = generateId();

type IProps = {
  questionCategory: QuestionCategory[] | null;
  categoryList: QuestionCategory[];
  setCategoryList: (
    categoryList: QuestionCategory[] | ((categoryList: QuestionCategory[]) => QuestionCategory[]),
  ) => void;
};

const AddCategoryButton: FC<IProps> = ({ questionCategory, categoryList, setCategoryList }) => {
  const [dropDown, setDropDown] = useState(false);

  const addCategory = (item: QuestionCategory) => {
    if (categoryList.length) {
      const categoryListCopy = categoryList.filter((el) => el.id !== item.id);
      if (categoryList.length === categoryListCopy.length) {
        const category: QuestionCategory = {
          id: item.id,
          title: item.title,
          position: '' + (categoryList.length + 1),
          items: [],
        };
        setCategoryList((categoryList: QuestionCategory[]) => [...categoryList, category]);
        setDropDown(false);
      } else {
        setDropDown(false);
      }
    } else {
      const category: QuestionCategory = {
        id: item.id,
        title: item.title,
        position: '' + 0,
        items: [],
      };
      setCategoryList((categoryList: QuestionCategory[]) => [...categoryList, category]);
      setDropDown(false);
    }
  };

  return (
    <>
      <div className="templates__categoryBox">

      <Button
          title='Add category'
          type={'transparency'}
          className={'darkest'}
          width={'150px'}
          onPress={() => setDropDown(!dropDown)} />
        {dropDown ? (
          <ul className="templates__categoryList">
            {questionCategory?.map((item, index) => (
              <li
                key={`category-${item.id}`}
                className="templates__categoryList__item"
                style={
                  categoryList.filter((el) => el.id === item.id).length
                    ? { cursor: 'auto', backgroundColor: '#53A0DF' }
                    : {}
                }
                onClick={() => categoryList.filter((el) => el.id === item.id).length ? null : addCategory(item)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        ) : null}
        {categoryList.length == 0 && <div className="templates__info">Please first select a cetegory at question</div>}
      </div>
    </>
  );
};

export default AddCategoryButton;
