import React, {useState, FC} from 'react';
import styles from './Textarea.module.scss';

interface IProps {
  value: string;
  onChange: (value: string) => void;

  error?: string | boolean;

  placeholder?: string;
  label?: string,
  
  disable?: boolean,
  className?: string,
  classLabelName?: string,

  width?: string | number,
  height?: string | number,
  id: string,
}

const Textarea: FC<IProps> = ({
                             error,
                             placeholder,
                             onChange,
                             value,
                             disable,
                             className,
                             classLabelName,
                             width,
                             height,
                             label,
                             id
                           }: IProps) => {

  const style: any = {};
  if (width) style.width = width;
  if (height) style.height = height;

  return (
      <>
        {label && <div className={`${styles.input_label} ${classLabelName ? classLabelName : ''}`}>{label}</div>}
        <div className={`${styles.input_wrapper} ${className ? className : ''}`}>

          <textarea
              className={`
                ${styles.input}
                ${error ? styles.input_error : ''}
                ${disable ? styles.input_disable : ''}
              `}
              id={id}
              placeholder={placeholder}
              value={value}
              style={style}

              disabled={disable}
              onChange={(e) => onChange(e.target.value)}/>
        </div>
        <div className={styles.error_text}>
          <span>{error}</span>
        </div>
      </>
  )
};

export default Textarea;
