import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Router } from './routes/Router';
import {SideBarView} from './components/common/index'
import {useSelector} from "react-redux";
import {RootState} from "./store/reducers";

import './components/common/SideBar/SideBarStyles.scss'

function App() {

  const access_token = useSelector((state: RootState) => state.user.access_token);
  const is_policy_accepted = useSelector((state: RootState) => state.user.is_policy_accepted);

  
  return (
    <div className={access_token && is_policy_accepted === '1' ? 'b-mainContainer': ''}>
    {/* <div className={access_token ? 'b-mainContainer': ''}> */}
      {(access_token && is_policy_accepted === '1') ?
      // {access_token ?
          <SideBarView />
          :
          null
      }
      <Router />
      <NotificationContainer/>
    </div>
  );
}

export default App;
