import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import Api from '../../services/api';
import {
    DeletePatientsPayload,
    DoctorsSearch,
    GetPatientModel,
    HccCodesSearch,
    PatientAddUpdatePayload,
    PatientsPayload
} from "../../models";
import {
    getPatient,
    addPatient,
    getDoctorsBySearch,
    getDoctorsBySearchSuccess,
    getStateList,
    getStateListSuccess,
    getPatientsList,
    getPatientsListSuccess,
    deletePatients,
    deletePatientsSuccess,
    updatePatient,
    getHccCodesBySearchSuccess,
    getHccCodesBySearch,
} from "./patientsSlice";

function* getPatientSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {id , callback}}: {payload: GetPatientModel} = yield take(getPatient.toString());

        try {
            const response = yield call(Api.post, `patient/get`, {id});
            if (response) {
                callback(response.data.resource);
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* addPatientSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: PatientAddUpdatePayload} = yield take(addPatient.toString());
        try {
            const response = yield call(Api.post, `patient/add`, data);
            if (response) {
                callback({message:'success'});
            }
        } catch (e) {
            console.log('error message222', e.response);
            if (e.response.status === 400) {
              callback(e.response.data.errors);
            }
        }
    }
}

function* updatePatientSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: PatientAddUpdatePayload} = yield take(updatePatient.toString());
        try {
            const response = yield call(Api.put, `patient/update`, undefined , data);
            if (response) {
                callback({message:'success'});
            }
        } catch (e) {
            console.log('error message222', e.response);
            if (e.response.status === 400) {
                callback(e.response.data.errors);
            }
        }
    }
}

function* getDoctorsBySearchSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: data}: {payload: DoctorsSearch} = yield take(getDoctorsBySearch.toString());

        try {
            const response = yield call(Api.post, `patient/search-doctors`, data);
            if (response) {
                yield put(getDoctorsBySearchSuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getHccCodesBySearchSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, callback}}: {payload: HccCodesSearch} = yield take(getHccCodesBySearch.toString());

        try {
            const response = yield call(Api.post, `patient/search-hcc-codes`, data);
            if (response) {
                yield put(getHccCodesBySearchSuccess(response.data.resource));
                if (callback) {
                    callback();
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getStateListSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        yield take(getStateList.toString());

        try {
            const response = yield call(Api.post, `patient/state-list`);
            if (response) {
                yield put(getStateListSuccess(response.data.resource));
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getPatientsListSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, page, callback} }: {payload: PatientsPayload} = yield take(getPatientsList.toString());

        try {
            const response = yield call(Api.post, `patient/list?page=${page}`, data);
            if (response) {
                yield put(getPatientsListSuccess(response.data.resource));
                if (callback){
                    callback({message:'success'});
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
            if (e.response.status === 403) {
                if (callback){
                  callback(e.response.data);
                }
            }
        }
    }
}

function* deletePatientsSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: DeletePatientsPayload} = yield take(deletePatients.toString());

        try {
            const response = yield call(Api.delete, `patient/delete`, data);
            if (response) {
                yield put(deletePatientsSuccess(response.data.resource));
                if (callback){
                    callback();
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}


export function* saga() {
    yield all([
        getPatientSaga(),
        addPatientSaga(),
        getDoctorsBySearchSaga(),
        getHccCodesBySearchSaga(),
        getStateListSaga(),
        getPatientsListSaga(),
        deletePatientsSaga(),
        updatePatientSaga(),
    ]);
}
