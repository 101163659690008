export enum Routes {
  SIGNIN = '/',
  FORGOTPASS = '/forgotpass',
  RESETPASS = '/restore-password/:token',
  CREATEPASS = '/create-password/:token',
  POLICY = '/policy',

  // HTML
  DASHBOARD = '/dashboard',
  PATIENTS_LIST = '/patients',
  PATIENTS_ADD = '/patient-add',
  PATIENTS_EDIT = '/patient-edit/:id',
  MANAGE_USERS_LIST = '/manage-users',
  MANAGE_USERS_ADD = '/manage-users-add',
  MANAGE_USERS_EDIT = '/manage-users-edit/:id',
  REPORTS = '/reports',
  TEMPLATES = '/templates',
  CREATE_TEMPLATE = '/create-template',
  CLINICAL_PRACTICE_GUIDES = '/clinical-practice-guides',
  TEMPLATES_LIST = '/templates-list',
  SURVEY = '/survey',
  SURVEY_PAGE = '/fill-questionnaire/:token',
  QUESTIONNAIRES = '/questionnaires',
  SETTINGS = '/settings',
  // PATIENTHCCCODES = '/hcc-codes/:id',
  ALLHCCCODES = '/all-hcc-codes',
};
