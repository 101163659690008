import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import Api from '../../services/api';
import {
    DeleteHccCodesPayload,
    HccCodesAddPayload,
    HccCodesCSVPayload,
    HccCodesPayload,
    HccCodesUpdatePayload,
} from "../../models";
import {
    getPatientHccCodeList,
    getPatientHccCodeListSuccess,
    addHccCode,
    updateHccCode,
    getHccCodesCSV,
    getAllHccCodeList,
    getAllHccCodeListSuccess,
    deleteHccCode,
    deleteHccCodeSuccess,
} from "./hccCodesSlice";
import { httpRequestReport } from '../../services/httpRequest';
const fileDownload = require('js-file-download');

function* getPatientHccCodeListSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, page, ipp, callback} }: {payload: HccCodesPayload} = yield take(getPatientHccCodeList.toString());

        try {
            const response = yield call(Api.post, `patient-hcc-code/list?page=${page}&ipp=${ipp}`, data);
            if (response) {
                yield put(getPatientHccCodeListSuccess(response.data.resource));
                if (callback){
                    callback({message:'success'});
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
            if (e.response.status === 403) {
                if (callback){
                  callback(e.response.data);
                }
            }
        }
    }
}

function* getAllHccCodeListSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data, page, callback} }: {payload: HccCodesPayload} = yield take(getAllHccCodeList.toString());

        try {
            const response = yield call(Api.post, `hcc-code/list?page=${page}`, data);
            if (response) {
                yield put(getAllHccCodeListSuccess(response.data.resource));
                if (callback){
                    callback({message:'success'});
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
            if (e.response.status === 403) {
                if (callback){
                  callback(e.response.data);
                }
            }
        }
    }
}

function* addHccCodeSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: HccCodesAddPayload} = yield take(addHccCode.toString());
        try {
            const response = yield call(Api.post, `hcc-code/add-code`, data);
            if (response) {
                if (callback) {
                    callback();
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            if (e.response.status === 400) {
                if (callback) {
                    callback(e.response.data.errors);
                }
            }
        }
    }
}

function* updateHccCodeSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: HccCodesUpdatePayload} = yield take(updateHccCode.toString());
        try {
            const response = yield call(Api.put, `hcc-code/update-code`, undefined , data);
            if (response) {
                // callback({message:'success'});
                callback();
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* deleteHccCodeSaga(): Generator<StrictEffect, void, any> {
    while (true) {
        const { payload: {data , callback} }: {payload: DeleteHccCodesPayload} = yield take(deleteHccCode.toString());

        try {
            const response = yield call(Api.delete, `hcc-code/delete-code`, data);
            if (response) {
                yield put(deleteHccCodeSuccess(response.data.resource));
                if (callback){
                    callback();
                }
            }
        } catch (e) {
            console.log('error message222', e.response);
            // if (e.response.status === 400) {
            //   callback(e.response.data.errors);
            // }
        }
    }
}

function* getHccCodesCSVSaga(): Generator<StrictEffect, void, any> {
    while (true) {
      const { payload: data}: {payload: HccCodesCSVPayload} = yield take(getHccCodesCSV.toString());
      try {
        const response = yield call(
            httpRequestReport,
            `patient-hcc-code/generate/csv`,
            { method: 'POST', ...data, responseType: 'blob' },
        );
        
        if (response) {
          fileDownload(response, 'HCC-codes.csv');
        }
      } catch (e) {
        console.log('error message222', e);
        if (e.response.status === 403) {
          if (data.callback){
            data.callback(e.response);
          }
        }
      }
    }
  }


export function* saga() {
    yield all([
        getPatientHccCodeListSaga(),
        getAllHccCodeListSaga(),
        addHccCodeSaga(),
        updateHccCodeSaga(),
        deleteHccCodeSaga(),
        getHccCodesCSVSaga(),
    ]);
}
