import React, {FC, useEffect, useRef, useState} from 'react';
import {Question, QuestionOptions, QuestionParent} from '../../../../../models/templates/templates.model';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';

type IProps = {
    categoryId: number;
    title: string;
    surveyStatus: string;
    categoryItems: Question[];
    // key: string;
    collectFields: (id : number, categoryQuestions : Question[]) => void;
    errorSurveyQuestion: boolean;
    handleChangeSurvey?: () => void;
};

const SurveyCategory: FC<IProps> = ({categoryId, title, surveyStatus, categoryItems, collectFields, errorSurveyQuestion, handleChangeSurvey}) => {

    const [category, setCategory] = useState<Question[]>(categoryItems);

    const collectAnswers = (type: 'sub' | 'main', id: number, answers?: number[], parent?: QuestionParent, text?: string) => {
        handleChangeSurvey && handleChangeSurvey();

        if (type === 'main') {
            const currentQuestionIndex = category.findIndex(question => question.id === id)
            if (answers && answers?.length > 0) {

                const changeAnswer = (category: Question[]) => {
                    let newCategory: Question[] = JSON.parse(JSON.stringify(category));

                    newCategory = newCategory.map((item, index) => index === currentQuestionIndex ? { ...item, answers: answers } : { ...item })
                    // newCategory[currentQuestionIndex].answers = answers;
                    newCategory[currentQuestionIndex].options.map((item) => item.subQuestions.length ? { ...item, subQuestions: [...item.subQuestions.map((el) => {if (el.type !== 'input') {el.answers = []}})] } : item);
                    return newCategory;
                }

                setCategory(changeAnswer(category))
            } else {
                const changeAnswer = (category: Question[]) => {
                    let newCategory: Question[] = JSON.parse(JSON.stringify(category));

                    newCategory = newCategory.map((item, index) => index === currentQuestionIndex ? { ...item, textArea: text } : { ...item })
                    // newCategory[currentQuestionIndex].options.map((item) => item.subQuestions.length ?  {...item, subQuestions: [...item.subQuestions.map((el) => el.answers= [])] } : item);
                    return newCategory;
                }

                setCategory(changeAnswer(category))
            }
        } else {
            const currentQuestionIndex = category.findIndex(question => question.id === parent?.mainQuestion_id)

             if (answers && answers?.length > 0) {
            const changeAnswer = (category: Question[]) => {
                let newCategory: Question[] = JSON.parse(JSON.stringify(category));

                let newOptions: QuestionOptions[];
                newOptions = newCategory[currentQuestionIndex].options.map((item) => item.id === parent?.option_id ?
                    { ...item, subQuestions: item.subQuestions.map((el) => el.id === id ? { ...el, answers: answers } : el) }
                    : item)

                newCategory = newCategory.map((elem, index) => currentQuestionIndex === index ? { ...elem, options: newOptions } : {...elem});
                return newCategory;
            }

            setCategory(changeAnswer(category))
        } else {

            const changeAnswer = (category: Question[]) => {
                let newCategory: Question[] = JSON.parse(JSON.stringify(category));

                let newOptions: QuestionOptions[];
                newOptions = newCategory[currentQuestionIndex].options.map((item) => item.id === parent?.option_id ?
                    { ...item, subQuestions: item.subQuestions.map((el) => el.id === id ? { ...el, textArea: text } : el) }
                    : item)

                newCategory = newCategory.map((elem, index) => currentQuestionIndex === index ? { ...elem, options: newOptions } : {...elem});
                return newCategory;
            }

            setCategory(changeAnswer(category))
        }
        }
    }

    const addSwitcherInputStatus = () => {
        const newCategory: Question[] = JSON.parse(JSON.stringify(categoryItems));
        newCategory.map((item) =>
          item.type === 'switcher' && item.answers?.length == 0
            ? (item.answers = [2])
            : item.type === 'input' && item.answers?.length == 0
            ? (item.answers = [1])
            : item.answers,
        );
        setCategory(newCategory);
    };

    useEffect(() => {
        addSwitcherInputStatus();
    }, []);

    useEffect(() => {
        collectFields(categoryId, category);
    }, [category]);

    const ref = useRef<HTMLDivElement>(null);

    return (
        <>
           <div className="b-surveyForm__block" ref={ref}>
               <h2 className="b-surveyForm__tilte categoryTitleRef">{title !== 'No Category' ? title : ''}</h2>
               {category.length > 0
                   ? category
                       .map((item) => (
                           <SurveyQuestion
                                id={`mainQuestion-${item.id}`}
                                data={item}
                                key={`mainQuestionItem-${item.id}`}
                                collectAnswers={collectAnswers}
                                errorSurveyQuestion={errorSurveyQuestion}
                                surveyStatus={surveyStatus}
                           />
                       ))
                   : null}
           </div>
        </>
    );
};

export default SurveyCategory;
