import React, { FC, useState, useEffect } from 'react';
import styles from './ManageUsersListView.module.scss'

import { Link, useHistory } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { HeaderSearch, Pagination } from '../../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from "../../../controls/CheckBox/CheckBox";
import Button from "../../../controls/Button/Button";
import Modal from "../../../common/Modal/Modal"
import {Routes} from "../../../../routes/routes";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { FilterUsers, ManageUser, UsersList } from '../../../../models/manageUsers/manageUsers.model';
import { deleteUserById, getUsersList } from '../../../../store/manageUsers/manageUsersSlice';
import { Input, Loader } from '../../../controls';

interface IState {
  modalType: 'fill' | 'send' | 'delete' | 'permissions-fail' | null,
}

const initialState: IState = {
  modalType: null,
};

const ManageUsersList = () => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const usersList: UsersList | null = useSelector(
    (state: RootState) => state.manageUsers.usersList,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [arrayIdsForDelete, setArrayIdsForDelete] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailCopied, setEmailCopied] = useState('');
  const [sort, setSort] = useState(true);
  let [state, setState] = useState<IState>(initialState);
  const [permissionsError, setpermissionsError] = useState<string>(`You don't have permissions to manage system users`);
  const [searchUsers, setSearchUsers] = useState<FilterUsers>({
    ordering: {
      users: {
        created_at: 'desc',
      },
    },
    searching: ''
  });

  useEffect(() => {
    currentPage === 1 ?
      dispatch(
        getUsersList({
          data: searchUsers,
          page: 1,
          callback: (message) => {
            if (message.message !== 'success') {
              setState({...state, modalType: 'permissions-fail'});
              // setpermissionsError(message.message)
            }
            setLoading(false);
          },
        }),
      )
      :
      setCurrentPage(1);
  }, [searchUsers]);

  useEffect(() => {
    // if (usersList) {
    //   setLoading(true);
    // }
    dispatch(
      getUsersList({
        data: searchUsers,
        page: currentPage,
        callback: () => {
          setLoading(false);
        },
      }),
    );
  }, [currentPage]);

  const handleSearch = (value: string) => {
    setSearchUsers({
      ...searchUsers,
      searching: value
    });
  };


  const deleteSelected = (arrayTemplateForDelete: number[]) => {
    dispatch(
      deleteUserById({
        ids: arrayTemplateForDelete,
        callback: () => {
          currentPage === 1
            ? dispatch(getUsersList({ data: searchUsers, page: 1 }))
            : setCurrentPage(1);
        },
      }),
    );
    setArrayIdsForDelete([]);
    handleCloseModal();
  };

  const checkItem = (user: ManageUser) => {
    setArrayIdsForDelete((prev) => {
      if (prev.includes(user.id)) {
        prev = prev.filter((item) => item !== user.id);
        return prev;
      } else {
        return [...prev, user.id];
      }
    });
  };

  const allSelected = () => {
    let checkedCounter = 0;
    if (usersList && usersList.data) {
      usersList?.data.map((item) =>
        arrayIdsForDelete.includes(item.id) ? checkedCounter++ : item,
      );
      if (arrayIdsForDelete.length > 0 && usersList?.data.length === checkedCounter) {
        return true;
      }
    }
    return false;
  };

  const checkAll = () => {
    if (usersList && usersList.data) {
      let checkedCounter = 0;
      usersList.data.map((item) => (arrayIdsForDelete.includes(item.id) ? checkedCounter++ : item));
      if (arrayIdsForDelete.length > 0 && usersList?.data.length === checkedCounter) {
        let array: number[] = [];
        usersList.data.map((item) => array.push(item.id));
        const filteredCheckedIds = arrayIdsForDelete.filter((item) => !array.includes(item));

        setArrayIdsForDelete(filteredCheckedIds);
      } else {
        let array: number[] = [...arrayIdsForDelete];
        usersList.data.map((item) => (array.includes(item.id) ? item : array.push(item.id)));

        setArrayIdsForDelete(array);
      }
    }
  };

  const handleModalOpen = (type: 'fill' | 'send' | 'delete' | null) => () => {
    setState({ ...state, modalType: type });
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null });
  };

  const headerEdit = (
    <span className="p-column-title -flex">
      <span className="p-column-title-inner">Edit</span>
      <Checkbox
        checked={allSelected()}
        onChange={checkAll}
      />
    </span>
  );

  const userDateOfCreate = (rowData: any) => {
    return <span>{rowData.created_at}</span>;
  };

  const userFirstName = (rowData: any) => {
    return <span>{rowData.first_name}</span>;
  };

  const userLastName = (rowData: any) => {
    return <span>{rowData.last_name}</span>;
  };

  const userRole = (rowData: any) => {
    return <span>{rowData.role}</span>;
  };

  const userEmail = (rowData: any) => {
    return (
      <a
        style={{ position: 'relative' }}
        onClick={(e) => {
          navigator.clipboard.writeText(e.target.innerHTML);
          setEmailCopied(`email-${rowData.id}`);
          setTimeout(() => setEmailCopied(''), 1000);
        }}
      >
        {emailCopied === `email-${rowData.id}` ? (
          <span className="b-tooltipCopy">Email copied</span>
        ) : null}
        {rowData.email}
      </a>
    );
  };

  const userEdit = (user: ManageUser) => {
    return (
      <div className={styles.patientControl}>
        <Link 
            to={`/manage-users-edit/${user.id}`}
            className={styles.patientEditLink} 
            href="#" 
            aria-label="Edit"
            data-tip data-for='patientEdit'>
          <ReactTooltip id='patientEdit' className='b-tooltip'>
            Edit
          </ReactTooltip>
        </Link>        
        <Checkbox 
          checked={arrayIdsForDelete.includes(user.id)} 
          onChange={() => checkItem(user)}/>
      </div>
    )
  };
  const footer = (
    <div className="patientControlList">
      {!userData?.option.permissions.create_edit_manage_user ? 
        <ReactTooltip id='manageListBtn' className='b-tooltipPermission'>
          {`You don't have permissions to manage system users`}
        </ReactTooltip> : null}

      <Link
      to={userData?.option.permissions.create_edit_manage_user ? Routes.MANAGE_USERS_ADD : '#'}
      className={`footerBtn ${userData?.option.permissions.create_edit_manage_user ? '' :'disabled'}`}
      data-tip data-for='manageListBtn'
      >
        add new User</Link>

      {userData?.option.permissions.create_edit_manage_user ? 
      <div className="patientControlList__innerWrapper">
        <Button
          title='delete'
          dataFor={'deleteToolTip'}
          className={'darkest -uppercase'}
          onPress={!arrayIdsForDelete.length ? (() => null) : handleModalOpen('delete')}/>
          {!arrayIdsForDelete.length ?
            <ReactTooltip  id='deleteToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
              Please select at least one user to delete user
            </ReactTooltip>
          : null}
      </div>
      : null}
    </div>
  );


  const dateOfCreateHeader = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchUsers({
          ordering: {
            users: {
              created_at: sort ? 'asc' : 'desc',
            },
          },
          searching: searchUsers.searching ,
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Date Of Create</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

 const firstNameHeader = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchUsers({
          ordering: {
            user_profiles: {
              first_name: sort ? 'asc' : 'desc',
            },
          },
          searching: searchUsers.searching,
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">First Name</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const lastNameHeader = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchUsers({
          ordering: {
            user_profiles: {
              last_name: sort ? 'asc' : 'desc',
            },
          },
          searching: searchUsers.searching,
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Last Name</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const roleHeader = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchUsers({
          ordering: {
            roles: {
              name: sort ? 'asc' : 'desc',
            },
          },
          searching: searchUsers.searching,
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Role</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  const emailHeader = (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchUsers({
          ordering: {
            users: {
              email: sort ? 'asc' : 'desc',
            },
          },
          searching: searchUsers.searching,
        });
        if (currentPage !== 1) {
          setCurrentPage(1);
        }
        setSort(!sort);
      }}>
      <span className="p-column-title">Email</span>
      <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>
    </div>
  );

  return (
    <div className={'b-mainContainer__wrapper'}>
      {/* {loading && <Loader fitParent={true}/>} */}
      <HeaderSearch pageName='Manage Users'/>
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={`b-mainContainer__contentWrapper ${styles.tableWrapper}`}>
        <div className="p-datatable-filters">
          <div className="p-datatable-filters__container">
          <form action="#" className="searchForm">
            <input
              type="search"
              placeholder="Search"
              value={searchUsers.searching}
              onChange={e => handleSearch(e.target.value)}
            />
            </form>
            </div>
          </div>
          {usersList ? (
            <>
            <DataTable 
            value={usersList.data}
            className='-paginator'
            footer={footer}
             >
            <Column field='created_at' header={dateOfCreateHeader} body={userDateOfCreate} />
            <Column field='first_name' header={firstNameHeader} body={userFirstName} />
            <Column field='last_name' header={lastNameHeader} body={userLastName} />
            <Column field='role' header={roleHeader} body={userRole} />
            <Column field='email' header={emailHeader} body={userEmail} />
            {userData?.option.permissions.create_edit_manage_user ?
            <Column field='edit' header={headerEdit} body={userEdit} />
            : null}
          </DataTable>
          <Pagination
                displayTitle={'users'}
                data={usersList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
          </>
          )
          : null}
        </div>
      </div>
      {state.modalType === 'delete' && <Modal
          title='Delete Users'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to delete <br/>
            this user(s)?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Yes'} onPress={() => deleteSelected(arrayIdsForDelete)} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'permissions-fail' && <Modal
          title='Error'
          width={520}
          onClose={() => {
            setState({...state, modalType: null}),
            // setpermissionsError(''),
            history.push(Routes.DASHBOARD);
          }}>
          <div className={'b-modal'}>
            <div className={'b-modal__text'}>
              {permissionsError}
            </div>
          </div>
         </Modal>}
    </div>
  );
};

export default ManageUsersList;
