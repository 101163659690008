import React, {FC, RefObject, useEffect, useRef} from 'react';
import Select, { MenuPosition, SingleValue } from 'react-select'
import styles from "./Select.module.scss";
import {ISelectOptions} from "../../../models";

interface IProps {
  value: ISelectOptions | ISelectOptions[] | null | undefined;
  options: ISelectOptions[] | null | undefined;
  onChange: (value: ISelectOptions) => void;
  // onChange: (newValue: SingleValue<ISelectOptions<string>>) => void;
  error?: string | boolean;

  placeholder?: string;
  label?: string,

  disabled?: boolean,
  className?: string,

  width?: string | number,
  height?: string | number,
  maxMenuHeight?: number,
  multi?: boolean,
  searchable?: boolean,
  closeMenuOnSelect?: boolean,
  closeMenuOnScroll?: boolean,
  menuPosition?: MenuPosition,

  onInputChange?: (value : string) => void,
  defaultInputValue?: string,
  defaultValue?: ISelectOptions | ISelectOptions[] | null;
  withIconInfo?: boolean,
  info?: JSX.Element,
}

const SelectView: FC<IProps> = (props) => {
  const {
    label, className, onChange, value,
    width, height, options, error, disabled, placeholder,maxMenuHeight,
    multi, searchable, closeMenuOnSelect, closeMenuOnScroll, onInputChange,defaultInputValue,
    defaultValue, menuPosition, withIconInfo, info
  } = props;

  const customStyles = {
    indicatorSeparator: () =>  ({
      display: 'none'
    }),
    control: (base: any, {isDisabled}: any) => ({
      ...base,
      width: width || 'auto',
      height: height || '40px',
      minHeight: height || '40px',
      color: '#656565',
      background:  isDisabled ? '#F4F4F4' : 'inherit',
      opacity: '1',
      borderColor: error ? 'red' : '#E5E5E5',
      '&:focus': {
        borderColor: 'var(--primary-color)',
      }
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "var(--primary-color)" // Custom colour
    }),
    singleValue: (base: any) => ({
      ...base,
      color: error ? '#656565' : '#656565',
    }),
  };

  const style: any = {};
  if (width) style.width = width;
  if (height) style.height = height;

  return (
      <>
        {label && <div className={`${styles.select_flex}`}>
          <span className={styles.select_label}>{label}</span>
          {withIconInfo ? info : null}
        </div>}
        <div className={`${styles.select_wrapper} ${className ? className : ''}`}>
          <Select
              value={value}
              placeholder={placeholder}
              styles={customStyles}
              options={options}
              isDisabled={disabled}
              isMulti={multi}
              maxMenuHeight={maxMenuHeight}
              menuPlacement={'auto'}
              menuPosition={menuPosition}
              onChange={onChange}
              closeMenuOnSelect={closeMenuOnSelect}
              closeMenuOnScroll={closeMenuOnScroll}
              searchable={searchable}
              onInputChange={onInputChange}
              defaultInputValue={defaultInputValue}
              defaultValue={defaultValue}
          />
          <div className={styles.error_text}>
            <span>{error}</span>
          </div>
        </div>
      </>
  );
};

SelectView.defaultProps = {
  placeholder: ''
};

export default SelectView;
