import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    HccCodesCSVPayload,
    HccCodesPayload,
    HccCodesTableFields,
    HccCodesAddPayload,
    HccCodesUpdatePayload,
    PaginationModel,
    AllHccCodesPayload,
    DeleteHccCodesPayload,
} from '../../models';

export interface InitialState {
    hccCodesList: PaginationModel<HccCodesTableFields[]> | null,
    allHccCodesList: PaginationModel<HccCodesTableFields[]> | null,
}

const initialState: InitialState = {
    hccCodesList: null,
    allHccCodesList: null,
};

const hccCodes = createSlice({
    name: 'hccCodes',
    initialState,
    reducers: {
        getPatientHccCodeList: (state, {payload}: PayloadAction<HccCodesPayload>) => { },
        getPatientHccCodeListSuccess: (state, { payload }: PayloadAction<PaginationModel<HccCodesTableFields[]>>) => {
            state.hccCodesList = payload;
        },

        getAllHccCodeList: (state, {payload}: PayloadAction<AllHccCodesPayload>) => { },
        getAllHccCodeListSuccess: (state, { payload }: PayloadAction<PaginationModel<HccCodesTableFields[]>>) => {
            state.allHccCodesList = payload;
        },

        addHccCode: (state, {payload}: PayloadAction<HccCodesAddPayload>) => { },
        addHccCodeSuccess: (state, { payload }) => {},
        
        updateHccCode: (state, {payload}: PayloadAction<HccCodesUpdatePayload>) => { },
        updateHccCodeSuccess: (state, { payload }) => {},

        deleteHccCode: (state, {payload}: PayloadAction<DeleteHccCodesPayload>) => { },
        deleteHccCodeSuccess: (state, { payload }) => {},

        getHccCodesCSV: (state, {payload}: PayloadAction<HccCodesCSVPayload>) => { },
        getHccCodesCSVSuccess: (state, { payload }) => {},

        clearHccCodesStore: (state) => {
            state.hccCodesList = null;
          },
    },
});

const {
    getPatientHccCodeList,
    getPatientHccCodeListSuccess,
    getAllHccCodeList,
    getAllHccCodeListSuccess,
    addHccCode,
    addHccCodeSuccess,
    updateHccCode,
    updateHccCodeSuccess,
    deleteHccCode,
    deleteHccCodeSuccess,
    getHccCodesCSV,
    getHccCodesCSVSuccess,
    clearHccCodesStore,
} = hccCodes.actions;

export {
    getPatientHccCodeList,
    getPatientHccCodeListSuccess,
    getAllHccCodeList,
    getAllHccCodeListSuccess,
    addHccCode,
    addHccCodeSuccess,
    updateHccCode,
    updateHccCodeSuccess,
    deleteHccCode,
    deleteHccCodeSuccess,
    getHccCodesCSV,
    getHccCodesCSVSuccess,
    clearHccCodesStore,
};

export default hccCodes.reducer;
