import React, { FC, useState, useEffect } from 'react';
import {PaginationModel} from '../../../models';
import styles from './Pagination.module.scss'

interface IProps {
  displayTitle: string,
  data: PaginationModel | null,
  currentPage: number,
  setCurrentPage: (currentPage: number) => void;
}


const Pagination: FC<IProps> = ({ data, currentPage, setCurrentPage , displayTitle}) => {

  useEffect(() => {
    if (data) {
      createPages(pages, data?.last_page, currentPage);
    }
  }, [data]);

  let pages: number[] = [];
  const createPages = (pages: number[], pagesCount: number, currentPage: number) => {
    if (pagesCount > 3) {
      if (currentPage > 2) {
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
          if (i == pagesCount) break;
        }
      } else {
        for (let i = 1; i <= 3; i++) {
          pages.push(i);
          if (i == pagesCount) break;
        }
      }
    } else {
      for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
      }
    }
  };

  if (data) {
    createPages(pages, data?.last_page, currentPage);
  }

  return (
    <>
      {data ? (
        <>
          {pages.length > 0 ? (
            <div className={'p-paginator'}>
              {data.total ? (
                <span className={'p-paginator-current'}>
                  <b>
                    {data.from} - {data.to}
                  </b>{' '}
                  of <b>&nbsp;{data.total}</b>{' '}
                  <span className={'p-paginator-currentText'}>
                    {displayTitle} displayed
                  </span>
                </span>
              ) : <span className={'p-paginator-current'}></span>}
              <button
                className={
                  currentPage === 1
                    ? 'p-paginator-first p-disabled'
                    : 'p-paginator-first'
                }
                onClick={() => setCurrentPage(1)}
              >
                First
              </button>
              <button
                disabled={!data.prev_page_url}
                style={
                  data.prev_page_url
                    ? {}
                    : { opacity: '0.45', cursor: 'default' }
                }
                className={'p-paginator-prev'}
                onClick={() =>
                  setCurrentPage(data.prev_page_url ? currentPage - 1 : 1)
                }
              >
                <span className={'pi-angle-left'}></span>
              </button>

              <span className={'p-paginator-pages'}>
                {pages.map((page, index) => (
                  <button
                    key={index}
                    className={
                      data.current_page == page
                        ? 'p-paginator-page p-highlight'
                        : 'p-paginator-page'
                    }
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </button>
                ))}
              </span>

              <button
                disabled={!data.next_page_url}
                style={
                  data.next_page_url
                    ? {}
                    : { opacity: '0.45', cursor: 'default' }
                }
                className={'p-paginator-next'}
                onClick={() =>
                  setCurrentPage(data.next_page_url ? currentPage + 1 : 1)
                }
              >
                <span className={'pi-angle-right'}></span>
              </button>
              <button
                className={
                  currentPage === data.last_page
                    ? 'p-paginator-last p-disabled'
                    : 'p-paginator-last'
                }
                onClick={() => setCurrentPage(data.last_page)}
              >
                Last
              </button>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Pagination;