import React, { FC, useState, useEffect } from 'react';
import styles from './PatientsListView.module.scss'

import {Link, useHistory} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { HeaderSearch } from '../../../common'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Checkbox from "../../../controls/CheckBox/CheckBox";
import Button from "../../../controls/Button/Button";
import Select from "../../../controls/Select/Select";
import Modal from "../../../common/Modal/Modal"
import {Routes} from "../../../../routes/routes";
import {
  ISelectOptions,
  PatientsListGetModel,
  PatientsListOrderingModel,
  PatientsTableModel,
  PatientSearchFields,
  PatientsListSearchingModel,
  UsersSearchingModel,
  PatientProfilesSearchingModel,
  PaginationModel,
  PatientTableFields,
} from "../../../../models";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import Pagination from "../../../common/Pagination/Pagination";
import {getPatientsList, deletePatients} from "../../../../store/patients/patientsSlice";
import {getColorType, getTemplates} from '../../../../store/templates/templatesSlice';
import {sendSurveyOnEmail, getSurveyForFill, clearSurveyChart} from '../../../../store/survey/surveySlice';
import {Loader} from "../../../controls";
import Input from "../../../controls/Input/Input";
import DatePicker from "react-datepicker";
import moment from "moment";
import {isEmptyObject} from "../../../../helpers";

interface IState {
  modalType: 'fill' | 'send' | 'delete' | 'search' | 'success' | 'permissions-fail' | null,
}

const selectionTimeToPass = [
  {label: '1 Day', value: '1440'},
  {label: '2 Day', value: '2880'},
  {label: 'Week', value: '10080'},
];

const initialPatientListConfig = {
  ordering: {
    users: {
      id: "desc",
      email: "asc"
    },
    patient_profiles: {
      first_name: "asc",
      last_name: "asc",
      dob: "asc",
      gender: "asc",
      record_number: "asc"
    }
  },
}

const initialState: IState = {
  modalType: null,
};

const PatientsList = () => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const history = useHistory();

  const navTo = (route: string) => {
    history.push(route);
  };

  const dispatch = useDispatch();

  const patients: PaginationModel<PatientTableFields[]> | null = useSelector((state: RootState) => state.patients.patientsList);
  const types = useSelector((state: RootState) => state.templates.colorType);
  const templates = useSelector((state: RootState) => state.templates.templates);

  const [typesForSelect, setTypesForSelect] = useState<ISelectOptions[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailCopied, setEmailCopied] = useState('');
  const [patientListConfig, setPatientListConfig] = useState<PatientsListGetModel>(initialPatientListConfig);
  const [patientsTable, setPatientsTable] = useState<PatientsTableModel[]>();
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [selectedTypeTemplates, setSelectedTypeTemplates] = useState<ISelectOptions[]>();
  const [selectedType, setSelectedType] = useState<ISelectOptions>();
  const [selectedTemplate, setSelectedTemplate] = useState<ISelectOptions>();
  const [selectedTimeToPass, setSelectedTimeToPass] = useState<ISelectOptions>();
  const [patientForFillId, setPatientForFillId] = useState<number>();
  const [searching, setSearching] = useState<PatientsListSearchingModel | null>(null);

  let [state, setState] = useState<IState>(initialState);
  const [permissionsError, setpermissionsError] = useState<string>(`You don't have permissions to manage Patients`);

  const handleModalOpen = (type: 'fill' | 'send' | 'delete' | 'search' | 'success' | null ) => () => {
    setState({...state, modalType: type})
  };

  const handleCloseModal = () => {
    setState({...state, modalType: null})
    setSelectedTemplate({});
    setSelectedType({});
  };

  const handleOpenSendQuestionnaire = () => {
    setState({...state, modalType: 'send'})
    dispatch(getColorType());
  }

  const handleOpenFillQuestionnaire = (id : number) => {
    setState({...state, modalType: 'fill'})
    dispatch(getColorType());
    setPatientForFillId(id);
  }

  const handleSearchPatient = (searchFields : PatientSearchFields) => {
    let searching : PatientsListSearchingModel = {};
    let users : UsersSearchingModel;
    let patient_profiles : PatientProfilesSearchingModel;

    if (patients) {
      setLoading(true);
    }

    users = {
      id: searchFields.id,
      email: searchFields.email,
    }

    patient_profiles = {
      first_name: searchFields.first_name,
      last_name: searchFields.last_name,
      dob:  moment(searchFields.dob).isValid() ?  moment(searchFields.dob).format('YYYY-MM-DD') : '',
      gender: `${searchFields.gender ? searchFields.gender?.value : ''}`,
      record_number: searchFields.record_number,
      ss_number: searchFields.ss_number,
      member_id: searchFields.member_id,
      pin: searchFields.pin,
     }

    users = Object.entries(users).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
    patient_profiles = Object.entries(patient_profiles).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});

    if (!isEmptyObject(users)) {
      searching.users = users;
    }

    if (!isEmptyObject(patient_profiles)) {
      searching.patient_profiles = patient_profiles;
    }

    handleCloseModal();
    setSearching(searching);

    currentPage === 1
      ? dispatch(
          getPatientsList({
            data: { searching: searching },
            page: currentPage,
            callback: () => {
              setLoading(false);
            },
          }),
        )
      : setCurrentPage(1);
  }

  const handleSendSurvey = (type : 'send' | 'fill') => {

    if (selectedTemplate) {
      if (type === 'send' && selectedTimeToPass) {
        dispatch(sendSurveyOnEmail(
          { data: {
             ids: checkedIds,
             time_to_leave: +selectedTimeToPass.value,
             template_id: +selectedTemplate?.value
           },
           callback: () => setState({...state, modalType: 'success'})
         }
       ))
        setCheckedIds([]);
      }

      if (type === 'fill' && patientForFillId) {
        dispatch(getSurveyForFill({
          data: {
            template_id: +selectedTemplate?.value,
            user_id: patientForFillId,
          },
          callback: (token : string) => {
            dispatch(clearSurveyChart());
            navTo(`/fill-questionnaire/${token}`)
          }
        }))
      }

      handleCloseModal();
    }
  }

  const sortColumn = (sortField : string) => {
    const config = JSON.parse(JSON.stringify(patientListConfig));
    let ordering : PatientsListOrderingModel;

    if (sortField === 'id' || sortField === 'email') {
      ordering = {
        users: {
          [sortField]: config.ordering.users[sortField] === 'asc' ? 'desc' : 'asc'
        }
      }
      config.ordering.users[sortField] = config.ordering.users[sortField] === 'asc' ? 'desc' : 'asc'
    } else {
      ordering = {
        patient_profiles: {
          [sortField]: config.ordering.patient_profiles[sortField] === 'asc' ? 'desc' : 'asc'
        }
      }
      config.ordering.patient_profiles[sortField] = config.ordering.patient_profiles[sortField] === 'asc' ? 'desc' : 'asc';
    }
    setPatientListConfig(config);
    if (patients) {
      setLoading(true);
    }

    const sendData : PatientsListGetModel = {};
    sendData.ordering = ordering;
    if ( searching !== null) {
      sendData.searching = searching;
    }

    dispatch(getPatientsList({data: sendData , page: currentPage, callback: () => {setLoading(false)}}))
  }

  const formatPatientsTable = (patients: PaginationModel<PatientTableFields[]>) => {
    const formattedPatients = patients.data.map((patient) => {
      let token: string = '';
      let titleArray: string[] = [];
      let title: string = '';
      let formattedSurveyHistory: { label: string; value: string }[] = [];
      patient.survey_history.map((item) => {
        titleArray = item.survey_history.split(' , ');
        token = titleArray.shift() || 'Noname';
        title = titleArray.join('');
        formattedSurveyHistory.push({ label: title, value: token });
      });

      return {
        id: patient.id,
        patient: `${patient.first_name} ${patient.last_name}`,
        email: patient.email,
        dob: patient.dob,
        gender: patient.gender,
        record_number: patient.record_number,
        survey_history: formattedSurveyHistory,
      };
    });
    setPatientsTable(formattedPatients);
  };

  const handleTypeChange = (value : ISelectOptions) => {
    setSelectedType(value);
    setSelectedTemplate({});

    dispatch(getTemplates({ data: {
        filtering: {
          type_colors: {
            id: [
              +value.value
            ]
          }
        },
        as_array: 1
      }
    }));

  }

  useEffect(() => {
    if (templates) {
      const formattedTemplates = Object.entries(templates).map( template => {
            return {
              label: template[1],
              value: template[0],
            }
        }
      )
      setSelectedTypeTemplates(formattedTemplates)
    }
  }, [templates])

  const checkItem = (patient: PatientsTableModel) => {
    setCheckedIds((prev) => {
      if (prev.includes(patient.id)) {
        prev = prev.filter((item) => item !== patient.id);
        return prev;
      } else {
        return [...prev, patient.id];
      }
    });
  };

  const deleteSelected = (arrayPatientsForDelete: number[]) => {
    dispatch(deletePatients({
      data: {ids: arrayPatientsForDelete},
      callback: () => {
        currentPage === 1 ?
            dispatch(getPatientsList({data: patientListConfig, page: currentPage, callback: () => {setLoading(false)}}))
            :
            setCurrentPage(1);
      }
    }));
    setCheckedIds([]);
    handleCloseModal();
  };

  const allSelected = () => {
    let checkedCounter = 0;
    if (patientsTable) {
      patientsTable.map((item) => (checkedIds.includes(item.id) ? checkedCounter++ : item));
      if (checkedIds.length > 0 && patientsTable.length === checkedCounter) {
        return true;
      }
    }
    return false;
  };

  const checkAll = () => {
    if (patientsTable) {
      let checkedCounter = 0;
      patientsTable.map((item) => (checkedIds.includes(item.id) ? checkedCounter++ : item));
      if (checkedIds.length > 0 && patientsTable?.length === checkedCounter) {
        let array: number[] = [];
        patientsTable.map((item) => array.push(item.id));
        const filteredCheckedIds = checkedIds.filter((item) => !array.includes(item));

        setCheckedIds(filteredCheckedIds);
      } else {
        let array: number[] = [...checkedIds];
        patientsTable.map((item) => (array.includes(item.id) ? item : array.push(item.id)));

        setCheckedIds(array);
      }
    }
  };

  useEffect(() => {
    // if (patients) {
    //   setLoading(true);
    // }

    const sendData = JSON.parse(JSON.stringify(patientListConfig));
    if ( searching !== null) {
      sendData.searching = searching;
    }

    dispatch(getPatientsList({
      data: sendData, page: currentPage, callback: (message) => {
        if (message.message !== 'success') {
          setState({...state, modalType: 'permissions-fail'});
          // setpermissionsError(message.message)
        }
        setLoading(false);
      }
    }));
  }, [currentPage]);

  useEffect(() => {
    if (patients?.data[0]?.id) {
      formatPatientsTable(patients)
    } else {
      setPatientsTable([]);
    }
  }, [patients])

  useEffect(() => {
    if(types && types[0]) {
      const selectTypes = types.map(type => {
        return {
          label: type.title,
          value: type.id,
        }
      })
      setTypesForSelect(selectTypes);
    }
  },[types])

  const headerEdit = (
    <span className='p-column-title -flex'>
      <span className='p-column-title-inner'>Edit</span>
      <Checkbox
        checked={allSelected()}
        onChange={checkAll}/>
    </span>
  );

  const templateEmail = (rowData: any) => {
    return (
      <a
        style={{ position: 'relative' }}
        onClick={(e) => {
          navigator.clipboard.writeText(e.target.innerHTML);
          setEmailCopied(`email-${rowData.id}`);
          setTimeout(() => setEmailCopied(''), 1000);
        }}>
        {emailCopied === `email-${rowData.id}` ? (
          <span className="b-tooltipCopy">Email copied</span>
        ) : null}
        {rowData.email}
      </a>
    );
  };

  const templateHistorySelection = (rowData: any) => {
    return (
      <>
        {rowData.survey_history.length ? (
          <Select
            value={rowData.survey_history[0]}
            options={rowData.survey_history}
            label={''}
            menuPosition={'fixed'}
            className={'mb-0'}
            onChange={(value) => navTo(`/fill-questionnaire/${value.value}`)}
          />
        ) : (
          <span style={{textAlign: 'center'}}>No Surveys</span>
        )}
      </>
    );
  };

  const templateEdit = (patient : PatientsTableModel) => {
    return (
      <div className={styles.patientControl}>
        <a 
            className={styles.patientDocLink} 
            href="#" 
            aria-label=""
            onClick={() => handleOpenFillQuestionnaire(patient.id)}
            data-tip data-for='patientDoc'>
          <ReactTooltip id='patientDoc' className='b-tooltip'>
            Fill Survey
          </ReactTooltip>  
        </a>
        {userData?.option.permissions.create_edit_patient ? 
        <Link
            to={`/patient-edit/${patient.id}`}
            className={styles.patientEditLink} 
            href="#" 
            aria-label="Edit"
            data-tip data-for='patientEdit'>
          <ReactTooltip id='patientEdit' className='b-tooltip'>
            Edit
          </ReactTooltip>
        </Link>
        : null}
        <Checkbox 
          checked={checkedIds.includes(patient.id)}
          onChange={() => checkItem(patient)}/>
      </div>
    )
  };

  const footer = (
    <div className="patientControlList">
      {!userData?.option.permissions.create_edit_patient ? 
        <ReactTooltip id='patientsListBtn' className='b-tooltipPermission'>
          {`You don't have permissions to manage Patients`}
        </ReactTooltip> : null}
        
      <Link 
      to={userData?.option.permissions.create_edit_patient ? Routes.PATIENTS_ADD : '#'} 
      className={`footerBtn ${userData?.option.permissions.create_edit_patient ? '' :'disabled'}`}
      data-tip data-for='patientsListBtn'
      >
        add new patient</Link>
        
      <div className="patientControlList__innerWrapper">
        <Button
          title='send Survey'
          dataFor={'sendToolTip'}
          className={'darkest -uppercase'}
          onPress={!checkedIds.length ? (() => null) : handleOpenSendQuestionnaire}/>
          {!checkedIds.length ?
            <ReactTooltip id='sendToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
              Please select at least one patient to send survey
            </ReactTooltip>
          : null}
        {userData?.option.permissions.create_edit_patient ? 
        <>
          <Button
            title='delete'
            dataFor={'deleteToolTip'}
            className={'darkest -uppercase'}
            onPress={!checkedIds.length ? (() => null) : handleModalOpen('delete')}/>
            {!checkedIds.length ?
              <ReactTooltip id='deleteToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
                Please select at least one patient to delete patient
              </ReactTooltip>
            : null}
        </>
        : null}
      </div>
    </div>
  );

  const columnHeader = (sortField : string , columnName : string) => {
    return (
        <>
          <div className={styles.customTableHeader} onClick={() => sortColumn(sortField)}>
            <span>{columnName}</span>
            <span className={'p-sortable-column-icon pi pi-fw pi-sort-alt'}></span>
          </div>
        </>
    )
  }

  const typeInfo = (
    <div className="p-datatable-filters__popupContainerInfo">
      <button type="button" className="p-datatable-filters__popupOpenerInfo"></button>
      <div className="p-datatable-filters__popupInfo">
        <div className="p-datatable-filters__popupFrame">
          <div className="p-datatable-filters__popupHeading">TYPE INFO</div>
          <div className="p-datatable-filters__popupContent">
            <ul className="p-datatable-filters__popupList">
              <li>
                <b>Hybrid survey</b> - Has a score and a dial. This is for clinicians only.
              </li>
              <li>
                <b>Task survey</b> that creates a task list or action plan based on how the questions are
                answered, Example Social determinants of Health. Only for clinicians to fill out.
              </li>
              <li>
                <b>Standard survey</b> - Just informational to get responses from patient or survey
                taker, sent to patient to fill out. Example patient pain. Patients and clinicians
                may fill out.
              </li>
              <li>
                <b>Scored survey</b> (with score) - Patients or clinicians
                may fill out
              </li>
              <li>
                <b>Weighted survey</b> (with dial) example, the readmission surveys. Only clinicians
                fill out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  return (
   <>
    { patients ?
    <div className={'b-mainContainer__wrapper'}>
      <HeaderSearch
          pageName='Patients'
          searchPatient={handleSearchPatient}
          openModal={handleModalOpen}
          closeModal={handleCloseModal}
          isSearchOpen={state.modalType === 'search'}
          isSearhVisible={true}
      />
      {/* {loading && <Loader/>} */}
      <div className={'b-mainContainer__wrapperInner'}>
        <div className={`b-mainContainer__contentWrapper ${styles.tableWrapper}`}>
          <DataTable 
            value={patientsTable}
            className='-paginator'
            footer={footer}
            // paginator
            // paginatorTemplate={template}
            // rows={10}
            >
            <Column field='id' header={columnHeader('id', 'ID')} />
            <Column field='patient' header={columnHeader('first_name', 'Patient')} />
            <Column field='email' header={columnHeader('email', 'Email')} body={templateEmail} />
            <Column field='dob' header={columnHeader('dob', 'DoB')} />
            <Column field='gender' header={columnHeader('gender', 'Gender')} />
            <Column field='record_number' header={columnHeader('record_number', 'Record Number')} />
            <Column style={{maxWidth: 140}} field='patient_survey' header='Patient Survey' body={templateHistorySelection}/>
            <Column field='edit' header={headerEdit} body={templateEdit}/>
          </DataTable>
          <Pagination
              displayTitle={'patients'}
              data={patients}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      {state.modalType === 'delete' && <Modal
          title='Delete Patients'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Are you sure you want to delete <br/>
            this patient(s)?
          </div>
          <div className={'b-modal__btnWrapper'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Yes'} onPress={() => deleteSelected(checkedIds)} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'send' && <Modal
          title='Send Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect?.filter(item => item.label === 'Standard survey' || item.label === 'Scored survey')}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <Select 
              label={'Time to pass *'}
              value={selectedTimeToPass}
              options={selectionTimeToPass}
              width='100%'
              onChange={value => setSelectedTimeToPass(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Send'} disabled={!selectedTemplate?.value || !selectedTimeToPass} onPress={() => handleSendSurvey('send')} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
      {state.modalType === 'success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Survey<i><b>&nbsp;
              {/* “${selectedTemplate?.label}” */}
               </b></i>&nbsp;has been sent
          </div>
        </div>
      </Modal>}
      {state.modalType === 'fill' && <Modal
          title='Fill Survey'
          width={520}
          onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <Select 
              label={'Select a Type *'}
              withIconInfo={true}
              info={typeInfo}
              value={selectedType}
              options={typesForSelect}
              width='100%'
              onChange={value => handleTypeChange(value)}/>
          <Select 
              label={'Select a Survey *'}
              value={selectedTemplate}
              options={selectedTypeTemplates}
              width='100%'
              onChange={value => setSelectedTemplate(value)}/>
          <div className={'b-modal__btnWrapper -mt-40'}>
            <Button title={'Cancel'} onPress={handleCloseModal} type={'transparency'} width='100px'/>
            <Button title={'Fill'} disabled={!selectedTemplate?.value} onPress={() => handleSendSurvey('fill')} className={'-ml-10'} width='100px'/>
          </div>
        </div>
      </Modal> }
    </div>
    : null}
    {state.modalType === 'permissions-fail' && <Modal
          title='Error'
          width={520}
          onClose={() => {
            setState({...state, modalType: null}),
            // setpermissionsError(''),
            history.push(Routes.DASHBOARD);
          }}>
          <div className={'b-modal'}>
            <div className={'b-modal__text'}>
              {permissionsError}
            </div>
          </div>
         </Modal>}
   </>
  );
};

export default PatientsList;
