import React from 'react';
import './Image.scss'
interface IImage {
    width?: string;
    height: string;
    source: string;
    className?: string
}

const Image = ({width, height, source, className}: IImage) => {
    return <img
        className={`image ${className || ''}`}
        src={source}
        style={{width: width || 'auto', height: height}}/>
};

export default Image;
