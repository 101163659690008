import React, { FC, useState, useEffect} from 'react';
import styles from './PatientsAddView.module.scss'

import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import {Routes} from "../../../../routes/routes";
import Modal from "../../../common/Modal/Modal";
import DatePicker from "react-datepicker";

import { HeaderSearch } from '../../../common'
import Button from "../../../controls/Button/Button";
import Select from "../../../controls/Select/Select";
import TitleInner from "../../../common/TitleInner/TitleInner";
import Input from "../../../controls/Input/Input";
import Textarea from "../../../controls/Textarea/Textarea";

import {
  CareProvidersModel, ISelectOptions, KeyValueModel, PatientGeneralModel,
  PatientContactInformationModel,
  PatientProfilesModel, PatientFormErrorsModel, PatientFormModel, PaginationModel, HccCodesTableFields, HccCodesUpdateModel, PatientHccCodesModel
} from '../../../../models/index'
import {getDoctorsBySearch, getStateList, addPatient, getPatient, updatePatient, getHccCodesBySearch} from "../../../../store/patients/patientsSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/reducers";
import moment from "moment";
import {email, phone, ssNumber, validate, zip} from "../../../../helpers/validatior";
import {isEmptyObject} from "../../../../helpers";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactTooltip from 'react-tooltip';
import { clearHccCodesStore, getHccCodesCSV, getPatientHccCodeList } from '../../../../store/hccCodes/hccCodesSlice';

interface IState {
  modalType: 'add-code' | 'update-code' | 'create-success' | 'update-success' | 'create-error' | null,
}

const initialState: IState = {
  modalType: null,
};

const selectGender = [
  {label: 'Male', value: 'male'},
  {label: 'Female', value: 'female'},
];

const usersValidatorScheme = {
  required: ['email', 'doctor_ids'],
  custom: [
    email(['email'])
  ],
};

const patientProfilesValidatorScheme = {
  required: [
    'first_name',
    'last_name',
    'dob',
    'gender',
    // 'record_number',
    'ss_number',
    // 'member_id',
    // 'pin',
    // 'comments'
  ],
  custom: [
    ssNumber(['ss_number'])
  ],
};

const contactsValidatorScheme = {
  required: [
    'state_code',
    'address',
    'city',
    'zip',
    // 'text',
    'phone',
    'mobile',
    'emergency_contact_name',
    'telephone_number',
    'cell_phone_number',
    // 'email',
  ],
  custom: [
    // email(['email']),
    phone(['phone']),
    phone(['mobile']),
    phone(['telephone_number']),
    phone(['cell_phone_number']),
    zip(['zip']),
  ],
};

const careProvidersValidatorScheme = {
  required: [
    'first_name',
    'last_name',
    'phone',
    'mobile',
    'text_number',
    'email',
  ],
  custom: [
    email(['email']),
    phone(['phone']),
    phone(['mobile']),
  ],
};

const PatientsAdd = () => {

  const history = useHistory();

  const {pathname} = useLocation<KeyValueModel<string>>();

  const {id : idForUpdate} = useParams<KeyValueModel<string>>();

  const dispatch = useDispatch();

  const initialPatient = {
    email: "",
    doctor_ids: null,
  }

  const initialPatientHccCodes = {
    hcc_code_ids: null,
  }

  const initialPatientProfiles = {
    date_of_admission: new Date(),
    planned_discharge_date: new Date(),
    discharge_date: new Date(),
    first_name: "",
    last_name: "",
    dob: new Date(),
    gender: null,
    record_number: "",
    ss_number: "",
    member_id: "",
    pin: "",
    comments: "",
  }

  const initialPatientContacts = {
    state_code: null,
    address: "",
    city: "",
    // text: "",
    zip: "",
    phone: "",
    mobile: "",
    emergency_contact_name: "",
    telephone_number: "",
    cell_phone_number: "",
    // email: "",
  }

  const initialCareProviders = {
    first_name: "",
    last_name: "",
    phone: "",
    mobile: "",
    text_number: "",
    email: "",
  }

  const doctors = useSelector((state: RootState) => state.patients.doctorsList);
  const hccCodes = useSelector((state: RootState) => state.patients.hccCodeListBySearch);
  const states = useSelector((state: RootState) => state.patients.stateList);
  const hccCodesList: PaginationModel<HccCodesTableFields[]> | null = useSelector((state: RootState) => state.hccCodes.hccCodesList);

  const [hccCodesTable, setHccCodesTable] = useState<HccCodesTableFields[] | []>([]);

  const [user, setUser] = useState<PatientGeneralModel>(initialPatient);
  const [hccCodesData, setHccCodesData] = useState<PatientHccCodesModel>(initialPatientHccCodes);
  const [userProfiles, setUserProfiles] = useState<PatientProfilesModel>(initialPatientProfiles);
  const [userContacts, setUserContacts] = useState<PatientContactInformationModel>(initialPatientContacts);
  const [careProvider, setCareProvider] = useState<CareProvidersModel>(initialCareProviders);
  const [doctorList, setDoctorList] = useState<ISelectOptions[]>();
  const [hccCodeListBySearch, sethccCodeListBySearch] = useState<ISelectOptions[]>();

  const [errors, setErrors] = useState<PatientFormErrorsModel>();
  const [patientToUpdate, setPatientToUpdate] = useState<PatientFormModel | null>();
  const [permissionsError, setPermissionsError] = useState<string>('');
  const [hccCodesWasChanged, setHccCodesWasChanged] = useState(false);

  let [state, setState] = useState<IState>(initialState);

  const handleExportCsv = (e: Event): void => {
    e.preventDefault();
    dispatch(getHccCodesCSV({ data: { user_id: +idForUpdate } }));
  };

  const handleCloseModal = () => {
    setState({ ...state, modalType: null });
    history.push(Routes.PATIENTS_LIST);
  };

  const handleOpenAddCodeModal = () => {
    setState({ ...state, modalType: 'add-code' });
  };

  const deleteCode = (id: number) => {
    const hccCodesTableNew = hccCodesTable.filter((item) => item.id !== id);

    setHccCodesTable(hccCodesTableNew);
    setHccCodesWasChanged(true);
  };

  const navTo = (route: string) => () => {
    history.push(route);
  };

  const handleChangeDoctorsInputValue = (value:string) => {
    dispatch(getDoctorsBySearch({search: value}));
  }

  const handleChangeHccCodesInputValue = (value: string) => {
    dispatch(getHccCodesBySearch({ data: { search: value } }));
  };

  let abbrevStates: ISelectOptions[] = [];
  if (states) {
    abbrevStates = states.map(
      (item) => (item = { ...item, label: `${item.value}` })
    );
  }

  const handleAddPatient = (e:Event) => {
    e.preventDefault();
    const {errors : usersErrors} = validate(usersValidatorScheme, user);
    const {errors : patientProfilesErrors} = validate(patientProfilesValidatorScheme, userProfiles);
    const {errors : contactsErrors} = validate(contactsValidatorScheme, userContacts);
    const {errors : careProviders} = validate(careProvidersValidatorScheme, careProvider);

    setErrors({
      users: usersErrors,
      patient_profiles: patientProfilesErrors,
      contacts: contactsErrors,
      care_providers: careProviders,
    });

    if (   isEmptyObject(usersErrors)
        && isEmptyObject(patientProfilesErrors)
        && isEmptyObject(contactsErrors)
        && isEmptyObject(careProviders))
    {
      const userData = {
        ...user,
        doctor_ids: user.doctor_ids?.map(doctor => {
          return +doctor.value;
        })
      }

      const hccCodesDataNew = Object.assign(
        {},
        ...hccCodesTable?.map((item) => ({
          [`${item.id}`]: moment(new Date(item.created_at.split('-').join('/'))).format('YYYY-MM-DD'),
        })),
      );

      const patientProfilesData = {
        ...userProfiles,
        date_of_admission: moment(userProfiles.date_of_admission).format('YYYY-MM-DD'),
        planned_discharge_date: moment(userProfiles.planned_discharge_date).format('YYYY-MM-DD'),
        discharge_date: moment(userProfiles.discharge_date).format('YYYY-MM-DD'),
        dob: moment(userProfiles.dob).format('YYYY-MM-DD'),
        gender: userProfiles.gender?.value,
        ss_number: userProfiles.ss_number.split('-').join(''),
      }

      const userContactsData = {
        ...userContacts,
        state_code: userContacts.state_code?.value,
      }

      const sendData = {
        users: userData,
        hcc_codes: hccCodesDataNew,
        patient_profiles: patientProfilesData,
        contacts: userContactsData,
        care_providers: careProvider,
      }

      if (pathname.indexOf("/patient-edit") !== -1) {
        dispatch(updatePatient({data: sendData, callback: (message) => {
          if (message.message === 'success') {
            setState({...state, modalType: 'update-success'});
            setHccCodesWasChanged(false);
          } else {
            for (let key in message) {
              if (key !== 'users.email') {
                setPermissionsError(message[key][0]);
                setState({...state, modalType: 'create-error'});
              } else {
                setErrors({
                  users: { email: 'Email has already been taken' },
                  patient_profiles: {},
                  contacts: {},
                  care_providers: {}
                });
              }
            }
          }
          }}));
      } else {
        dispatch(addPatient({data: sendData, callback: (message) => {
          if (message.message === 'success') {
            setState({...state, modalType: 'create-success'});
          } else {
            for (let key in message) {
              if (key !== 'users.email') {
                setPermissionsError(message[key][0]);
                setState({...state, modalType: 'create-error'});
              } else {
                setErrors({
                  users: { email: 'Email has already been taken' },
                  patient_profiles: {},
                  contacts: {},
                  care_providers: {}
                });
              }
            }
          }
        }}));
      }
    }
  }

  useEffect(() => {
    if(patientToUpdate && states && states[0]) {
      let abbrevStates: ISelectOptions[] = [];
      abbrevStates = states.map((item) => item =  {...item, label: `${item.value}`});
      const genderId = selectGender.findIndex(gender => gender.label === patientToUpdate.patient_profiles.gender);
      const stateId = abbrevStates.findIndex(state => state.value === patientToUpdate.contacts.state_code);

      const userData = {
        ...patientToUpdate.users,
        doctor_ids: patientToUpdate.users.doctor_ids?.map(doctor => {
          return {
            label: `${doctor.first_name} ${doctor.last_name}`,
            value: doctor.id,
          }
        }),
      }

      const patientProfilesData = {
        ...patientToUpdate.patient_profiles,
        date_of_admission: new Date(patientToUpdate.patient_profiles.date_of_admission.split('-').join('/')),
        planned_discharge_date: new Date(patientToUpdate.patient_profiles.planned_discharge_date.split('-').join('/')),
        discharge_date: new Date(patientToUpdate.patient_profiles.discharge_date.split('-').join('/')),
        dob: new Date(patientToUpdate.patient_profiles.dob.split('-').join('/')),
        gender: selectGender[genderId],
      }

      const userContactsData = {
        ...patientToUpdate.contacts,
        state_code: abbrevStates[stateId],
      }

      setUser(userData);
      setUserProfiles(patientProfilesData);
      setUserContacts(userContactsData);
      setCareProvider(patientToUpdate.care_providers);
    }
  }, [states, patientToUpdate]);

  useEffect(() => {
    if (pathname.indexOf('/patient-edit') !== -1) {
      dispatch(
        getPatient({
          id: +idForUpdate,
          callback: (response) => {
            setPatientToUpdate(response);
          },
        }),
      );
    } else {
      dispatch(clearHccCodesStore());
    }
  }, [pathname]);

  useEffect(() => {
    if (doctors) {
      const list = doctors.map((doctor) => {
        return {
          label: `${doctor.first_name} ${doctor.last_name}`,
          value: doctor.id,
        };
      });

      setDoctorList(list);
    }
  }, [doctors]);

  const handleChangeItem = (state: string, elem: string, value: any) => {
    if (state === 'userProfiles') {
      setUserProfiles({ ...userProfiles, [elem]: value });
    }

    if (state === 'userContacts') {
      setUserContacts({ ...userContacts, [elem]: value });
    }

    if (state === 'user') {
      setUser({ ...user, [elem]: value });
    }

    if (state === 'careProvider') {
      setCareProvider({ ...careProvider, [elem]: value });
    }

    setHccCodesWasChanged(true);
  };

  useEffect(() => {
    if (hccCodes) {
      let list: ISelectOptions[] = [];

      if (hccCodesTable?.length) {
        hccCodes.map((code) => {
          let hasCode = false;
          for (let el of hccCodesTable) {
            if (el.id === code.id) {
              hasCode = true;
              break;
            }
          }

          if (!hasCode) {
            list.push({ label: code.text, value: code.id });
          }
        });
      } else {
        list = hccCodes.map((code) => {
          return {
            label: code.text,
            value: code.id,
          };
        });
      }

      sethccCodeListBySearch(list);
    }
  }, [hccCodes, hccCodesTable]);

  useEffect(() => {
    dispatch(clearHccCodesStore());

    dispatch(getStateList());
    dispatch(getDoctorsBySearch({ search: '' }));
    if (pathname.indexOf('/patient-edit') !== -1) {
      dispatch(getPatientHccCodeList({ data: { user_id: +idForUpdate }, page: 1, ipp: 250 }));
    }
      dispatch(getHccCodesBySearch({ data: { search: '' } }));
  }, []);

  useEffect(() => {
    if (hccCodesList) {
      const table = hccCodesList.data.map((item) => {
        return {
          id: item.id,
          created_at: item.created_at,
          code: item.code,
          diagnostic: item.diagnostic,
        };
      });

      setHccCodesTable(table);
    } else {
      setHccCodesTable([]);
    }
  }, [hccCodesList]);

  useEffect(() => {
    const inputErrorElements = document.getElementsByName('input_error');
    if (inputErrorElements.length) {
      const coord = inputErrorElements[0].getBoundingClientRect();
      window.scrollTo(0, coord.y);
    }
  }, [errors]);

  const handleAddCode = () => {
    const hccCodesDataNew = {
      hcc_code_ids: hccCodesData.hcc_code_ids?.map((item: ISelectOptions) => {
        return +item.value;
      }),
    };

    const hccCodesTableNew: HccCodesTableFields[] = [];

    hccCodesDataNew.hcc_code_ids.reverse().map((item: number) =>
      hccCodes?.map((code) =>
        code.id === item
          ? hccCodesTableNew.push({
              id: code.id,
              created_at: moment(new Date()).format('MM-DD-YYYY'),
              code: code.first_name,
              diagnostic: code.last_name,
            })
          : code,
      ),
    );
    setHccCodesData({ ...hccCodesData, hcc_code_ids: null });
    setHccCodesTable([...hccCodesTableNew, ...hccCodesTable]);
    setState({ ...state, modalType: null });
    setHccCodesWasChanged(true);
  };

  const dateOfCreateHeader = (
    <div>
      <span className="p-column-title">Date added</span>
    </div>
  );

  const codeHeader = (
    <div>
      <span className="p-column-title">Code</span>
    </div>
  );

  const diagnosticHeader = (
    <div>
      <span className="p-column-title">Diagnostic</span>
    </div>
  );

  const headerEdit = (
    <Button
      title={'add code'}
      className={'-uppercase '}
      width={'145px'}
      onPress={handleOpenAddCodeModal}
    />
  );

  const hccCodeBody = (rowData: any) => {
    return <span>{rowData.code}</span>;
  };

  const hccDiagnostic = (rowData: any) => {
    return <span>{rowData.diagnostic}</span>;
  };

  const hccDateBody = (rowData: any) => {
    return (
      <DatePicker
        maxDate={new Date()}
        dateFormat={'MM.dd.yyyy'}
        selected={new Date(rowData.created_at.split('-').join('/'))}
        className={'b-form__datePikerInput'}
        onChange={value => {
          setHccCodesTable((prevState) => {
          const table = prevState.map((item) =>
            item.id === rowData.id
              ? {
                  ...item,
                  created_at:
                    (value && moment(value).format('MM-DD-YYYY')) ||
                    moment(new Date()).format('MM-DD-YYYY'),
                }
              : item,
          );
          return table;
        })
        setHccCodesWasChanged(true)
        }}/>
    );
  };

  const hccEditBody = (rowData: any) => {
    return (
      <div className={styles.hccControl}>
        <button
          className={styles.hccDelete}
          aria-label="Remove"
          data-tip
          data-for="remove"
          onClick={() => deleteCode(rowData.id)}>
          <ReactTooltip id="remove" className="b-tooltip">
            Remove
          </ReactTooltip>
        </button>
      </div>
    );
  };

  const ssNumberValue = (value: string) => {
    let newValue = value;

    if (value.length < 3) {
      newValue = value;
    } else if (value.length > 2 && value.length < 4) {
      newValue = value.split('-').join('');
      newValue = `${newValue.slice(0, 3)}-`;
    } else if (value.length > 3 && value.length < 7) {
      newValue = value.split('-').join('');
      if (value.length < 6) {
        newValue = `${newValue.slice(0, 3)}-${newValue.slice(3)}`;
      } else {
        newValue = `${newValue.slice(0, 3)}-${newValue.slice(3)}-`;
      }
    } else if (value.length > 7) {
      newValue = value.split('-').join('');
      newValue = `${newValue.slice(0, 3)}-${newValue.slice(3, 5)}-${newValue.slice(5)}`;
    }

    if (value[value.length - 1] === '-') {
      newValue = newValue.slice(0, -1);
    }

    return newValue;
  };

  const navToAllHccCodes = () => {
    const userData = {
      ...user,
      doctor_ids: user.doctor_ids?.map((doctor) => {
        return +doctor.value;
      }),
    };

    const hccCodesDataNew = Object.assign(
      {},
      ...hccCodesTable?.map((item) => ({
        [`${item.id}`]: moment(new Date(item.created_at.split('-').join('/'))).format('YYYY-MM-DD'),
      })),
    );

    const patientProfilesData = {
      ...userProfiles,
      date_of_admission: moment(userProfiles.date_of_admission).format('YYYY-MM-DD'),
      planned_discharge_date: moment(userProfiles.planned_discharge_date).format('YYYY-MM-DD'),
      discharge_date: moment(userProfiles.discharge_date).format('YYYY-MM-DD'),
      dob: moment(userProfiles.dob).format('YYYY-MM-DD'),
      gender: userProfiles.gender?.value,
      ss_number: userProfiles.ss_number.split('-').join(''),
    };

    const userContactsData = {
      ...userContacts,
      state_code: userContacts.state_code?.value,
    };

    const sendData = {
      users: userData,
      hcc_codes: hccCodesDataNew,
      patient_profiles: patientProfilesData,
      contacts: userContactsData,
      care_providers: careProvider,
    };

    const location = {
      pathname: `/hcc-codes/${idForUpdate}`,
      state: sendData,
    };

    history.push(location);
  };

  return (
      <div className={'b-mainContainer__wrapper'}>
        <HeaderSearch
            pageName='Patients'
            isSearhVisible={true}
        />
        <div className={'b-mainContainer__wrapperInner'}>
          <div className={'b-mainContainer__contentWrapper'}>
            <TitleInner title={pathname.indexOf("/patient-edit") !== -1 ? 'Update Patient' : 'Add New Patient'}/>
            <form className={'b-form'}>
            <div className={'b-form__container'}>

              <div className={'b-form__containerLeftBox'}>
              <div className={'b-form__subTitle'}>
                General Information
              </div>
              <div className={'b-form__row -three_column'}>
                <div className={'b-form__formGroup'}>
                  <div className={'b-form__datePikerLabel'}>
                    Date of Admission *
                  </div>
                  <DatePicker minDate={new Date()}
                              dateFormat={'MM.dd.yyyy'}
                              selected={userProfiles.date_of_admission}
                              onChange={date => handleChangeItem('userProfiles', 'date_of_admission', date)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <div className={'b-form__datePikerLabel'}>
                    Planned Discharge Date *
                  </div>
                  <DatePicker minDate={new Date()}
                              dateFormat={'MM.dd.yyyy'}
                              selected={userProfiles.planned_discharge_date}
                              onChange={date => handleChangeItem('userProfiles', 'planned_discharge_date', date)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <div className={'b-form__datePikerLabel'}>
                    Discharge Date *
                  </div>
                  <DatePicker minDate={new Date()}
                              dateFormat={'MM.dd.yyyy'}
                              selected={userProfiles.discharge_date}
                              onChange={date => handleChangeItem('userProfiles', 'discharge_date', date)}/>
                </div>
                
              </div>
              

              <div className={'b-form__row -three_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userProfiles.first_name}
                      label={'First Name *'}
                      error={errors?.patient_profiles.first_name}
                      onChange={value => handleChangeItem('userProfiles', 'first_name', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userProfiles.last_name}
                      error={errors?.patient_profiles.last_name}
                      label={'Last Name * '}
                      onChange={value => handleChangeItem('userProfiles', 'last_name', value)}/>
                </div>
              <div className={'b-form__row -two_column hccTwo'}>

                <div className={'b-form__formGroup'}>
                  <div className={'b-form__datePikerLabel'}>
                    Date of Birth *
                  </div>
                  <DatePicker
                      maxDate={new Date()}
                      dateFormat={'MM.dd.yyyy'}
                      selected={userProfiles.dob}
                      className={'b-form__datePikerInput'}
                      // error={errors?.patient_profiles.dob}
                      showYearDropdown
                      dropdownMode="select"
                      onChange={date => handleChangeItem('userProfiles', 'dob', date)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Select
                      defaultInputValue={userProfiles.gender?.value.toString()}
                      value={userProfiles.gender}
                      options={selectGender}
                      label={'Gender *'}
                      className={'mb-0'}
                      error={errors?.patient_profiles.gender}
                      onChange={value => handleChangeItem('userProfiles', 'gender', value)}/>
                </div>
              </div>
              </div>

              <div className={'b-form__row -three_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.address}
                      label={'Address *'}
                      autoComplete={'new-password'}
                      error={errors?.contacts.address}
                      onChange={value => handleChangeItem('userContacts', 'address', value)}/>
                </div>
                  <div className={'b-form__formGroup'}>
                    <Input
                        value={userContacts.city}
                        label={'City *'}
                        error={errors?.contacts.city}
                        autoComplete={'new-password'}
                        onChange={value => handleChangeItem('userContacts', 'city', value)}/>
                  </div>
                  <div className={'b-form__row -two_column hccTwo'}>
                    <div className={'b-form__formGroup'}>
                      <Select
                          defaultInputValue={userContacts.state_code?.value.toString()}
                          value={userContacts.state_code}
                          error={errors?.contacts.state_code}
                          searchable={true}
                          options={abbrevStates}
                          label={'State *'}
                          className={'mb-0'}
                          onChange={value => handleChangeItem('userContacts', 'state_code', value)}/>
                    </div>
                    <div className={'b-form__formGroup'}>
                      <Input
                          value={userContacts.zip}
                          label={'Zip Code *'}
                          error={errors?.contacts.zip}
                          onChange={value => handleChangeItem('userContacts', 'zip', value)}/>
                    </div>
                  </div>
              </div>
              <div className={'b-form__row -three_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={user.email}
                      label={'Email *'}
                      error={errors?.users.email}
                      onChange={value => handleChangeItem('user', 'email', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.phone}
                      label={'Phone *'}
                      error={errors?.contacts.phone}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('userContacts', 'phone', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.mobile}
                      label={'Mobile *'}
                      error={errors?.contacts.mobile}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('userContacts', 'mobile', value)}/>
                </div>
    
              </div>
              <div className={'b-form__row hccFour'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userProfiles.record_number}
                      label={'Record Number'}
                      error={errors?.patient_profiles.record_number}
                      onChange={value => handleChangeItem('userProfiles', 'record_number', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={ssNumberValue(userProfiles.ss_number)}
                      label={'SS Number *'}
                      error={errors?.patient_profiles.ss_number}
                      onChange={value => value === '0' || value === '00' || Number(value) || Number(value.split('-').join('')) && value.length < 12 || value === '' ? handleChangeItem('userProfiles', 'ss_number', value) : null}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userProfiles.member_id}
                      label={'Member ID'}
                      error={errors?.patient_profiles.member_id}
                      onChange={value => handleChangeItem('userProfiles', 'member_id', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userProfiles.pin}
                      label={'PIN'}
                      error={errors?.patient_profiles.pin}
                      onChange={value => handleChangeItem('userProfiles', 'pin', value)}/>
                </div>
              </div>

              <div className={'b-form__row -three_column'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.emergency_contact_name}
                      label={'Emergency Contact Name * '}
                      error={errors?.contacts.emergency_contact_name}
                      onChange={value => handleChangeItem('userContacts', 'emergency_contact_name', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.telephone_number}
                      label={'Telephone Number * '}
                      error={errors?.contacts.telephone_number}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('userContacts', 'telephone_number', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={userContacts.cell_phone_number}
                      label={'Cell Phone Number *'}
                      error={errors?.contacts.cell_phone_number}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('userContacts', 'cell_phone_number', value)}/>
                </div>
              </div>

              <div className={'b-form__bigRow'}>
                <div className={'b-form__formGroup'}>
                  <Select
                      value={user.doctor_ids}
                      defaultValue={user.doctor_ids}
                      error={errors?.users.doctor_ids}
                      options={doctorList}
                      label={'Clinicians *'}
                      height={'auto'}
                      onInputChange={handleChangeDoctorsInputValue}
                      className={'mb-0'}
                      placeholder={'Select One or More'}
                      onChange={value => handleChangeItem('user', 'doctor_ids', value)}
                      multi={true}
                      searchable={true}
                  />
                </div>
              </div>
             
              {/* <div className={'b-form__subTitle'}>
                Contact Information
              </div> */}
              </div>

              <div className={'b-form__containerRightBox'}>
              <div className={'b-form__subTitle'}>
                Care Provider
              </div>
              <div className={'b-form__row -one_column hccOne'}>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.first_name}
                      label={'First Name * '}
                      error={errors?.care_providers.first_name}
                      onChange={value => handleChangeItem('careProvider', 'first_name', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.last_name}
                      label={'Last Name * '}
                      error={errors?.care_providers.last_name}
                      onChange={value => handleChangeItem('careProvider', 'last_name', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.phone}
                      label={'Phone *'}
                      error={errors?.care_providers.phone}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('careProvider', 'phone', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.mobile}
                      label={'Mobile *'}
                      error={errors?.care_providers.mobile}
                      autoComplete={'new-password'}
                      onChange={value => handleChangeItem('careProvider', 'mobile', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.text_number}
                      label={'Text Number *'}
                      error={errors?.care_providers.text_number}
                      onChange={value => handleChangeItem('careProvider', 'text_number', value)}/>
                </div>
                <div className={'b-form__formGroup'}>
                  <Input
                      value={careProvider.email}
                      label={'Email Address *'}
                      error={errors?.care_providers.email}
                      onChange={value => handleChangeItem('careProvider', 'email', value)}/>
                </div>
              </div>
              <div className={'b-form__formGroup'}>
                <Textarea
                    value={userProfiles.comments}
                    label={'Comments'}
                    error={errors?.patient_profiles.comments}
                    onChange={value => handleChangeItem('userProfiles', 'comments', value)}/>
              </div>
              {/* <div className={'b-form__row -two_column'}> */}
               
              {/* </div> */}
              </div>
              </div>
            </form>

            <TitleInner title={'HCC Codes'}/>
            {hccCodesTable ? (
              <>
                <DataTable 
                  value={hccCodesTable}
                  className='-paginator'
                  resizableColumns={true}
                  style={{marginTop: '10px'}}
                  // footer={footer}
                  >
                  <Column style={{width: 130}} field='first_name' header={codeHeader} body={hccCodeBody} />
                  <Column field='last_name' header={diagnosticHeader} body={hccDiagnostic} />
                  <Column className='' style={{width: 170}} header={dateOfCreateHeader} body={hccDateBody} />
                  {/* {userData?.option.permissions.create_edit_manage_user ? */}
                  <Column style={{width: 150}} field='edit' header={headerEdit} body={hccEditBody} />
                  {/* : null} */}
                </DataTable>
              </>
            )
            : null}

              <div className={'b-form__footerBox column'}>
              <div className={'b-form__btnWrapperHcc row'} style={{borderTop: "none", paddingTop: "0"}}>
                <Button
                    title={'back'}
                    type={'transparency'}
                    className={'-uppercase -w-50'}
                    onPress={navTo(Routes.PATIENTS_LIST)}/>
                <Button
                    title={'save'}
                    className={'-uppercase -ml-10 -w-50'}
                    onPress={(e) => handleAddPatient(e)}/>
              </div>
              {(pathname.indexOf('/patient-edit') !== -1) ?
              // <>
              // <Button
              //   title={'all hcc codes'}
              //   dataFor={'hccToolTip'}
              //   className={'darkest -uppercase'}
              //   width={window.innerWidth <= 400 ? '110px' : '155px'}
              //   onPress={hccCodesWasChanged ? () => null : navToAllHccCodes}/>
              //   {hccCodesWasChanged ?
              //     <ReactTooltip id='hccToolTip' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
              //       Please save patient edit to view HCC Codes
              //     </ReactTooltip>
              //   : null}
              //   </>
              <div className={'b-form__btnWrapperHcc row'} style={{borderTop: "none", paddingTop: "0"}}>
                  <Button
                    title={'All DataBase Codes'}
                    dataFor={'allDBcodes'}
                    className={'-w-50'}
                    onPress={hccCodesWasChanged ? () => null : navTo(Routes.ALLHCCCODES)}
                    />
                    {hccCodesWasChanged ?
                      <ReactTooltip id='allDBcodes' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
                        Please save changes to view All DataBase Codes
                      </ReactTooltip>
                      : null}
                  <Button
                    title={'export to scv'}
                    dataFor={'export'}
                    className={'darkest -uppercase -ml-10 -w-50'}
                    onPress={hccCodesWasChanged ? () => null : handleExportCsv}
                  />
                  {hccCodesWasChanged ?
                      <ReactTooltip id='export' arrowColor='var(--primary-color) !important' className='b-tooltip__withoutArrow'>
                        Please save changes to download HCC Codes in CSV
                      </ReactTooltip>
                    : null}
                </div>

                : null}
              </div>
          </div>
        </div>
        {state.modalType === 'add-code' && <Modal
          title='Add code'
          disableScroll={true}
          onClose={() => {
            setState({...state, modalType: null});
            setHccCodesData({...hccCodesData, hcc_code_ids: null });
          }}>
        <div className={'b-modal'}>
          {/* <div className={'b-form__formGroup'}>
            <div className={'b-form__datePikerLabel'}>
              Date create *
            </div>
            <DatePicker
                maxDate={new Date()}
                dateFormat={'MM.dd.yyyy'}
                selected={editHccCode.created_at}
                className={'b-form__datePikerInput'}
                // error={errors?.patient_profiles.dob}
                showYearDropdown
                dropdownMode="select"
                onChange={value => setEditHccCode({...editHccCode, created_at: value})}/>
          </div> */}
          <div className={'b-form__formGroup'} style={{position: 'relative'}}>
            <Select
                value={hccCodesData.hcc_code_ids}
                defaultValue={hccCodesData.hcc_code_ids}
                options={hccCodeListBySearch}
                label={'HCC Codes *'}
                height={'auto'}
                maxMenuHeight={200}
                onInputChange={handleChangeHccCodesInputValue}
                className={'mb-0'}
                placeholder={'Select One or More'}
                onChange={value => setHccCodesData({...hccCodesData, hcc_code_ids: value })}
                multi={true}
                searchable={true}
                closeMenuOnSelect={false}
                closeMenuOnScroll={false}
            />
            <div className={'b-modal__btnWrapper absolute'}>
              <Button
                title={'Cancel'}
                onPress={() => {
                  setState({ ...state, modalType: null });
                  setHccCodesData({ ...hccCodesData, hcc_code_ids: null });
                }}
                className={'-p-0'}
                type={'transparency'}
                width="80px"
                height="30px"
              />
              <Button
                title={'Add'}
                disabled={!hccCodesData.hcc_code_ids}
                onPress={handleAddCode}
                className={'-ml-10'}
                width="80px"
                height="30px"
              />
            </div>
          </div>
        </div>
      </Modal> }
        {state.modalType === 'create-success' && <Modal
        title='Success'
        width={520}
        onClose={handleCloseModal}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
            Patient<i><b>&nbsp;“{userProfiles.first_name}&nbsp;{userProfiles.last_name}” </b></i>&nbsp;was created
          </div>
        </div>
        </Modal>}
        {state.modalType === 'update-success' && <Modal
        title='Success'
        disableScroll={true}
        width={520}
        onClose={() => setState({ ...state, modalType: null })}>
        <div className={'b-modal'}>
          <div className={'b-modal__text'}>
          Patient<i><b>&nbsp;“{userProfiles.first_name}&nbsp;{userProfiles.last_name}” </b></i>&nbsp;was updated
          </div>
        </div>
        </Modal>}
        {state.modalType === 'create-error' && <Modal
          title='Error'
          width={520}
          onClose={() => {
            setState({...state, modalType: null}),
            setPermissionsError('')
          }}>
          <div className={'b-modal'}>
            <div className={'b-modal__text'}>
              {permissionsError}
            </div>
          </div>
         </Modal>}
      </div>
  );
};

export default PatientsAdd;
